.search-status-running {
    @include properties($m-search-status-running);

    .ms-grid-column.patient-identification-column {
        @include properties($m-search-status-running-patient-identification-column);

        .patient-identification-wrapper {
            @include properties($m-patient-identification-wrapper);
        }
    }
    .patient-identification {
        @include properties($m-patient-identification-running-grid)
    }
}
