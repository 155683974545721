.ms-data-grid .patient-potential-typing-grid {
    @include properties($m-search-patient-potential-typing-grid);

    .index {
        @include properties($m-search-patient-potential-typing-index);
    }

    tbody .index {
        @include properties($m-search-patient-potential-typing-body-index);
    }

    .percent {
        @include properties($m-search-patient-potential-typing-percent);
    }

    tbody .percent {
        @include properties($m-search-patient-potential-typing-body-percent);
    }

    tbody tr td {
        @include properties($m-search-patient-potential-typing-cell);
    }

    .index {
        @include properties($m-search-patient-potential-typing-index);
    }

    .loci {
        @include properties($m-search-patient-potential-typing-loci);
    }

    .loci-drbx {
        @include properties($m-search-patient-potential-typing-loci-drbx);
    }
}

.potential-typing-dialog.ngdialog .ngdialog-content {
    @include properties($m-search-patient-potential-typing-dialog-content);
}
