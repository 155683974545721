.source-no-potential-typing,
.source-drbx-score-issue,
.patient-drbx-score-issue {
    @include properties($m-source-potential-typing-none);
}

.no-haplotype-pair-tooltip {
    .tippy-tooltip {
        @include properties($m-source-potential-typing-no-pair-tooltip);

        a {
            @include properties($m-source-potential-typing-no-pair-tooltip-link);
        }
    }
}

.ms-data-grid .source-potential-typing-grid {
    @include properties($m-source-potential-typing-grid);

    thead th {
        @include properties($m-source-potential-typing-head-cell);
    }

    .ht-pair {
        @include properties($m-source-potential-typing-ht-pair);
    }

    .mcat {
        @include properties($m-source-potential-typing-mcat);
    }

    .loci-a {
        @include properties($m-source-potential-typing-loci-a);
    }

    .loci-b {
        @include properties($m-source-potential-typing-loci-b);
    }

    .loci-c {
        @include properties($m-source-potential-typing-loci-c);
    }

    .loci-drb1 {
        @include properties($m-source-potential-typing-loci-drb1);
    }

    .loci-dqb1 {
        @include properties($m-source-potential-typing-loci-dqb1);
    }

    .loci-drbx {
        @include properties($m-source-potential-typing-loci-drbx);
    }

    .percent {
        @include properties($m-source-potential-typing-percent);
    }

    .mcat-allele10 {
        @include properties($m-source-potential-typing-alelle10);
    }

    .mcat-allele8 {
        @include properties($m-source-potential-typing-alelle8);
    }

    tbody {
        tr td {
            @include properties($m-source-potential-typing-cell);
        }

        .percent {
            @include properties($m-source-potential-typing-cell-percent);
        }

        .mcat-allele10 {
            @include properties($m-source-potential-typing-cell-allele10);
        }

        .mcat-allele8 {
            @include properties($m-source-potential-typing-cell-allele8);
        }

        .ht-pair {
            @include properties($m-source-potential-typing-cell-ht-pair);
        }
    }

    .negative {
        @include properties($m-source-potential-typing-negative);
    }
}

.source-potential-typing-dialog.ngdialog .ngdialog-content {
    @include properties($m-source-potential-typing-dialog-content);
}
