.workup-section{
    @include properties($m-workup-section);

    .field-wrapper {
        &.workup-section-title{
            .ms-field-text{
                @include properties($m-workup-section-ms-field-text);
            }

            li {
                &.field-note{
                    @include properties($m-workup-section-ms-field-note);
                }
            }
        }
    }

    .workup-section-text-label{
        .ms-field-text{
            @include properties($m-workup-section-ms-text-label);
        }
    }
}