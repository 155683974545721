@use 'sass:math';

.progress-spinner {
    & > div {
        @include properties($c-progress-spinner-bounce);
    }

    @for $i from 1 through length($c-progress-spinner-bounce-list) {
        .bounce-#{$i} {
            @include properties(nth($c-progress-spinner-bounce-list, $i));
        }
    }
}

.rounded-spinner {
    @include properties($c-rounded-spinner);

    @for $i from 1 through $c-rounded-spinner-bounce-number {
        .bounce-#{$i} {
            @include properties($c-rounded-spinner-bounce);
            transform: rotate(math.div(360deg, $c-rounded-spinner-bounce-number) * $i);
            transform-origin: ($c-rounded-spinner-radius - $c-rounded-spinner-bounce-offset) ($c-rounded-spinner-radius - $c-rounded-spinner-bounce-offset);
        }

        &.active .bounce-#{$i} {
            @include properties($c-rounded-spinner-active-bounce);
            @include properties(nth($c-rounded-spinner-active-bounce-list, $i));
        }
    }
}
