.collapsed-section{
    .ms-module-section-header{
        @include properties($c-collapsed-section-header);

        .collapse-link-arrow-ico {
            @include properties($c-collapsed-section-collapse-link-arrow-ico);

            &.open{
                @include properties($c-collapsed-section-collapse-link-arrow-ico-open);
            }
        }
    }

    .no-results{
        @include properties($c-collapsed-section-no-results);
    }

    .section-content{
        @include properties($c-collapsed-section-content);
    }

    .collapsed-title{
        @include properties($c-collapsed-section-collapsed-title);
    }
}