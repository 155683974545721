/************************************************
    BUTTONS: MAIN OPTIONS
 ************************************************/
$default_button_type: plane;
$default_button_font: $font-regular-properties;
$default_button_font_size: 1.3;
$default_button_width: 100%;
$default_button_min_width: rem(80);
$default_button_height: rem(28);
$default_button_size: (
    width: $default_button_width,
    height: $default_button_height
);
$default_button_padding: 0 rem(16);
$default_button_margin: 0;
$default_button_border: (
    style: solid,
    width: rem(2),
    radius: rem(2)
);
$default_button_button_indent: rem(16);

$functional-button-width: 100%;
$functional-button-min-width: rem(80);
$functional-button-height: rem(36);
$functional-button-size: (
    width: $functional-button-width,
    height: $functional-button-height
);
$functional-button-padding: 0 rem(16);
$functional-button-margin: 0;
$functional-button-button-indent: rem(28);

$mini_button_min_width: auto;

/************************************************
    BUTTON WRAPPER DEFINITION
 ************************************************/
$g_button_wrapper: (
    size: (
        min-width: $default_button_min_width
    )
);
$g_button_wrapper_n_wrapper: (
    margin: (0 0 0 $default_button_button_indent)
);

$g_button_wrapper_mini: (
    size: (
        min-width: $mini_button_min_width
    )
);

$g-functional-button-wrapper: (
    size: (
        min-width: $functional-button-min-width
    )
);
$g-functional-button-wrapper-n-wrapper: (
    margin: (0 0 0 $functional-button-button-indent)
);

$g-button-flexible: (
    flexbox: (row, center),
);

$g-workflow-export-disabled: (
    text: (
        color: #B6DBFF,
    ),
);

/************************************************
    COMMON BUTTON STATE PROPERTIES
 ************************************************/
$g_common_button: (
    position: (relative, 0 null null 0),
    transform: translate(0px, 0px),
    block: (
        box-sizing: border-box,
        box-shadow: none,
        cursor: pointer,
        display: block,
        outline: none
    ),
    text:(
        font: $font-regular-properties,
        font-size: $default_button_font_size,
        line-height: normal,
        text-align: center,
        text-decoration: none,
        vertical-align: middle
    ),
    padding: 0,
    margin: 0
);
$g_common_button_hover: (
    transform: translate(0px, 0px),
    text: (
        text-decoration: none
    )
);
$g_common_button_active: (
    transform: translate(0px, 0px),
    text: (
        text-decoration: none
    ),
    block: (
        outline: none
    )
);
$g_common_button_focus: (
    transform: translate(0px, 0px),
    text: (
        text-decoration: uppercase
    ),
    block: (
        outline: inherit
    )
);
$g_common_button_disabled: (
    block: (
        cursor: not-allowed
    )
);

/************************************************
    COMMON BUTTON COLOR PROPERTIES
 ************************************************/
$common_button_default_color_schema: (
    background: (
        color: #0C87FF
    ),
    text: (
        color: #FFFFFF
    ),
    border: (
        color: #0C87FF
    )
);
$common_button_hover_color_schema: (
    background: (
        color: #0A79E5
    ),
    text: (
        color: #FFFFFF
    ),
    border: (
        color: #0A79E5
    )
);
$common_button_active_color_schema: (
    background: (
        color: #096CCC
    ),
    text: (
        color: #FFFFFF
    ),
    border: (
        color: #096CCC
    )
);
$common_button_focus_color_schema: (
    block: (
        box-shadow: 0 0 0 1px #FFFFFF inset
    )
);
$common_button_disabled_color_schema: (
    background: (
        color: #B6DBFF
    ),
    text: (
        color: #FFFFFF
    ),
    border: (
        color: #B6DBFF
    )
);
$common_button_color_schema: (
    default: $common_button_default_color_schema,
    hover: $common_button_hover_color_schema,
    focus: $common_button_focus_color_schema,
    active: $common_button_active_color_schema,
    disabled: $common_button_disabled_color_schema
);

$g-action-button-important-color_schema: (
    default: (
        background: (
            color: #D64848
        ),
        border: (
            color: #D64848
        )
    ),
    hover: (
        background: (
            color: #CB2C2C
        ),
        border: (
            color: #CB2C2C
        )
    ),
    focus: (
        block: (
            box-shadow: 0 0 0 1px #FFFFFF inset
        )
    ),
    active: (
        background: (
            color: #B21C1C
        ),
        border: (
            color: #B21C1C
        )
    ),
    disabled: (
        background: (
            color: #F3C8C8
        ),
        border: (
            color: #F3C8C8
        )
    )
);

/************************************************
    COMMON BUTTON SIZE PROPERTIES
 ************************************************/
$common_button_default_sizes: (
    size: $default_button_size,
    border: $default_button_border,
    padding: $default_button_padding,
    margin: $default_button_margin
);
$common_button_active_sizes: ();
$common_button_hover_sizes: ();
$common_button_disabled_sizes: ();
$common_button_sizes: (
    default: $common_button_default_sizes,
    hover: $common_button_hover_sizes,
    active: $common_button_active_sizes,
    disabled: $common_button_disabled_sizes
);

/************************************************
    BUTTONS: DEFAULT
 ************************************************/
$g_button_default_properties: ();

/************************************************
    BUTTONS: ACTION
 ************************************************/
$g_action_button_type: plane;

$g_button_plane_properties: $g_button_default_properties;
$g_button_plane_color_schema: $common_button_color_schema;
$g_button_plane_sizes: $common_button_sizes;

/************************************************
    BUTTONS: FUNCTIONAL
 ************************************************/
$g-functional-button-sizes: (
    default: (
        size: $functional-button-size,
        padding: $functional-button-padding,
        margin: $functional-button-margin
    ),
    hover: (),
    active: (),
    disabled: ()
);

$g_button_functional_properties: $g_button_default_properties;
$g_button_functional_color_schema: $common_button_color_schema;
$g_button_functional_sizes: $common_button_sizes;

/************************************************
    BUTTONS: LINK
 ************************************************/
$g_button_link_default_color_schema: (
    text: (
        color: $color-link
    )
);
$g_button_link_size: (
    block: (
        display: inline
    ),
    font: (
        size: rem(13)
    ),
    text: (
        line-height: rem(15)
    ),
    size: auto,
    border: (
        width: 0,
        radius: 0
    ),
    padding: 0,
    margin: 0
);
$g_button_link_properties: ();
$g_button_link_color_schema: (
    default: $g_button_link_default_color_schema,
    hover: $g_button_link_default_color_schema,
    focus: (),
    active: $g_button_link_default_color_schema,
    disabled: $g_button_link_default_color_schema
);
$g_button_link_sizes: (
    default: $g_button_link_size,
    hover: $g_button_link_size,
    active: $g_button_link_size,
    disabled: $g_button_link_size
);

/************************************************
    BUTTONS: ICO
 ************************************************/
$g-ico-button-type: ico;
$g-ico-button-properties: (
    block: (
        display: block,
        box-sizing: border-box
    )
);
$g-ico-button-color-schema: (
    default: (
        icon: (
            type: only,
            symbol: download,
            font_size: rem(16),
            width: 28px,
            height: 24px,
            behavioral: custom,
            initial_bg_color: #FFF,
            initial_color: #0C87FF,
            initial_ico_bg_color: $transparent,
            initial_ico_color: #0C87FF
        )
    ),
    hover: (
        icon: (
            type: only,
            symbol: download,
            font_size: rem(16),
            behavioral: custom,
            initial_bg_color: #FFF,
            initial_color: #0A79E5,
            initial_ico_bg_color: $transparent,
            initial_ico_color: #0A79E5
        )
    ),
    focus: (
        block: (
            box-shadow: 0 0 0 1px #0C87FF inset
        )
    ),
    active: (
        icon: (
            type: only,
            symbol: download,
            font_size: rem(16),
            behavioral: custom,
            initial_bg_color: #FFF,
            initial_color: #096CCC,
            initial_ico_bg_color: $transparent,
            initial_ico_color: #096CCC
        )
    ),
    disabled: ()
);
$g-ico-button-sizes: (
    default: (
        size: (
            width: rem(32),
            height: rem(28)
        ),
        border: (
            width: rem(2),
            style: solid,
            color: #B6DBFF,
            radius: 0
        ),
        padding: 0,
        margin: 0
    ),
    hover: (),
    active: (),
    disabled: ()
);

/************************************************
    BUTTONS: SECONDARY
 ************************************************/
$g_secondary_button_type: plane;
$g_secondary_button_color_schema: (
    default: (
        background: (
            color: #FFFFFF
        ),
        text: (
            color: #0C87FF
        ),
        border: (
            color: #B6DBFF
        )
    ),
    hover: (
        background: (
            color: #FFFFFF
        ),
        text: (
            color: #0A79E5
        ),
        border: (
            color: #B7D7F8
        )
    ),
    focus: (
        block: (
            box-shadow: 0 0 0 1px #0C87FF inset
        )
    ),
    active: (
        background: (
            color: #FFFFFF
        ),
        text: (
            color: #096CCC
        ),
        border: (
            color: #B5D3F0
        )
    ),
    disabled: (
        background: (
            color: #FFFFFF
        ),
        text: (
            color: #B6DBFF
        ),
        border: (
            color: #E9F5FF
        )
    )
);
$g_secondary_button_sizes: (
    default: (
        size: $default_button_size,
        border: (
            width: rem(2),
            style: solid,
            radius: rem(2)
        ),
        padding: $default_button_padding,
        margin: $default_button_margin
    ),
    hover: (),
    active: (),
    disabled: ()
);

/************************************************
    BUTTONS: CUSTOM
 ************************************************/
$g_danger_button_type: plane;
$g_danger_button_color_schema: (
    default: (
        background: (
            color: $color-highlight
        ),
        text: (
            color: $white
        ),
        border: (
            color: $transparent
        )
    ),
    hover: (
        background: (
            color: $color-highlight-hover
        ),
        text: (
            color: $white
        ),
        border: (
            color: $transparent
        )
    ),
    focus: (),
    active: (
        background: (
            color: $color-highlight-active
        ),
        text: (
            color: $white
        ),
        border: (
            color: $transparent
        )
    ),
    disabled: (
        background: (
            color: #F3C8C8
        ),
        text: (
            color: $white
        ),
        border: (
            color: $transparent
        )
    )
);

$g_button_wrapper_fill_content: (
    block: (
        display: inline-block,
    ),
);

$g-button-close: (
    position: (absolute, rem(-5) rem(-5) null null),
    block: (
            display: block
    ),
    background: (
            color: $transparent
    ),
    border: (
            width: 0
    ),
    size: rem(24),
    icon: (
            type: only,
            symbol: cross,
            font_size: rem(12),
            width: rem(24),
            height: rem(24),
            behavioral: custom,
            initial_bg_color: $transparent,
            initial_color: $color-text-main-2,
            hover_bg_color: $transparent,
            hover_color: $color-link
    ),
    padding: 0
);

$g-button-trash-ico: (
        block: (
                display: inline-block,
        ),
        icon: (
                type: only,
                symbol: trash,
                font_size: rem(16),
                behavioral: $default_icon_dark_red_behavioral,
        )
);
