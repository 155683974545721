#okta-sign-in.auth-container.main-container {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12)
  }
  #okta-sign-in .o-form-button-bar .button {
    height: 35px;
  }

#okta-sign-in.auth-container .okta-form-label {
  padding-bottom:0px;
}
  #okta-sign-in .custom-checkbox input, #okta-sign-in .custom-radio input {
    opacity:100;
    transform:scale(1.5,1.5);
  }
  #okta-sign-in .o-form .custom-checkbox label {
      font-size: 14px;
  }
  #okta-sign-in .infobox:before {
    width: 0;
  }
  #okta-sign-in .infobox > .icon {
      display: none;
  }
  #okta-sign-in.auth-container h2, #okta-sign-in.auth-container h3 {
      font-weight: inherit;
      color: #666666; 
      font-family: Arial, sans-serif  !important;
      font-size: 14px !important;
      line-height: 15px   !important;
      text-align: center;
      margin-bottom: 30px !important;
      width: 396px !important;
      margin-left: -40px !important;
  }
  #okta-sign-in a.link.js-forgot-password {
    float: right;
    font-size: 11px;
  }
  #okta-sign-in.auth-container .link:link {
      font-family: Arial, sans-serif;
      /* font-size: 13px; */
      font-weight: normal;
      line-height: 15px;
  }
  div#okta-sign-in.auth-container .okta-sign-in-header {
    padding: 0 0 0 !important;
     border-bottom: none !important;
      padding-bottom: 0px !important;
  }
  .no-beacon .auth-header{
      padding-bottom: 0px;
      padding-top: 0px;
      border-bottom: none;
  }

  .beacon-container {
     position: absolute;
     bottom: 10px;
     width: 40px;
     height: 40px;
      border-bottom: none;
  }
  
  div.okta-signin-header .auth-header {
    padding: 0 0 0;
  }
  #okta-sign-in.auth-container input[type="submit"], #okta-sign-in.auth-container input[type="button"] {
    font-family: Arial, sans-serif;
    font-size:14px;
  }
  
  #okta-sign-in .o-form .input-fix {
    font-family: Arial, sans-serif;
  }
  #okta-sign-in .o-form .input-fix, #okta-sign-in .o-form .textarea-fix {
      /* width: 300px; */
  }
  #okta-sign-in .auth-content {
    padding-bottom: 0;
  }
  .no-beacon .auth-content {
      padding-top: 0px;
  }
  .o-form-fieldset-container {
    padding: 14px 40px 0px;
  }
  #okta-sign-in .okta-sign-in-header {
    padding: 0 0 0 !important;
     border-bottom: none !important;
      padding-bottom: 0px !important;
      border-bottom-color: transparent;
  }
  
  
  #nmdp-custom-div {
    padding-bottom:20px;
  }
  
  #okta-sign-in .auth-org-logo {
      display: block;
      position: relative;
      margin: 0 auto;
      max-width: 270px;
      max-height: 100px;
  }
  #okta-sign-in .o-form-head {
    margin-top: 0px;
  }
  #okta-sign-in .forgot-password .o-form-fieldset {
      margin-bottom: 25px;
  }
  #okta-signin-username {
      position:relative;
      color: #666666;	
      font-family: Arial, sans-serif;
      font-size: 14px;	
      line-height: 16px;
  }
  #okta-sign-in span {
      margin: 2px 0 0 0;
  }
  #okta-sign-in span.nmdp-input-label {
      height: 2.06%;  
      width: 17.42%;  
      color: #666666; 
      font-family: Arial, sans-serif;
      font-size: 14px;    
      line-height: 16px;
      text-align: left;
  }
  
  div#acceptTandCImplicit {
    padding: 0px 0px 20px;
      height: 3.85%;
      width: 100%;
      color: #797979;
      font-family: Arial, sans-serif;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
  }
   #okta-sign-in a:active, #okta-sign-in a:link, #okta-sign-in a:visited, #okta-sign-in.auth-container link:link {
    color: #0077C5;
  }
  #okta-sign-in a.email-button {
      margin-bottom: 12px;
  }
  #okta-sign-in.auth-container .link:link, #okta-sign-in.auth-container .link:visited, #okta-sign-in.auth-container .link:hover, #okta-sign-in.auth-container .link:active {
    color: #0077C5;
  }
  #okta-sign-in.auth-container .button-primary:disabled {
      /* -- Buttons' Colors -- */
      color: #fff;
      /* Opera 11.10+ */
      /* IE10+ */
      background: -webkit-linear-gradient(#7FBBE2, #7FBBE2);
      background: linear-gradient(#7FBBE2, #7FBBE2);
      border-color: #7FBBE2;
      border-bottom-color: #7FBBE2;
    }

  #okta-sign-in.auth-container .link-button:link {
      color: white;
     background : #007dc1;
  }
  
  #okta-sign-in   .button-primary {
      position: relative;
      width: 80%;
      word-wrap: break-word;
      white-space: normal;
      color: #FFFFFF;
      font-family: Arial, sans-serif;
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      border-color: #007dc1;
      border-bottom-color: #007dc1;
  }
  
  #okta-sign-in .link:link, #okta-sign-in .link:visited, #okta-sign-in .link:hover, #okta-sign-in .link:active {
      color: #0077C5;
  }
  
  #okta-sign-in .button-primary:hover, #okta-sign-in .button-primary:focus, #okta-sign-in .button-primary:active {
      box-shadow: none;
      background-color: #0077C5;
  }
  #okta-sign-in .forgot-password .auth-footer {
      margin-bottom: 15px;
  }
  .auth-footer {
    font-family: Arial, sans-serif;
    width: 103%;
  }

  .o-form-input {
      color: #666666;
      font-family: Arial, sans-serif;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
  }
  #okta-sign-in.auth-container .okta-form-input-field input {
    font-family: Arial, sans-serif;
      font-size: 16px;
      line-height: 18px;
      text-align: left;
      color: #666666;
      
  }
  #okta-sign-in p, p.okta-form-subtitle.o-form-explain {
      font-family: Arial, Helvetica, sans-serif;
  }
  div#nmdp-eeo-div {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  a#nmdp-eeo-link {
    margin-top: 50 px;
      height: 2%;
      width: 16.02%;
      color: #0077C5;
      font-family: Arial, sans-serif;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
  }
  div#nmdp-main-footer {
      background-color: #F0F4F5;
      border-top: 1px solid #ddd; 
  }
  div#nmdp-auth-guest-div {
      border-top: 1px solid #ddd; 
      color: #666666;
      font-family: Arial, sans-serif;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      padding-top: 10px;
      margin-bottom: 10px;
  }
  div#nmdp-desc-div {
      color: #797979;
      font-family: Arial, sans-serif;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      background-color: #F0F4F5;
      margin-bottom: 10px;
  }
  a#nmdp-guest-login-link {
    position: relative;
      width: 80%;
      color: #0077C5;
      font-family: Arial, sans-serif;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
  }
  div#nmdp-copyright-div {
      color: #797979;
      background-color: #F0F4F5;
      font-family: Arial, sans-serif;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      padding-bottom: 15px;
  }

  .mfa-verify .auth-footer {
    padding: 20px 40px 20px;
  }
  div#forgot-pwd-explain {
      color: #666666;
      font-family: Arial, sans-serif;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
      width:110%;
  }
  div#okta-sign-in.auth-container h2#forgot-pwd-header {
      height: 22.65px;
      width: 346px;
      color: #666666;
      font-family: Arial, sans-serif;
      font-size: 16px;
      line-height: 23px;
      text-align: left;
      margin-left: 0;
  }
  #nmdp-forgot-js-back-link.js-back.nmdp-forgot-js-back {
      float: right;
      font-family: Arial, sans-serif;
      font-size: 13px;
      font-weight: normal;
      line-height: 16px;
      margin-top: -16px;
  }

  #okta-sign-in a.link-button {
    color: #FFFFFF;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    background-color: #0087d0;
    cursor: pointer;
    text-align: center; 
    vertical-align: middle;
    border: 1px solid;
    border-radius: 3px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-bottom-color: #00456a;
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext{
      visibility: hidden;
      text-align: left;
      padding: 10px 0px;
      border-radius: 0px;
  
      /* Position the tooltip text box */
      position: absolute;
      z-index: 1;
      bottom: 120%;
      left: 50%;
      height: 95px;
      width: 320px;
      color: #666666;
      font-family: Arial, sans-serif;
      line-height: 20px;
      white-space:preline;
      background-color: #FFFFFF;
      box-shadow: 0 0 34px 0 rgba(0,0,0,0.25);
      padding-bottom:50px;
      margin-bottom: 5px;
  }
  
  #nmdp-info-super.tooltiptext { 
     padding-left: 20px;
     padding-top:15px;
      margin-left: -250px;
      line-height: 16px;
      font-size: 13px;
      height: 110px;
      width: 310px;
      background-color: #FFFFFF;
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5), 0 0 34px 0 rgba(0,0,0,0.25);
      font-family: Arial, sans-serif;
  }
  
  /* Tooltip arrow */
  div.tooltip .tooltiptext::after {
      content: "";
      position: relative;
      top: 100%;
      margin-left: 200px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
      color: white;
      font-size: 6px;
      
  }

  #okta-sign-in :hover {
      visibility: visible;
      opacity: 1;
  }
   
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
      font-size: 14px;
      white-space: pre-line;
      padding-left: 20px;
      padding-top: 15px;
      box-shadow: 0 4px 4px 20 rgba(0,0,0,0.5);
  }
  
  .tooltip {
    
      font-family: Arial, sans-serif;
      position: relative;
      display: inline-block;
  
      visibility: visible;
      opacity: 1;
      font-size: 24px;
      color: #0077C5;
      text-align: center;
      padding-bottom: 0px;
      margin-bottom: -4px;
  }
  
  #nmdp-guest-login-tooltip-base.tooltip {
    color: #0077C5;
      font-family: okticon, sans-serif;
      font-size: 18px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      margin-bottom: 10px;
      padding-left: 5px;
      vertical-align: text-top;
  }
  #nmdp-guest-login-tooltip-base.tooltiptext {
    color: red;
    background-color:transparent;
  }
  
  #nmdp-info-super.tooltiptext:after, .tooltiptext:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      font-family: Arial, sans-serif;
  }
  
  #nmdp-info-super.tooltiptext:after {
      color: white;
      border-top-color: white;
      border-width: 10px;
      margin-left: 85px;
      padding-bottom: 0px;
  }
  #okta-sign-in .beacon-container {
      width: 50px;
      height: 50px;
   }
  #error-toast {
    display: none;
    color: #ffffff;
    text-align: center;
    z-index: 1;
    font-size: 13px;
    line-height: 20px; 
    min-height: 50px;	
    max-height: 205px;
    border-radius: 2px;	
    background-color: #FFFFFF;
}
#error-toast.show {
    display: flex;
    background-color: rgba(195,85,85,0.75);
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 0 10px 0;
}
#okta-sign-in .message-container {
    float: right;
    min-height: 50px;
    max-height: 205px;
}
#okta-sign-in .error-message {
    float: left;
    display: inline-flex;
    text-overflow: ellipsis;
    overflow-x: auto;
}
#okta-sign-in .o-form .infobox {
    border: none;
    border-radius: 2px;
    background-color: rgba(195,85,85,0.75);
    color: white;
    padding: 0 !important;
    height: auto;
}

#okta-sign-in .o-form .infobox p {
    text-align: center;
    padding: 10px;
    line-height: 20px;
}
#okta-sign-in .o-form .o-form-label, #okta-sign-in .o-form input, #okta-sign-in .o-form label, #okta-sign-in .o-form textarea {
    font-family: Arial, sans-serif;
    font-size:14px;
    font-weight:400;
}
.nmdp-appinfo-outer-div {
    text-align: center;
    padding: 0;
}
.nmdp-appinfo-outer-div div {
    padding: 0;
    position: relative;
    overflow: hidden;
}
div#nmdp-appinfo-list-div {
    display: inline-block;
    width: 90%;
    padding: 0;
}
.nmdp-appinfo-list-div ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
#nmdp-appinfo-list-div li {
    flex-grow: 1;
    flex-basis: auto;
    width: 50%;
    font-size: 12px;
    font-family: Arial, sans-serif;
}
#nmdp-capsLockOn {
    color:#d93934;
}
