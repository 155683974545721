.module-page {
    @include properties($m-module-page);

    .ms-module-header-area {
        @include properties($m-module-page-header);
    }

    .content-area {
        @include properties($m-module-page-content-area);

        & > .container {
            @include properties($m-module-page-content-area-container);
        }
    }

    .page-content {
        @include properties($m-module-page-content);
    }

}
