$c-dialog-overlay-color: rgba(0, 0, 0, 0.4);
$c-dialog-width: rem(560);
$c-dialog-shadow: 0 0 rem(30) rgba(0, 0, 0, 0.15);
$c-dialog-border: solid $color-alt-1 rem(1);


$c-dialog: (
    position: relative,
    block: (
        box-sizing: border-box
    ),
    padding: rem(40) rem(20) rem(24) rem(20)
);

$c-dialog-row: (
    size: (
        width: 100%,
    ),
    block: (
        box-sizing: border-box
    ),
    padding: 0 rem(4),
    margin: 0 0 rem(16) 0
);

$c-dialog-close-button: (
    position: (absolute, rem(8) rem(16) null null),
    block: (
        display: block
    ),
    background: (
        color: $transparent
    ),
    border: (
        width: 0
    ),
    size: rem(24),
    icon: (
        type: only,
        symbol: cross,
        font_size: rem(12),
        width: rem(24),
        height: rem(24),
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: $color-text-main-2,
        hover_bg_color: $transparent,
        hover_color: $color-link
    ),
    padding: 0
);

$c-dialog-header-area: (
    position: relative
);

$c-dialog-header-area-container: (
    font: (
        size: rem(16),
        family: Arial,
        weight: normal
    ),
    text: (
        color: $color-border,
        line-height: normal,
        white-space: normal
    )
);

$c-dialog-body-area: (
    position: relative,
    padding: 0 0 rem(20) 0
);

$c-dialog-body-area-container: (
    font: (
        size: rem(13),
        family: Arial,
        weight: normal
    ),
    text: (
        color: $color-text-primary,
        line-height: normal,
        white-space: normal
    )
);

$c-dialog-footer-area: ();
$c-dialog-footer-area-container: (
    flexbox: (row, center, center)
);

$c-dialog-flexible-content: (
    size: (
        width: auto,
        min-width: rem(555),
        max-height: 90%,
    ),
    block: (
        overflow: hidden,
    ),
    flexbox: (column),
);
$c-dialog-flexible-dialog: (
    flexbox: (column),
);
$c-dialog-flexible-container: (
    block: (
        overflow: auto,
    ),
);

$c-scrollable-content-dialog: (
    block: (
        overflow: auto,
    ),
);
$c-scrollable-content-dialog-content: (
    size: (
        width: rem(660),
        height: 90%,
    ),
    block: (
        overflow: hidden,
    ),
);
$c-scrollable-content-dialog-layout: (
    size: (
        height: 100%,
    ),
    block: (
        overflow: hidden,
    ),
);
$c-scrollable-content-dialog-layout-container: (
    flexbox: (column),
    size: (
        height: 100%,
    ),
    block: (
        overflow: hidden,
    ),
);
$c-scrollable-content-dialog-layout-container: (
    flexbox: (column),
    size: (
        height: 100%,
    ),
    block: (
        overflow: hidden,
    ),
);
$c-scrollable-content-dialog-layout-body: (
    block: (
        overflow: hidden,
    ),
    custom: (
        flex: 1,
    ),
);
