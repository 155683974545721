.master_wrapper {
    @include properties($l_main_properties);

    &.scroll-container{
        @include properties($l_main_scroll_container_properties);

        & > div[ng-transclude]{
            @include properties($l_main_transclude_properties);
        }
    }
}
.window {
    @include properties($l_window_properties);
}
overlay, .overlay {
    @include properties($l_overlay_properties);
}
.bubble {
    @include properties($l_bubble_properties);
}

ui-view {
    @include properties($l_ui_view_properties);
}

