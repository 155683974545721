$c-ui-grid-border-primary-color: #DBDBDB;
$c-ui-grid-border-secondary-color: #91ACBC;

$c-ui-grid: (
    size: 100%,
    border: (
        width: 0
    )
);

$c-ui-grid-header: (
    border: (
        width: 0 0 rem(2),
        style: solid,
        color: #91ACBC
    )
);
$c-ui-grid-header-top-panel: (
    background: (
        color: $transparent
    )
);
$c-ui-grid-header-cell: (
    border: (
        color: $transparent,
        width: 0,
        style: solid
    )
);
$c-ui-grid-header-cell-content: (
    flexbox: (column, flex-start, flex-end),
    padding: rem(2) rem(16) rem(6),
    font: $font-bold-properties,
    size: (
        height: rem(36)
    ),
    text: (
        font-size: 1.2,
        white-space: normal
    )
);

$c-ui-grid-cell-content: (
    padding: 0 rem(16) 0,
    font: $font-regular-properties,
    text: (
        line-height: normal,
        font-size: 1.3,
        color: #1D3649,
        white-space: normal
    ),
    size: (
        height: auto
    )
);
$c-ui-grid-cell-content-group: (
    size: (
        width: 100%
    )
);
$c-ui-grid-cell-content-group-cell: (
    block: (
        white-space: nowrap,
        text-overflow: ellipsis,
        overflow: hidden
    )
);
$c-ui-grid-cell-label: (
    padding: 0
);
$c-ui-grid-cell-label-sort: (
    block: (
        cursor: pointer
    )
);
$c-ui-grid-cell-label-sort-ico: (
    size: (
        width: rem(24),
        height: rem(24),
    ),
    position: (relative, null null rem(-6) null),
    block: (
        display: inline-block,
        box-sizing: border-box,
        vertical-align: baseline
    ),

);
$c-ui-grid-cell-label-sort-ico-arrow: (
    size: 0,
    position: (absolute, 50% null null 50%),
    border: (
        radius: 0,
        color: $transparent $transparent #91ACBC $transparent,
        style: solid,
        width: 0 rem(3) rem(3) rem(3)
    ),
    margin: -1px 0 0 -3px
);
$c-ui-grid-cell-label-sort-ico-arrow-desc: (
    border: (
        color: #1D3649 $transparent $transparent $transparent,
        style: solid,
        width: rem(3) rem(3) 0 rem(3)
    )
);
$c-ui-grid-cell-label-sort-ico-arrow-asc: (
    border: (
        color: $transparent $transparent #1D3649 $transparent,
        style: solid,
        width: 0 rem(3) rem(3) rem(3)
    )
);

$c-ui-grid-canvas: (
    padding: 0
);
$c-ui-grid-cell: (
    flexbox: (row, center),
    border: (
        width: 0
    ),
    padding: rem(12) null rem(13) null,
);
$c-ui-grid-cell-odd: (
    background: (
        color: #EDF0F2
    )
);
$c-ui-grid-cell-even: (
    background: (
        color: $transparent
    )
);
$c-ui-grid-link: (
    text: (
        font-size: 1.3
    )
);
$c-ui-grid-row-selected-cell: (
    background: (
        color: $color-brand-alt-3
    ),
    block: (
        box-shadow: (inset 0 rem(-1) rem(1) $color-brand-alt-1, 0 rem(-1) 0 $color-brand-alt-1)
    )
);
$c-ui-grid-row-selected-cell-first: (
    block: (
        box-shadow: (inset 0 rem(-1) rem(1) $color-brand-alt-1, 0 rem(-1) 0 $color-brand-alt-1, inset rem(4) 0 0 $color-brand-alt-1)
    )
);
$c-ui-grid-row-selected-n-row-selected-cell-first: (
    block: (
        box-shadow: (inset 0 rem(-1) 0 $color-brand-alt-1, inset rem(4) 0 0 $color-brand-alt-1)
    )
);

$c-ui-grid-row-pending-this-request-cell: (
    background: (
        color: #B6DBFF
    ),
    block: (
        box-shadow: (inset 0 rem(-1) rem(1) #0C87FF, 0 rem(-1) 0 #0C87FF)
    )
);
$c-ui-grid-row-pending-this-request-cell-first: (
    block: (
        box-shadow: (inset 0 rem(-1) rem(1) #0C87FF, 0 rem(-1) 0 #0C87FF, inset rem(4) 0 0 #0C87FF)
    )
);

$c-ui-grid-row-pending-another-request-cell: (
    background: (
        color: #FEE9C5
    ),
    block: (
        box-shadow: (inset 0 rem(-1) rem(1) #D66F0A, 0 rem(-1) 0 #D66F0A)
    )
);
$c-ui-grid-row-pending-another-request-cell-first: (
    block: (
        box-shadow: (inset 0 rem(-1) rem(1) #D66F0A, 0 rem(-1) 0 #D66F0A, inset rem(4) 0 0 #D66F0A)
    )
);
/************************************************
    COMPLEX GRID PROPERTIES
 ************************************************/
$c-ui-grid-complex-header: (
    border: (
        width: rem(1) 0 rem(2) 0,
        style: solid,
        color: #91ACBC
    )
);

$c-ui-grid-complex-header-cell: (
    size: (
        height: rem(40)
    ),
    border: (
        width: 0 rem(1) 0 0,
        style: solid,
        color: #DBDBDB
    )
);
$c-ui-grid-complex-header-cell-content: (
    flexbox: (column, flex-start, center),
    size: (
        height: rem(38)
    ),
    padding: 0 rem(8)
);
$c-ui-grid-complex-header-cell-content-compact: map_merge(
    $c-ui-grid-complex-header-cell-content,
    (
        padding: 0,
    )
);
$c-ui-grid-complex-header-cell-cell: (
    marign: rem(1) 0 0 0
);
$c-ui-grid-complex-header-cell-last: (
    border: (
        width: 0
    )
);
$c-ui-grid-complex-pinned-header-cell-last: (
    border: (
        width: 0 rem(1) 0 0,
        style: solid,
        color: #91ACBC
    )
);

$c-ui-grid-complex-cell: (
    flexbox: (row, center),
    border: (
        width: 0 rem(1) 0 0,
        style: solid,
        color: #DBDBDB
    ),
    size: (
        height: rem(44)
    )
);
$c-ui-grid-complex-cell-disable-selection: (
    text: (
        user-select: text
    )
);
$c-ui-grid-complex-pinned-cell-last: (
    border: (
        width: 0 rem(1) 0 0,
        style: solid,
        color: #91ACBC
    )
);
$c-ui-grid-complex-cell-last: (
    border: (
        width: 0
    )
);
$c-ui-grid-complex-cell-content: (
    block: (
        overflow: visible
    ),
    padding: 0 rem(8)
);
$c-ui-grid-complex-cell-content-compact: map_merge(
    $c-ui-grid-complex-cell-content,
    (
        padding: 0,
    )
);
$c-ui-grid-complex-cell-content-item: (
    text: (
        vertical-align: middle
    )
);

$c-ui-grid-no-results-area: (
    padding: rem(20) 0 0 0,
);
$c-ui-grid-no-results-label: (
    text: (
        color: #1D3649,
        line-height: rem(15)
    ),
    font: (
        size: rem(13),
    )
);

$c-ui-grid-no-scroll-y: (
    custom: (
        height: auto !important,
    ),
);

$c-ui-grid-no-scroll-y-viewport: (
    custom: (
        height: auto !important,
    ),
);

$c-ui-grid-body-header-bordered: (
    border: (
        width: rem(1) 0 rem(2) 0,
        style: solid,
        color: $c-ui-grid-border-primary-color transparent $c-ui-grid-border-secondary-color transparent,
    )
);

$c-ui-grid-header-bordered: (
    border: (
        width: rem(1) 0 rem(2) rem(1),
        style: solid,
        color: $c-ui-grid-border-primary-color transparent $c-ui-grid-border-secondary-color $c-ui-grid-border-primary-color,
    ),
);

$c-ui-grid-bordered: (
    border: (
        width: rem(1) 0 0 rem(1),
        style: solid,
        color: $c-ui-grid-border-primary-color,
    ),
);

$c-ui-grid-scrollbar-bordered: (
    border: (
        width: rem(1) 0 0 0,
        style: solid,
        color: $c-ui-grid-border-primary-color,
    ),
);

$c-ui-grid-container-left-bordered: (
    block: (
        index: 2,
    ),
);

$c-ui-grid-complex-header-cell-content-normal: (
    font: (
        weight: normal,
    ),
);

$c-ui-grid-icon-wrapper: (
    size: (
        min-height: rem(20)
    )
);

$c-ui-grid-draggable-row-over: (
    border: (
        color: #0C87FF,

        style: (null null solid null),
    )
);

$c-ui-grid-draggable-row-handle: (
    block: (
        cursor: pointer,
    ),
);

$c-ui-grid-draggable-row: (
    size: (
        height: rem(44),
    ),
);