/**************************************************************************************************
 VARIABLES
**************************************************************************************************/
$c-datefield-icon:(
    position: (absolute, 0 0 null null),
    icon: (
        type: right,
        position: (absolute, null null null null),
        symbol: calendar,
        font_size: 1.25rem,
        width: $g-field-icon-width,
        height: rem(36),
        behavioral: $default_icon_dark_blue_behavioral
    )
);
$c-datefield-icon-narrow:(
    position: (absolute, 0 0 null null),
    icon: (
        type: right,
        position: (absolute, null null null null),
        symbol: calendar,
        font_size: 1rem,
        width: rem(28),
        height: rem(28),
        behavioral: $default_icon_dark_blue_behavioral
    )
);

$c-datefield-mask-input-narrow:(
    size: (
        height: rem(28)
    )
);

$c-datepicker-dafault-input-hide: (
    size: (
        height: 0,
        width: 0
    ),
    border: (
        width: 0
    ),
    block: (
        display: block,
        visibility: hidden
    ),
    padding: ( 0 )
);

$c-datefield-icon-line-property: (
    text: (
        line-height: 36px
    )
);
$c-datefield-icon-line-property-narrow: (
    text: (
        line-height: 28px
    )
);
$c-datefield-input-group-addon:(
    position: (absolute, 0 0 null null),
);
$с-datepicker-next-month-arrow: (
    block: (
        display: block
    ),
    size: (
        width: rem(44)
    ),
    text: (
        color: transparent
    ),
    icon: (
        type: only,
        symbol: rightArrow,
        font_size: rem(16),
        width: rem(24),
        height: rem(24),
        behavioral: $default_icon_dark_blue_behavioral
    )
);
$с-datepicker-prev-month-arrow: (
    block: (
        display: block
    ),
    size: (
        width: rem(44)
    ),
    text: (
        color: transparent
    ),
    icon: (
        type: only,
        symbol: leftArrow,
        font_size: rem(16),
        width: rem(24),
        height: rem(24),
        behavioral: $default_icon_dark_blue_behavioral
    )
);

$c-datepicker-default-heigth: rem(304);
$c-datepicker-dropdown-overrides: (
    size: ( height: $c-datepicker-default-heigth ),
    position: (absolute),
    block: (
        display: block,
        box-shadow: none,
    ),
    padding: ( 0 ),
    border: (
        width: 0,
        radius: 0
    ),
    background: (none)
);

$c-datepicker-orient-bottom: (
    margin: ( rem(37) null null null)
);

$c-datepicker-orient-bottom-blocks: (
    position: (absolute, 0 null null 0)
);

$c-datepicker-orient-top-blocks: (
    position: (absolute, null null 0 0)
);

$c-datepicker-orient-pseudo-display: (
    block: (
        display: none
    )
);


$c-datepicker-month-font-size: rem(16);
$c-datepicker-month-font-color: #3c3f40;
$c-datepicker-month-header: (
    block: (
        overflow: hidden
    ),
    size: (height: rem(28)),
    position: (relative),
    padding: (rem(8) 0 0 0),
    text: (
        text-align: center,
        line-height: 1,
        font-size: $c-datepicker-month-font-size,
        color: $c-datepicker-month-font-color
    ),
    background: (
        color: transparent
    )
);
$c-datepicker-month-header-hover: (
    background: (none)
);

$c-datepicker-month-header-controls: (
    padding: ( 0 0 0 rem(6)),
    size: (width: rem(44)),
    text: (color: transparent)
);

$c-datepicker-month-header-controls-icon-color: #91ACBC;
$c-datepicker-month-header-controls-hover-color: #0C87FF;
$c-datepicker-month-header-controls-hover: (
    text: (
        color: $c-datepicker-month-header-controls-hover-color
    )
);
$c-datepicker-month-header-controls-color: (
    text: (
        color: $c-datepicker-month-header-controls-icon-color
    )
);

$c-datepicker-month-header-titles-color: $color-border;
$c-datepicker-month-header-titles: (
    size: (
        height: rem(20)
    ),
    margin: (0),
    padding: (0),
    text: (
        font-size: rem(12),
        line-height: 1,
        text-align: center,
        color: $c-datepicker-month-header-titles-color,
        font-family: 'Arial'
    )
);

$c-datepicker-body-text-properties: (
    text: (
        font-size: rem(13)
    )
);

$c-datepicker-days-body-tr-border: (
    border: (
        width: (rem(1) 0 0 0),
        color: #e9e9e9,
        style: solid
    )
);


$c-datepicker-days-body-day: (
    padding:(0),
    size: (
        width: rem(44),
        height: rem(40)
    ),
    border: (
        radius: 0,
        style: solid,
        color: #e9e9e9,
        width: (0 rem(1) 0 0)
    ),
    text: (
        vertical-align: middle,
        line-height: rem(39)
    )
);

$c-datepicker-days-body-day-last: (
    border: (
        width: (null 0 null null)
    )
);
$c-datepicker-days-body-day-hover: (
    text: (
        color: #1D3649
    ),
    border: (
        color: #DBEDFF
    ),
    block: (
        outline: 0
    ),
    custom: (
        cursor: pointer
    ),
    background: (#DBEDFF)
);

$c-datepicker-days-today-day: (
    background: (#E9E9E9),
    border: (
        color:  #E9E9E9
    ),
    text: (
        color: #1D3649
    )
);
$c-datepicker-days-today-day-hover: (
    background: (#DBEDFF),
    border: (
        color:  #DBEDFF
    ),
    text: (
        color: #1D3649
    ),
    custom: (
        cursor: pointer
    )
);

$c-datepicker-days-disabled-day: (
    background: (white),
    text: (
        color: $color-border
    ),
    custom: (
        cursor: default
    )
);

$c-datepicker-days-active-day: (
    background: (#0C87FF),
    text: (
        color: white
    ),
    border: (
        radius: 0,
        color: #0C87FF
    )
);

$c-datepicker-month-tr-border: (
    border: (
        width: (rem(1) 0 0 0),
        style: solid,
        color: #e9e9e9
    )
);

$c-datepicker-month-item: (
    padding: (1%),
    margin: (0),
    text: (
        line-height: rem(39),
        vertical-align: middle
    ),
    border: (
        radius: 0
    ),
    size: (
        height: rem(40)
    )
);

$c-datepicker-month-td-padding: (
    padding: (0)
);
$c-datepicker-days-items-color: #0C87FF;
$c-datepicker-items-properties: (
    border: (
        width: rem(1),
        color: $c-datepicker-days-items-color,
        radius: rem(2),
        style: solid
    ),
    background:(white),
    block: (
        box-shadow: ( 0 0 rem(10) 0 rgba(0, 0, 0, 0.20))
    ),
    size: (
        width: rem(230)
    )
);

$c-datepicker-days-properties: (
    border: (
        width: rem(1),
        color: $c-datepicker-days-items-color,
        radius: rem(2),
        style: solid
    ),
    background:(white),
    block: (
        box-shadow: ( 0 0 rem(10) 0 rgba(0, 0, 0, 0.20))
    ),
    size: (
        width: rem(315)
    )
);

$c-datepicker-years-hover-properties: (
    custom: (
        cursor: default
    )
)


