/* stylelint-disable scss/selector-no-redundant-nesting-selector */
/**************************************************************************************************
 SEARCH RESULTS
**************************************************************************************************/
.export-donors-dialog {
    .ngdialog-content {
        @include properties($m-export-donors-dialog-content)
    }

    .section-column {
        @include properties($m-export-panel-dialog-section-column);

        &.from-ref-col, &.to-ref-col{
            @include properties($m-search-results-export-ref-col);
        }

        &.selection-type-col {
            @include properties($m-search-results-export-selection-type-col);
        }
    }

    .dialog-body-area {
        @include properties($m-export-panel-dialog-area)
    }
}

.export-opl-dialog {
    .ngdialog-content {
        @include properties($m-export-opl-dialog-content);
    }
}

.search-results {
    @include properties($m-search-results);

    .ui-grid-cell-contents,
    .cell-field {
      &.b-locus-cell {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;

        >:nth-child(2) {
          align-self: flex-end;
          flex: 1 1 auto;
          padding: 0 rem(8);
          position: absolute;
          right: 0;
          text-align: right;
        }
      }

      [data-bleader] {
        cursor: pointer;
      }
    }

    .cell-field {
      &.b-locus-cell {
        >:nth-child(2) {
          padding: 0;
        }
      }
    }


  .ms-module-header-toolbar{
        .shopping-cart-button-container, .download-button-container{
           @include properties($m-search-results-buttons-margin);
        }

        .shopping-cart-button-container{
            @include properties($m-search-results-shopping-cart-button-container);
        }

        .search-results-date-msg{
            @include properties($m-search-results-date-msg-field);
        }
    }

    .applied-filters-indicator {
        @include properties($m-search-results-applied-filters-indicator);
    }

    .maintenance-end-date {
        @include properties($m-search-results-maintenance-end-date);
    }

    .export-panel-area {
        @include properties($m-search-results-export-panel-area);

        & > .container {
            @include properties($m-search-results-export-panel-container);
        }

        .section-header {
            @include properties($m-search-results-export-panel-header);

            & > .container {
                @include properties($m-search-results-export-panel-header-container);
            }
        }

        .toolbar-area {
            @include properties($m-search-results-export-panel-toolbar-area);
        }

        .required-mark {
            @include required;
            @include properties($m-search-results-export-panel-required-mark);
        }

        .section-content {
            @include properties($m-search-results-export-panel-section-content);

            & > .container {
                @include properties($m-search-results-export-panel-section-container);
            }
        }

        .section-column {
            @include properties($m-search-results-export-panel-section-column);

            &:last-child {
                @include properties($m-search-results-export-panel-section-column-last);
            }
        }

        .export-panel-ctrls-area {
            @include properties($m-search-results-export-panel-ctrls-area);

            & > .container {
                @include properties($m-search-results-export-panel-ctrls-container);
            }
        }
    }

    .donor-grid-area {
        @include properties($m-search-results-donor-grid-area);

        & > .container {
            @include properties($m-search-results-donor-grid-container);
        }

        .cell-field {
            @include properties($m-search-results-donor-grid-cell-field);

            & + .cell-field {
                @include properties($m-search-results-donor-grid-cell-field-n-field);
            }

            .cell-field-value {
                @include properties($m-search-results-donor-grid-cell-field-value);
            }

            .value-wrapper {
                @include properties($m-search-results-donor-grid-cell-field-value-wrapper);
            }
        }

        .cord-matching-cell,
        .donor-matching-cell {
            display: flex;
            font-size: 12px;

            li {
                color: $color-text-primary;
                line-height: 0.875rem;
                margin-top: 1px;
                padding: 0 8px;
                width: 50px;
            }

            li:first-child {
                padding-left: 0;
            }
        }

        .allele-level-match-prop .cell-field {
            .cell-field-value, .value-wrapper {
                @include properties($m-search-results-donor-grid-cell-allele-level-match-prop);
            }
        }

        .ms-hla-locus-view > .container {
            @include properties($m-search-results-donor-grid-cell-hla-locus-complex);
        }

        .ms-donor-country {
            @include properties($m-search-results-country-cell);
        }

        .blocked-icon {
            @include properties($m-search-results-country-ofac-icon);
        }

        .bp-restricted:not(.show-in-restricted) {
          .ui-grid-cell-contents,
          .ui-grid-cell-contents--compact {
            &:not(.show-in-restricted) {
              @include properties($m-search-results-restricted-cells);
            }
          }
        }
    }

    .empty-grid-area {
        @include properties($m-search-results-empty-grid-area);
    }

    .container-vertical {
      display: flex;
      flex-direction: column;
    }

    .search-results-not-maintained {
        @include properties($m-search-results-not-maintained);
    }

    .search-deferred-message {
      @include properties($m-search-results-search-deferred-msg);
    }

    .search-results-failed {
      @include properties($m-search-results-failed);
    }

    .search-results-area {
        @include properties($m-search-results-area);

        > .container {
            @include properties($m-search-results-container);
        }
    }

    .search-results-data {
        @include properties($m-search-results-data);

        > .container {
            @include properties($m-search-results-data-container);
        }
    }

    .domestic-sources-filter-container {
      @include properties($m-search-results-domestic-sources-filter-container);
    }

    .search-results-toolbar {
        @include properties($m-search-results-data-toolbar);

        > .container {
            @include properties($m-search-results-data-toolbar-container);

            .search-result-params-list{
                @include properties($m-search-result-params-list);
            }

            .search-type-param {
                @include properties($m-search-result-params-search-type);
            }

            .horizontal-label {
                @include properties($m-search-results-horizontal-label);

                .field-label {
                    @include properties($m-search-results-no-padding);
                }
            }
        }
    }

    .deferred .search-results-toolbar .search-type-param {
        @include properties($m-search-result-deferred-params-search-type);
    }

    .search-results-content {
        @include properties($m-search-results-content);

        > .container {
            @include properties($m-search-results-content-container);
        }
    }

  .deferred-four-eight-alert {
    @include properties($m-search-result-deferred-four-eight-alert);
  }
}

.potential-source-info-ico {
  @include properties($m-search-results-potential-source-info-ico);
  font-weight: normal;
}

.current-search-tooltip {
  @include properties($m-search-results-current-search-tooltip);
}
