@import 'node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'fn/index';
@import 'mixins/icon';
@import 'mixins/checkbox';
@import 'variables';

$dropdown-checkbox-color: #000;

@mixin dropdown-container {
  border-color: $color-text-main-2;
  border-radius: rem(2);
  font-size: rem(13);
}

@mixin dropdown-panel($parentSelector: '') {
  #{$parentSelector}.ng-dropdown-panel {
    @include dropdown-container;
    border-color: $color-link;

    .ng-option {
      color: $color-text-primary;
      min-height: rem(29);

      &.ng-option-disabled {
        opacity: 0.5;
      }

      &.ng-option-selected:not(.ng-option-disabled),
      &.ng-option-marked:not(.ng-option-disabled) {
        background-color: $dropdown-menu-item-background-color-hover;
      }

      &:first-child {
        &.ng-option-marked {
          background: $white;
          color: $color-text-primary;
        }

        &:hover:not(.ng-option-disabled) {
          background-color: $dropdown-menu-item-background-color-hover;
        }
      }

      &:first-child {
        &.ng-option-disabled {
          background: $white;
          cursor: default;
          user-select: none;

          &:hover {
            background-color: $white;
          }
        }
      }
    }

    &.ng-select-bottom {
      .ng-dropdown-panel-items {
        .ng-option {
          &:last-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .ng-option-label {
          color: $color-text-primary;
        }
      }
    }

    &.ng-select-filtered {
      .ng-option:first-child {
        border: rem(1) solid $c-tabs-tab-link-active-border-color;
        margin-top: rem(1);
      }
    }
  }
}


.ng-select.ms-drop-down-short {
  height: $ms-field-control-short-height;
  min-height: $ms-field-control-short-height;

  .ng-select-container {
    height: $ms-field-control-short-height;
    min-height: $ms-field-control-short-height;
  }
}

.ng-select {
  @for $max-number-of-displayed-lines from 3 through 7 {
    &.ms-drop-down-panel-#{$max-number-of-displayed-lines}-rows .ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: $ms-field-control-regular-height * $max-number-of-displayed-lines;
    }

    // Fix ng-option height and remove '+ 1'
    // Height of short ng-option is bigger than regular height
    &.ms-drop-down-short.ms-drop-down-panel-#{$max-number-of-displayed-lines}-rows .ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: $ms-field-control-short-height * ($max-number-of-displayed-lines + 1);
    }
  }
}

.ng-select {
  &.ng-select-multiple.custom-checkbox-theme {
    .ng-select-container.ng-has-value {
      .ng-value-container {
        .ng-value {
          align-items: center;
          display: flex;
          flex-direction: row-reverse;
          font-size: rem(14);

          .ng-value-icon {
            &.left {
              border-right: rem(1) solid transparent;
              color: #75b0c3;
              font-size: rem(21);
            }

            &:hover {
              background-color: #ebf5ff;
            }
          }
        }
      }
    }
  }
}

.notional-phenotype {
  .ng-select {
    .ng-select-container {
      background-color: $color-action-4;
    }
  }
}

@include dropdown-panel('.ms-drop-down');

.field-wrapper {
  &.invalid {
    .ms-drop-down.ng-select .ng-select-container {
      border-color: #d64848;
    }
  }
}

.ng-select.ms-drop-down {
  @include dropdown-panel;

  &--multiline {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      line-height: rem(16);
      white-space: normal;
    }
  }

  &.ng-select-disabled > .ng-select-container {
    background: #fff;
    border-color: #dee6eb;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.ng-select-filtered {
    .ng-option:first-child {
      border: rem(1) solid $c-tabs-tab-link-active-border-color;
      margin-top: rem(1);

      &:hover {
        background-color: $dropdown-menu-item-background-color-hover;
      }
    }
  }

  &.invalid {
    .ms-drop-down.ng-select .ng-select-container {
      border-color: #d64848;
    }
  }

  .ng-select-container {
    @include dropdown-container;
  }

  &.ng-select-multiple:not(.custom-checkbox-theme) {
    .ng-select-container {
      .ng-value-container {
        padding: 0;

        .ng-value {
          background-color: transparent;
          margin: 0;

          &::after {
            content: ',';
          }

          .ng-value-icon {
            display: none;
          }
        }

        .ng-input {
          display: none;
        }
      }
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      box-shadow: none !important;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border-color: $color-link;
    }
  }

  &:not(.ng-select-opened):not(.ng-select-focused) {
    .ng-has-value {
      .ng-clear-wrapper {
        visibility: hidden;
      }

      &:hover {
        .ng-clear-wrapper {
          visibility: visible;
        }
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          height: rem(19);
          line-height: rem(20);
        }

        .ng-input {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .ng-clear-wrapper {
        @include set_ico(
          (
            symbol: cross,
            type: left,
            font_size: rem(10),
            width: rem(32),
            height: rem(34),
            behavioral: custom,
            initial_bg_color: $transparent,
            initial_color: #91acbc,
            initial_ico_bg_color: $transparent,
            initial_ico_color: $color-text-main-2,
            hover_bg_color: $transparent,
            hover_color: $color-link,
            hover_ico_bg_color: inherit,
            hover_ico_color: $color-link,
          )
        );

        .ng-clear {
          font-size: 0;
        }

        &:hover {
          .ng-clear {
            color: $color-link;
          }
        }
      }
    }
  }

  &.ng-select-multiple:not(.custom-checkbox-theme) {
    .ng-select-container .ng-value-container {
      /* stylelint-disable-next-line */
      -webkit-box-orient: vertical;
      /* stylelint-disable-next-line */
      display: -webkit-box;
      /* stylelint-disable-next-line */
      -webkit-line-clamp: 2;
      overflow: hidden;

      padding: 0 rem(10);

      .ng-value {
        display: inline-block;
        line-height: rem(14);

        .ng-value-label {
          padding-right: 0;
        }

        &::after {
          content: ', ';
        }

        &:nth-last-child(2)::after {
          content: '';
        }
      }
    }
  }
}

.ms-drop-down.checkbox-theme,
.ng-dropdown-panel.checkbox-theme {
  .ng-option {
    padding: 0;

    span {
      @include checkbox-unchecked;
      @include set-checkbox-color($dropdown-checkbox-color);

      line-height: $checkbox-height;

      &::before {
        display: inline-block;
      }
    }

    &.ng-option-selected span {
      @include checkbox-checked;
      @include set-checkbox-color($dropdown-checkbox-color);
    }
  }
}

:not(.nsm-dialog) .ng-dropdown-panel {
  z-index: 1000;
}
