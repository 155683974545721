@import 'variables';

$m-top-header-height: rem(64);
$m-top-header-link-color: $color-brand-main-2;

$m-top-header-logo-area-width: rem(395);
$m-top-header-logo-width: rem(182);
$m-top-header-logo-height: rem(44);
$m-top-header-wordmark-width: rem(114);
$m-top-header-wordmark-height: rem(23);
$m-top-header-search-area-input-width: 100%;

$m-top-header-link-hovered-color: #4398D2;
$m-top-header-link-selected-color: #004875;
$m-top-header-search-link-color: #586C78;

$m-top-header-search-type-choice-opened-background-color: #DDE4EA;

/**************************************************************************************************
 LOGO AREA
**************************************************************************************************/
$m-top-header-logo-area: (
    size: (
        width: $m-top-header-logo-area-width,
        min-width: $m-top-header-logo-area-width
    )
);
$m-top-header-logo-container: (
    position: relative,
    size: 100%,
    flexbox: (row, center, flex-start)
);
$m-top-header-logo: (
    margin: 0 rem(10) 0 0
);
$m-top-header-logo-img: (
    block: (
        display: block
    ),
    size: (
        width: $m-top-header-logo-width,
        min-width: $m-top-header-logo-width,
        height: $m-top-header-logo-height
    )
);
$m-top-header-wordmark: (
    block: (
        display: block
    ),
    size: (
        width: $m-top-header-wordmark-width,
        min-width: $m-top-header-wordmark-width,
        height: $m-top-header-wordmark-height
    ),
    text: (
        color: $white,
        font-size: rem(19),
        line-height: $m-top-header-wordmark-height
    ),
    font: (
        style: italic
    )
);
$m-top-header-wordmark-sm: (
    position: (relative, rem(-8) null null null),
    text: (
        font-size: rem(8),
        transform: uppercase
    ),
);

/**************************************************************************************************
 SEARCH AREA
**************************************************************************************************/
$m-top-header-search-area: ();
$m-top-header-search-area-container: (
    position: relative,
    size: 100%,
    flexbox: (row, center),
    text-align: right,
);
$m-top-header-search-area-container-item: (
    // margin: 0 0 0 rem(12),
    size: (
        height: rem(32)
    )
);
$m-top-header-search-area-field-group: (
    size: (
        height: rem(32)
    )
);
$m-top-header-search-area-input: (
    padding: 0 rem(12),
    size: (
        height: rem(32),
        width: $m-top-header-search-area-input-width
    ),
);
$m-top-header-search-area-field-group-button: ();
$m-top-header-search-area-search-button: (
    block: (
        outline: none,
    ),
    background: (
        color: $white,
    )
);
$m-top-header-search-area-search-button-loup: (
    block: (
        display: block
    ),
    size: rem(22),
    icon: (
        type: only,
        symbol: loup,
        font_size: rem(18),
        width: rem(22),
        height: rem(22),
        behavioral: $default_icon_gray_blue_behavioral

    )
);
$m-top-header-link: (
    position: relative,
    block: (
        cursor: pointer
    ),
    border: (
        width: 0,
        radius: rem(2)
    ),
    size: (
        height: rem(32),
        width: rem(32),
        min-width: rem(32)
    ),
    flexbox: (row, center, center),
    background: (
        color: $m-top-header-link-color
    ),
    text:(
        text-decoration: none
    )
);
$m-top-header-link-hover: (
    background: (
        color: $m-top-header-link-hovered-color
    )
);
$m-top-header-link-selected: (
    background: (
        color: $m-top-header-link-selected-color
    )
);
$m-top-header-link-plus: (
    block: (
        display: block
    ),
    size: rem(22),
    icon: (
        type: only,
        symbol: plus,
        font_size: rem(14),
        width: rem(22),
        height: rem(22),
        behavioral: $default_icon_white
    )
);
$m-top-header-link-dropdown-menu: (
    position: (absolute, null auto null 0)
);
$m-top-header-link-counter: (
    position: (absolute, rem(-12) null null rem(24)),
    block: (
        display: block,
        box-sizing: border-box
    ),
    size: (
        height: rem(18),
        min-width: rem(18)
    ),
    border: (
        radius: rem(1000),
        style: solid,
        width: rem(1),
        color: $color-brand-main-1
    ),
    background: (
        color: $color-highlight
    ),
    text: (
        color: $white
    ),
    font: (
        size: rem(10),
        family: Arial,
        weight: bold
    ),
    padding: rem(3) rem(5)
);

/**************************************************************************************************
 SEARCH TYPE DROPDOWN
**************************************************************************************************/
$m-top-header-search-type: (
    text: (
        font-size: rem(13)
    )
);

$m-top-header-search-type-link: (
    position: relative,
    block: (
        display: block,
        overflow: hidden,
        outline: none,
        box-sizing: border-box,
        box-shadow: none,
        cursor: pointer,
    ),
    text: (
        text-decoration: none,
        line-height: 2.125rem,
    ),
    border: (
        width: 0 rem(1) 0 0,
        style: solid,
        color: $color-text-light-1,
        radius: 0.125rem 0 0 0.125rem,
    ),
    size: (
        height: 2rem,
    ),
    padding: 0 0 0 0.75rem,
    margin: 0,
    background: (
        color: $color-text-main-3
    ),
);

$m-top-header-search-type-link-open: (
    background: (
        color: $m-top-header-search-type-choice-opened-background-color,
    ),
    border: (
        radius: 0.125rem 0 0 0,
    ),
);

$m-top-header-search-type-link-value: (
    block: (
        display: block,
        overflow: hidden,
        white-space: nowrap,
        text-overflow: ellipsis,
    ),
    size: (
        width: auto,
        height: rem(30),
    ),
    margin: 0 2.2rem 0 0,
    text: (
        font-size: rem(13),
        line-height: rem(30),
        color: $color-text-primary,
    ),
);

$m-top-header-search-type-link-after: (
    position: (absolute, null rem(12) null null),
    background: (
        color: transparent,
        image: none,
    ),
    size: (
        top: 50%,
        height: 0,
        width: 0,
    ),
    margin: -0.1875rem 0 0 -0.3125rem,
    border: (
        style: solid,
        width: 0.3125rem 0.3125rem 0,
        color: $color-border transparent transparent,
    ),
);

$m-top-header-search-type-link-after-open: (
    border: (
        style: solid,
        width: 0 0.3125rem 0.3125rem,
        color: transparent transparent $color-border,
    ),
);

$m-top-header-search-type-dropdown-menu: (
    border: (
        radius: 0 0 0.125rem 0.125rem,
    ),
    size: (
        min-width: auto,
    ),
);

$m-top-header-search-type-dropdown-menu-item: (
    text: (
        font-size: rem(13),
        line-height: rem(32),
    ),
    size: (
        height: rem(32),
    ),
    margin: 0,
);

$m-top-header-search-type-dropdown-menu-item-link: (
    text: (
        font-size: rem(13),
    ),
);


/**************************************************************************************************
 USER AREA
**************************************************************************************************/
$m-top-header-user-area: (
    size: (
        width: $m-top-header-logo-area-width
    )
);
$m-top-header-user-area-container: (
    size: (
        width: 100%
    ),
    text: (
        font-size: rem(13),
        line-height: rem(15px)
    ),
);
$m-top-header-user-area-link: (
    text: (
        color: $white,
        text-decoration: none,
        line-height: rem(24)
    ),
    block: (
        outline: none,
        cursor: pointer
    )
);
$m-top-header-user-area-link-dropdown-arrow: (
    border: (
        color: $color-text-main-3 transparent transparent
    )
);
$m-top-header-user-area-link-dropdown-arrow-open: (
    border: (
        color: transparent transparent $color-text-main-3,
        width: 0 0.3125rem 0.3125rem
    )
);
$m-top-header-user-area-link-dropdown-menu: (
    position: (absolute, null rem(10) null null),
    size: (min-width: rem(252))
);

/**************************************************************************************************
 MAIN CONTENT
**************************************************************************************************/
$m-main-content: (
    position: relative,
    size: 100%,
    flexbox: (column),
    block: (
        box-sizing: border-box,
        overflow: hidden
    ),
    padding: 0
);
$m-main-content-container: (
    position: relative,
    size: 100%
);

$m-form-due-remove-icon: (
  icon: (
    type: only,
    symbol: plus,
    font_size: rem(12),
    width: rem(20),
    height: rem(20),
    behavioral: $default_icon_dark_red_behavioral
  )
);
