.scroll-wrapper {
    @include properties($c-perfect-scroll-container);
}

.ms-scrollable {
    @include properties($g-scrollable);
}

.ps-always-visible.ps-container {
    > .ps-scrollbar-x-rail,
    > .ps-scrollbar-y-rail {
        @include properties($c-perfect-scroll-always-visible);
    }
}

.ps__rail-y {
  z-index: 103;
}
