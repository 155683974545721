.ms-grid-table {
    @include properties($g-grid-table);

    &.ms-grid-table--ui-grid {
      @include properties($g-grid-table-ui-grid);

      &.grid-table-striped {
        tbody {
          tr:nth-child(2n + 1) {
            @include properties($g-grid-table-ui-grid-row-odd);
          }
        }
      }

      thead tr th {
        @include properties($g-grid-table-ui-grid-headers);
      }
    }

    &.ms-grid-table--no-border {
        thead tr th,
        thead tr td,
        tbody tr td {
          @include properties($g-grid-table-no-border-cell);
        }
    }

    .aligned-left { text-align: left; }
    .aligned-right { text-align: right; }
    .aligned-center { text-align: center; }
    .valigned-top { vertical-align: top; }
    .valigned-bottom { vertical-align: bottom; }

    .table-title {
        @include properties($g-grid-table-title);
    }

    thead {
        @include properties($g-grid-table-thead);

        tr {
            @include properties($g-grid-table-thead-tr);

            th {
                @include properties($g-grid-table-th);
                @include properties($g-grid-table-thead-th);

                &.double {
                    @include properties($g-grid-table-thead-th-double);
                }
            }

        }

        &:first-child {
            @include properties($g-grid-table-thead-first);
        }
    }

    tbody {
        @include properties($g-grid-table-tbody);

        tr {
            @include properties($g-grid-table-tbody-tr);

            th {
                @include properties($g-grid-table-th);
                @include properties($g-grid-table-tbody-th);

                &:first-child {
                    @include properties($g-grid-table-tbody-th-first);
                }

                & + td {
                    @include properties($g-grid-table-tbody-th-td-first);
                }
            }

            td {
                @include properties($g-grid-table-td);
                @include properties($g-grid-table-tbody-td);
            }

        }
    }

    &.grid-table-striped {
        tbody {
            tr:nth-child(2n + 1) {
                @include properties($g-grid-table-row-odd);
            }
            tr:nth-child(2n) {
                @include properties($g-grid-table-row-even);
            }
        }
    }

    tr.highlight {
        td {
            @include properties($g-grid-table-hightlight-cell);

            &:first-child {
                @include properties($g-grid-table-hightlight-first-cell);
            }

            &:last-child {
                @include properties($g-grid-table-hightlight-last-cell);
            }
        }

        & + tr.highlight {
            td {
                @include properties($g-grid-table-hightlight-sibling-cell);

                &:first-child {
                    @include properties($g-grid-table-hightlight-sibling-first-cell);
                }

                &:last-child {
                    @include properties($g-grid-table-hightlight-sibling-last-cell);
                }
            }
        }
    }
}
