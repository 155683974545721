/* You can add global styles to this file, and also import other style files */
@import '../node_modules/om-perfect-scrollbar/css/perfect-scrollbar.css';
@import './scss/drag-n-drop.scss';
@import '../node_modules/@angular/cdk/overlay-prebuilt.css';
@import '../node_modules/tippy.js/dist/tippy.css';
@import './scss/tippy-override.scss';

/* After migration should be moved to scss/layouts/_common.scss file */
ui-view-ng-upgrade {
  height: 100%;
}

/* Fix datepicker z-index issue */
.cdk-overlay-container {
  z-index: 2000;
}
