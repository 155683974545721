@import 'fn/index';

input[msTextInput][type=text] {
  background-color: $white;
  border-color: $color-text-main-2;
  border-radius: $text-input-radius;
  border-style: solid;
  border-width: $text-input-width;
  box-sizing: border-box;
  color: $color-text-primary;
  font-size: $text-input-font-size;
  height: $text-input-height;
  margin: 0;
  padding: $text-input-padding;
  text-indent: 0;
  width: 100%;

  &:hover {
    border-color: $g-field-input-hovered-border-color;
  }
}
