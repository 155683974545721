.orders-grid{
  @include properties($c-orders-grid);

  & > .table-area{
    @include properties($c-orders-grid-table);

    .table-header {
      @include properties($c-orders-grid-table-header);
    }

    .table-header-fixed {
      width: 100%;
    }

    .button-wrapper + .button-wrapper {
      margin: 0;
    }

    .table-body {
      @include properties($c-orders-grid-table-body);

      td {
        @include properties($c-orders-grid-table-body-cell);
      }

      td:not(.locus){
        @include properties($c-orders-grid-table-body-cell-default);
      }

      .source-orders-wrapper {
        padding-left: 0;
        padding-right: 0;
      }

      .cell-field {
        min-height: 17px;
      }
    }

    .table-body {
      .locus.combine-locus {
        .ms-hla-locus-view:not(.has-match-likelihood) {
          padding-top: rem(8);
        }
      }
    }

    .table-body-fixed {
      td {
        @include properties($c-orders-grid-table-body-cell);
      }

      td:not(.locus){
        @include properties($c-orders-grid-table-body-cell-default);
      }

      .cell-field {
        min-height: 17px;
      }

      .source-order {
        & + .source-order {
          border-color: transparent;
        }
      }

      .order-fields-list {
        height: 3.625rem;
      }
    }

    .source:nth-child(odd){
      @include properties($c-orders-grid-source-odd);
    }

    .source-row{
      @include properties($c-orders-grid-source-row);
    }

    .tnc-cd34 {
      @include properties($c-orders-grid-table-body-donor-grid-tnc-cd34-cell);
    }

    .expand{
      @include properties($c-orders-grid-table-donor-grid-expand-cell);
    }

    .cart-cell{
      @include properties($c-orders-grid-table-donor-grid-cart-cell);
    }

    .id-grid{
      @include properties($c-orders-grid-table-donor-grid-id-cell);
    }

    .race{
      @include properties($c-orders-grid-table-donor-grid-race-cell);
    }

    .age-sex{
      @include properties($c-orders-grid-table-donor-grid-age-sex-cell);
    }

    .cmv-abo{
      @include properties($c-orders-grid-table-donor-grid-cmv-cell);
    }

    .preg-weight, .status-ion{
      @include properties($c-orders-grid-table-donor-grid-preg-cell);
    }

    .vaccination-type-status {
      width: rem(125);
    }

    .vaccination-date-manufacturer {
      width: rem(156);
    }

    .status-reg-cbb {
      @include properties($c-orders-grid-table-donor-grid-status-reg-cbb-cell);
    }

    .mcat{
      @include properties($c-orders-grid-table-donor-grid-mcat-cell);
    }

    .tnc-cd34 {
      @include properties($c-orders-grid-table-donor-grid-tnc-cd34-cell);
    }

    .dob-sex {
      @include properties($c-orders-grid-table-donor-grid-dob-sex-cell);
    }

    .hgb-bloodtype {
      @include properties($c-orders-grid-table-donor-grid-hgb-bloodtype-cell);
    }

    .plasma-rbc {
      @include properties($c-orders-grid-table-donor-grid-plasma-rbc-cell);
    }

    .eligibility-licensure {
      @include properties($c-orders-grid-table-donor-grid-eligibility-licensure-cell);
    }

    .locus{
      @include properties($c-orders-grid-table-donor-grid-locus-cell);

      &.dpb1-expression {
        width: rem(140);
      }

      & span{
        @include properties($c-orders-grid-table-donor-grid-locus-typing);
      }

      &.combine-locus{
        @include properties($c-orders-grid-table-donor-grid-locus-combine);

        &.dpb1-tce-expression {
          width: rem(260);
        }
      }
    }

    .dpb1-expression-match-status {
      text-transform: capitalize;
    }

    .drbx-score {
      width: rem(120);
    }

    .composite-prediction {
      @include properties($c-orders-grid-table-donor-grid-composite-prediction-cell);
    }

    .composite-prediction-cords {
      @include properties($c-orders-grid-table-donor-grid-composite-prediction-cords-cell);

      .composite-prediction-cords-list {
        display: flex;
      }

      .composite-prediction-cords-item {
        margin-right: 16px;
      }
    }

    .dpb1-tce-match {
      @include properties($c-orders-grid-table-donor-grid-dpb1-tce-match);
    }

    .table-header-area{
      .dpb1-tce{
        @include properties($c-orders-grid-table-donor-grid-dpb-cell);
      }
    }

    .content{
      @include properties($c-orders-grid-table-content);
    }
  }

  .collapse-link-arrow-ico {
    @include properties($c-orders-grid-collapse-link-arrow-ico);

    &.open{
      @include properties($c-orders-grid-collapse-link-arrow-ico-open);
    }
  }

  .cart-icon {
    @include properties($m-search-results-donor-grid-cart-icon);
  }

  .ms-link{
    @include properties($c-orders-grid-link);

    &:hover{
      @include properties($c-orders-grid-link-hovered);
    }

    &.workup-id{
      @include properties($c-orders-grid-link-order-id);
    }

    &.donor-id{
      @include properties($c-orders-grid-link-donor-id);
    }
  }

  .source-orders{
    @include properties($c-orders-grid-source-orders);

    &.open{
      @include properties($c-orders-grid-source-orders-open);
    }
  }

  .source-order {
    @include properties($c-orders-grid-source-order);
    padding-left: .5rem;
    padding-right: .5rem;

    & + .source-order {
      @include properties($c-orders-grid-source-order-n);
    }
  }

  .order-grid{
    @include properties($c-orders-grid-orders-container);
  }
}
