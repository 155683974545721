.ms-data-grid {
    @include properties($c-data-grid);

    & > .container {
        @include properties($c-data-grid-container);
    }

    .ms-data-grid-header {
        @include properties($c-data-grid-header);
    }

    .ms-data-grid-body {
        @include properties($c-data-grid-body);
    }

    .collapse-link-arrow-ico {
        @include properties($c-data-grid-collapse-link-arrow-ico);

        &.open {
            @include properties($c-data-grid-collapse-link-arrow-ico-open);
        }
    }

    .ms-link {
        @include properties($c-data-grid-link);

        &:hover {
            @include properties($c-data-grid-link-hovered);
        }
    }

    .field {
        @include properties($c-data-grid-field);
    }

    thead {
        @include properties($c-data-grid-table-header);

        &:first-child {
            @include properties($c-data-grid-table-header-row);
        }

        td {
            @include properties($c-data-grid-table-header-cell);

            & > span {
                @include properties($c-data-grid-table-cell-header-content);
            }
        }
    }

    tbody {
        @include properties($c-data-grid-table-body);

        &:nth-child(odd) {
            @include properties($c-data-grid-table-body-odd);
        }

        td {
            @include properties($c-data-grid-table-body-cell);
        }

        .tr {
            @include properties($c-data-grid-table-body-row);
        }
    }

    .fixed-part {
        @include properties($c-data-grid-fixed-part);

        .ms-data-grid-body {
            @include properties($c-data-grid-fixed-body);
        }
    }

    .regular-part {
        @include properties($c-data-grid-regular-part);

        .ms-data-grid-body {
            @include properties($c-data-grid-regular-body);
        }
    }
}

.ms-data-grid.poc {

  virtual-scroller {
    display: block;
    height: 100%;
  }

  .fixed-part .ms-data-grid-body {
    virtual-scroller {
      padding-right: -25px;
      margin-right: -25px;
    }

    overflow-y: hidden;
    overflow-x: hidden;
  }

  .regular-part .ms-data-grid-body {
    overflow-y: hidden;
    overflow-x: hidden;
  }
}
