@import '../../../projects/ngx-smart-modal/dist/src/lib/styles/ngx-smart-modal';
@import 'fn/index';

.nsm-dialog {
  left: 50%;
  max-width: none;
  top: 50%;
  transform: translate(-50%, -50%);

  .nsm-content {
    left: 50%;
    margin-left: 0;
    margin-top: 0;
    transform: translate(-50%);

    .modal-header {
      color: $color-border;
      font-size: rem(16);
      margin-bottom: rem(16);
      margin-top: rem(25);
    }

    .modal-body {
      color: $color-text-primary;
      font-size: rem(13);
      line-height: rem(16);
      margin-bottom: rem(40);
    }

    .modal-footer {
      display: flex;
      justify-content: center;
    }
  }

  .nsm-dialog-btn-close svg {
    display: none;
  }

  .nsm-dialog-btn-close {
    $font-color: #767676;
    $initial-color: #91acbc;

    @include set_ico((
      symbol: cross,
      type: left,
      font_size: rem(12),
      width: rem(32),
      height: rem(34),
      behavioral: custom,
      initial_bg_color: $transparent,
      initial_color: $initial-color,
      initial_ico_bg_color: $transparent,
      initial_ico_color: $color-text-main-2,
      hover_bg_color: $transparent,
      hover_color: $color-link,
      hover_ico_bg_color: inherit,
      hover_ico_color: $color-link
    ));
    color: $font-color;
    font-size: rem(13);
    position: absolute;
  }

  .overlay.nsm-overlay-open {
    overflow: hidden;
  }
}

.ms-dialog-overlay {
  .nsm-content {
    width: rem(500);
  }
}

.compare-hla-modal {
  .nsm-content {
    width: rem(700);
  }
}
