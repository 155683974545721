.moment-picker {
    .moment-picker-container {
        @include properties($c-datepicker-container);
    }
    th {
        @include properties($c-datepicker-th);

        &:first-child, &:last-child {
            @include properties($c-datepicker-th-bound);
        }
    }
    td {
        @include properties($c-datepicker-td);

        &:hover {
            @include properties($c-datepicker-td-hover);
        }
        &.selected {
            @include properties($c-datepicker-td-selected);
        }
        &.disabled {
            @include properties($c-datepicker-td-disabled);
            pointer-events: none;
        }
    }
    .header-view {
        th {
            @include properties($c-datepicker-header-th);
        }
    }
}