.filters-wrapper{
    @include properties($c-filters-wrapper);

    .datepicker {
        &.datepicker-dropdown{
            &.datepicker-orient-bottom{
                @include properties($c-filters-datepicker);
            }
        }
    }

    .filters-list{
        @include properties($c-filters-list);

        & > li{
            @include properties($c-filters-list-item);

            & ~ li{
                @include properties($c-filters-list-item-reset);
            }
        }

        .filter-label{
            @include properties($c-filters-label);
        }

        ms-field{
            @include properties($c-filters-ms-field);
        }

        .clear-filters-btn{
            @include properties($c-filters-clear-filters-btn);
        }
    }
}