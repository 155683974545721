
$c-select: (
    size: (
        height: rem(36),
    ),
    block: (
        overflow-y: visible,
        box-sizing: border-box,
        outline: none,
    ),
    position: (relative),
    text: (
        color: $color-text-primary
    ),
    padding: 0,
);
$c-select-open: (
    position: (relative),
    block: (
        index: 2,
    ),
);
$c-select-ctrls: (
    flexbox: (column),
    size: (
        height: 100%,
    ),
);
$c-select-wrapper: (
    block: (
        display: none,
    )
);
$c-select-wrapper-open: (
    position: (relative, null null null null),
    block: (
        display: block,
        index: 1,
    ),
    border: (
        style: solid,
        color: $color-link,
        width: (0 rem(1) rem(1) rem(1)),
        radius: (0 0 rem(2) rem(2))
    ),
    size:(
        max-height: rem(136),
    ),
    custom: (
        -ms-overflow-style: scrollbar
    )
);
$c-select-container: (
    background: white,
);
$c-select-container-empty: (
    padding: rem(10),
    font: $font-italic-properties,
    text: (
        font-size: 1.3,
    ),
);
$c-select-option: (
    position: (relative, null null null null),
    block: (
        display: block,
        cursor: pointer,
    ),
    padding: (0),
    text: (
        font-size: 0,
    ),
);
$c-select-option-hover: (
    background: (
        color: #DBEDFF,
    ),
);
$c-select-option-matched: (
    block: (
        box-shadow: 0 0 1px 1px inset #BDCD42,
    ),
);
$c-select-show: (
    position: (absolute, rem(0) rem(0) null null),
    size: 0,
);
$c-select-clean-icon: (
    position: (absolute, 50% rem(24) null null),
    custom: (
        margin-top: rem(-17),
    ),
    block: (
        display: none,
    ),
    size: (
        width: rem(34),
        height: rem(34),
    ),
    icon: (
        type: right,
        position: (absolute, null null null null),
        symbol: cross,
        font_size: rem(11),
        width: $g-field-icon-width,
        height: $g-field-icon-height,
        behavioral: $default_icon_grey_blue_behavioral
    )
);
$c-select-clean-icon-visible: (
    block: (
        display: block,
    ),
);
$c-select-clean-icon-hidden: (
    block: (
        display: none,
    ),
);
$c-select-arrow-size: rem(5);
$c-select-arrow-color: $color-text-main-2;
$c-select-arrow-color-hovered: #586C78;
$c_select_arrow-ico-open: (
    position:(absolute, rem(15) rem(10) null null),
    border: (
        style: solid,
        width: 0 $c-select-arrow-size $c-select-arrow-size $c-select-arrow-size,
        color: transparent transparent $c-select-arrow-color
    )
);
$c_select_arrow-ico-open-hovered: (
    border: (
        style: solid,
        width: 0 $c-select-arrow-size $c-select-arrow-size $c-select-arrow-size,
        color: transparent transparent $c-select-arrow-color-hovered,
    )
);
$c_select_arrow-ico: (
    position:(absolute, rem(15) rem(10) null null),
    border: (
        style: solid,
        width: $c-select-arrow-size $c-select-arrow-size 0 $c-select-arrow-size,
        color: $c-select-arrow-color transparent transparent
    )
);
$c_select_arrow-ico-hovered: (
    border: (
        style: solid,
        width: $c-select-arrow-size $c-select-arrow-size 0 $c-select-arrow-size,
        color: $c-select-arrow-color-hovered transparent transparent,
    )
);

$c-select-toggle-icon: (
    position: (absolute, 50% rem(1) null null),
    custom: (
        margin-top: rem(-17),
    ),
    size: (
        width: rem(24),
        height: rem(34),
    ),
    block: (
        cursor: pointer,
    ),
);

$c-select-search-input: map_merge(
    $g-field-input,
    (
        block: (
            overflow: hidden,
        ),
        ellipsis: (),
        custom: (
            padding-right: rem(36) !important,
            width: 'auto',
            height: 100%,
            box-sizing: border-box,
        ),
    )
);

$c-select-search-input-not-empty: (
    custom: (
        padding-right: rem(50) !important,
    ),
);

$c-select-component: (
    flexbox: (column),
    size: (
        width: 100%,
        height: 100%,
    ),
    position: relative,
);

$c-select-option-label: map_merge(
    $g-field-radio-label,
    $g-field-option-radio-label
);

$c-select-search-input-focus: $g-field-active-input;

$c-select-narrow: (
    size: (
        height: rem(28),
    ),
);

$c-multiselect-dropdown-list-item-label-narrow: (
    custom: (
        min-height: rem(27),
        line-height: rem(27),
    ),
);
$c-select-wrapper-open-narrow: (
    size:(
        max-height: rem(160),
    ),
);
.ms-select {
  .ms-select-container {
    .ms-select-option[disabled] {
      &:hover {
        background-color: transparent;
      }
      input[type=radio] + label{
        cursor: default;
        pointer-events: none;
        opacity: .3;
        font-width: normal;
      }
    }
  }
}
