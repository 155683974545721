.ms-grid {
    @include properties($g-grid);

    > .container {
        @include properties($g-grid-container);
    }

    .ms-grid-row {
        @include properties($g-grid-row);

        + .ms-grid-row {
            @include properties($g-grid-row-row);
        }
    }

    .ms-grid-column {
        @include properties($g-grid-column);

        + .ms-grid-column {
            @include properties($g-grid-column-n-column);
        }
    }

    .ms-grid-ctrls-area {
        @include properties($g-grid-ctrls-area);

        > .container {
            @include properties($g-grid-ctrls-area-container);
        }
    }

    .ms-grid-header {
        @include properties($g-grid-header);

        .ms-grid-row {
            @include properties($g-grid-header-row);
        }

        .ms-grid-column {
            @include properties($c-grid-header-column);
        }

        .ms-grid-checkbox-container {
            @include properties($g-grid-header-checkbox-container);
        }

        .ms-grid-checkbox {
            @include properties($g-grid-header-checkbox);
        }
    }

    .ms-grid-data {
        @include properties($g-grid-data);

        .ms-grid-row:hover:not(.grid-row-heading) {
            @include properties($g-grid-data-row-hover);
        }
    }

    &.grid-striped {
        .ms-grid-data {
            .ms-grid-row:nth-child(2n + 1) {
                @include properties($g-grid-data-row-odd);
            }
            .ms-grid-row:nth-child(2n) {
                @include properties($g-grid-data-row-even);
            }
        }
    }

    .ms-grid-label {
        @include properties($c-grid-label);
    }

    .ms-grid-value {
        @include properties($g-grid-value);
    }

    a.ms-grid-value {
        @include properties($g-grid-link);
    }

    .ms-grid-empty-message {
        @include properties($g-grid-empty-message);
    }

    .ms-grid-checkbox-container {
        @include properties($g-grid-checkbox-container);
    }

    .ms-grid-checkbox {
        @include properties($g-grid-checkbox);
    }
}

ms-grid {
    &,
    >.container {
        @include properties($c-ui-grid-wrapper-container);
    }
}