.ms-collapse {
    @include properties($c-collapse);

    .collapse-link {
        @include properties($c-collapse-link);
    }

    .collapse-link-title {
        @include properties($c-collapse-link-title);
    }

    .collapse-link-arrow {
        @include properties($c-collapse-link-arrow);
    }

    .collapse-link-arrow-ico {
        @include properties($c-collapse-link-arrow-ico);
    }

    &.collapsed {

        .collapsible {
            display: none;
        }

        .collapse-link-arrow {
            transform: rotate(-90deg);
        }
    }
}
