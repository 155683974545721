.export-sources-dialog {
  .selection-type-column {
      padding-right: rem(117);
  }

  .field-wrapper {
    .component-wrapper {
      ms-radio {
        input[type=radio] + label {
          line-height: rem(24);

          &::before {
            text-align: left;
            line-height: rem(24);
            width: rem(24);
          }
        }
      }
    }
  }
}
