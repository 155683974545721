@import 'variables';
@import 'mixins/icon';
@import 'mixins/icon-fonts';

@import '../../../projects/nmdp-ui/src/lib/styles';

@import 'fn/index';
@import 'common/toast';
@import 'common/modal';
@import 'common/drag';
@import 'common/tooltip';
@import 'common/virtual-scroll';

.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body > ngx-smart-modal {
  font-family: Arial, sans-serif;
}
