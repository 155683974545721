$m-search-results-cart-icon-base: (
    type: only,
    symbol: cart,
    font_size: rem(16),
    width: rem(20),
    height: rem(20),
    behavioral: $default_icon_dark_blue_behavioral
);

$m-search-results: (
    position: relative,
    size: 100%,
    flexbox: (column, null, flex-start),
    block: (
        box-sizing: border-box
    ),
    border: (
        style: solid,
        color: #DBDBDB,
        width: 0 0 0 rem(1)
    )
);
$m-search-results-area: (
    block: (
        overflow: hidden
    )
);
$m-search-results-buttons-margin: (
    margin: ( null null null rem(16))
);

$m-search-results-shopping-cart-button-container: (
    block: (
        display: flex
    )
);

$m-search-results-date-msg-field: (
    font: (
        size: rem(12)
    ),
    text: (
        color: #1D3649
    ),
    padding: (0 rem(16) 0 0)
);

$m-search-results-maintenance-end-date: (
    block: (
        display: inline-block,
        box-sizing: border-box
    ),
    size: (
        height: rem(20)
    ),
    border: (
        radius: rem(2)
    ),
    padding: rem(4) rem(8) 0 rem(8),
    background: (
        color: $color-highlight
    ),
    text: (
        color: $white
    ),
    font: (
        size: rem(13),
        family: Arial,
        weight: normal
    ),
    margin: 0 0 0 rem(16),
);

$m-search-results-empty-grid-area: (
    padding: rem(30) 0 0 rem(20)
);

$m-search-results-not-maintained: (
    padding: rem(15) 0 0 rem(20),
    font: (
        size: rem(13),
        family: Arial,
        weight: normal
    ),
    text: (
        color: $color-text-primary
    ),
);

$m-search-results-search-deferred-msg: (
  padding: 0 0 0 rem(20),
  font: (
    size: rem(13),
    family: Arial,
    weight: normal
  ),
  text: (
    color: $color-text-primary
  ),
);

$m-search-results-failed: (
    size: (
      width: 100%,
    ),
);

$m-search-results-donor-grid-area: (
    position: relative,
    size: 100%,
    block: (
        overflow: hidden
    )
);

$m-search-results-donor-grid-container: (
    position: relative,
    size: 100%,
    block: (
        overflow: hidden
    )
);

$m-search-results-donor-grid-cart-icon: (
    block: (
        display: block
    ),
    size: rem(20),
    icon: $m-search-results-cart-icon-base,
);

$m-search-results-donor-grid-cell-field: (
    size: (
        min-height: rem(15)
    )
);
$m-search-results-donor-grid-cell-field-n-field: (
    margin: rem(4) 0 0 0
);
$m-search-results-donor-grid-cell-field-value: (
    font: (
        family: Arial,
        weight: normal
    ),
    text: (
        font-size: rem(13),
        line-height: rem(19)
    )
);
$m-search-results-donor-grid-cell-field-value-wrapper: (
    text: (
        white-space: pre
    )
);

$m-search-results-donor-grid-cell-allele-level-match-prop: (
    text: (
        font-size: rem(12),
        line-height: rem(14)
    )
);

$m-search-results-donor-grid-cell-hla-locus-complex: (
    text: (
        font-size: rem(12),
        line-height: rem(14)
    )
);

/**************************************************************************************************
 EXPORT PANEL AREA
**************************************************************************************************/
$m-export-donors-dialog-content: (
    size: (
        width: auto,
        min-width: rem(555),
    )
);
$m-export-opl-dialog-content: (
    size: (
        width: rem(560),
        min-width: rem(560),
    )
);
$m-search-results-export-panel-area: (
    size: (
        width: 100%
    ),
    block: (
        box-sizing: border-box,
        box-shadow: rem(1) rem(2) rem(3) 0 rgba(0,0,0,0.2),
        index: 1001
    ),
    padding: 0 rem(20) rem(10) rem(20)
);
$m-search-results-export-panel-container: (
    position: relative,
    size: 100%,
);
$m-search-results-export-ref-col: (
    size: (
        width: rem(95)
    ),
);
$m-search-results-export-selection-type-col: (
    size: (
        width: 50%
    ),
);
$m-export-panel-dialog-area: (
   padding: 0 0 0 0
);
$m-export-panel-dialog-section-column: (
    padding: 0 27px 0 0,
    block: (
        display: inline-block
    )
);
$m-search-results-export-panel-header: (
    position: relative,
    size: 100%
);
$m-search-results-export-panel-header-container: (
    position: relative,
    size: 100%,
    flexbox: (row, center, space-between)
);
$m-search-results-export-panel-toolbar-area: (
    text: (
        text-align: right
    )
);
$m-search-results-export-panel-required-mark: (
    block: (
        display: inline-block
    ),
    size: (
        width: rem(100),
    ),
    text: (
        font-size: 1.1,
        color: #1D3649,
        line-height: normal,
        text-align: left
    ),
    font: $font-regular-properties
);

$m-search-results-export-panel-section-content: (
    size: 100%
);
$m-search-results-export-panel-section-container: (
    size: 100%,
    flexbox: (row, flex-start, flex-start)
);
$m-search-results-export-panel-section-column: (
    padding: 0 rem(28) 0 0
);
$m-search-results-export-panel-section-column-last: (
    padding: 0
);
$m-search-results-export-panel-ctrls-area: ();
$m-search-results-export-panel-ctrls-container: (
    flexbox: (row, flex-start, flex-end)
);

/**************************************************************************************************
 SEARCH RESULTS AREA
**************************************************************************************************/
$m-search-results-container: (
    size: 100%,
    flexbox: (row, null, flex-start)
);

$m-search-results-data: (
    block: (
        overflow: hidden
    )
);
$m-search-results-data-container: (
    size: 100%,
    flexbox: (column, null, flex-start),
    block: (
        overflow: hidden
    )
);
$m-search-results-domestic-sources-filter-container: (
  flexbox: (row, null, flex-start)
);
$m-search-results-data-toolbar: (
    flexbox: (row, null, flex-start),
    size: (
        height: rem(55)
    ),
);
$m-search-results-data-toolbar-container: (
    size: 100%,
    flexbox: (row, center, flex-start),
    padding: 0 rem(16),
);

$m-search-result-params-list: (
    margin: (null rem(16) null null)
);

$m-search-result-params-search-type: (
  size: (
    width: auto
  )
);

$m-search-result-deferred-params-search-type: (
  size: (
    width: rem(226)
  )
);

$m-search-result-deferred-four-eight-alert: (
  margin: (null null rem(15) null)
);

$m-search-results-horizontal-label: (
    margin: (null rem(6) null null)
);

$m-search-results-no-padding: (
    padding: 0
);

$m-search-results-content: (
    block: (
        overflow: hidden
    )
);
$m-search-results-content-container: (
    size: 100%
);

$m-search-results-applied-filters-indicator: (
    block: (
        display: inline-block,
    ),
    size: (
        width: rem(19),
    ),
    padding: 0 rem(7) 0 0,
    icon: (
        type: only,
        symbol: filter,
        font_size: 10px,
        width: 12px,
        height: 10px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #DB852E,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #DB852E,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #DB852E,
    )
);
$m-search-results-country-cell: (
  block: (
    display: inline-block,
  ),
);

$m-search-results-country-ofac-icon: (
  block: (
    display: inline-block,
  ),
  custom: (
    top: rem(1.5),
  ),
);

$m-search-results-restricted-cells: (
  block: (
    display: none,
  ),
);

$m-search-results-potential-source-info-ico: (
  block: (
    display: inline-block,
    cursor: pointer,
  ),
  text: (
    line-height: rem(15),
    vertical-align: text-bottom,
  ),
  icon: map-merge($m-search-col-ico, (symbol: info, behavioral: $default_dark_gray_icon, font_size: rem(16))),
);

$m-search-results-current-search-tooltip: (
    size: (
        width: rem(345)
    )
);
