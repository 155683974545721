#toast-container {
    @include properties($c-toast-container);

    &.toast-top-center {
      .toast-lower-position {
        top: rem(155);
      }
    }

    .toast {
        @include properties($c-toast);

        &::before {
            content: '';
            @include properties($c-toast-ico-border);
        }

        &.toast-success {
            @include properties($c-toast-success);

            &::after {
                content: '';
                @include properties($c-toast-success-ico);
                transform: rotate(45deg);
            }
        }
        &.toast-info {
            @include properties($c-toast-info);

            &::after {
                content: '';
                @include properties($c-toast-info-ico);
            }
        }
        &.toast-error {
            @include properties($c-toast-error);

            &::after {
                content: '';
                @include properties($c-toast-error-ico);
            }
        }

        .toast-message {
            @include properties($c-toast-message);
        }

        .toast-close-button {
            @include properties($c-toast-close-button);
        }

        .button-wrapper {
            @include properties($c-toast-message-link);
        }

        .toast-message-body {
            @include properties($c-toast-message-body);
        }

        .truncate-overlflow {
            @include properties($c-toast-truncate-overflow);
        }

        .toast-text {
            @include properties($c-toast-text);
        }
    }
}

:not(.no-enter)#toast-container > div.ng-enter.ng-enter-active,
:not(.no-leave)#toast-container > div.ng-leave {
    opacity: 1;
}

:not(.no-leave)#toast-container > div.ng-leave.ng-leave-active,
:not(.no-enter)#toast-container > div.ng-enter {
    opacity: 0;
}
