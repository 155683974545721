.search-status {
    @include properties($m-search-status);

    .content-area {
        @include properties($m-search-status-content);

        > .container {
            @include properties($m-search-status-content-container);
        }
    }

    .ms-tab-content > .container {
        @include properties($m-search-status-tab-contanier);
    }
}