@keyframes progress-spinner-bounce {
    0%, 100% {
        transform: scale(0.25);
    }
    50% {
        transform: scale(1.5);
    }
}

@keyframes rounded-spinner-bounce {
    0%, 30% {
        width: rem(4);
        height: rem(4);
    }
    15% {
        width: rem(6);
        height: rem(6);
    }
}