/**************************************************************************************************
    ICON DEFINITION
**************************************************************************************************/
$g_icon_properties: (
    position: (relative, null),
    block: (
        box-sizing: border-box,
        cursor: pointer,
        outline: none
    ),
    text: (
        text-decoration: none,
        text-indent: 0,
        vertical-align: inherit
    )
);
$g_icon_before_properties: (
    font: (
        family: $font-icon,
        style: normal
    ),
    text: (
        text-align: center,
        white-space: normal,
        vertical-align: top
    ),
    padding: 0,
    margin: 0
);

/**************************************************************************************************
    ICON TYPE: ONLY
**************************************************************************************************/
$g_icon_only_properties: (
    block: (
        display: inline-block,
        overflow: hidden
    ),
    text: (
        text-align: center
    )
);
$g_icon_only_before_properties: (
    block: (
        display: inline-block
    )
);

/**************************************************************************************************
    ICON TYPE: LEFT
**************************************************************************************************/
$g_icon_left_properties: (
    text: (
        text-align: left
    ),
    size: (
        width: auto
    )
);
$g_icon_left_before_properties: (
    block: (
        display: inline-block
    )
);

/**************************************************************************************************
    ICON TYPE: RIGHT
**************************************************************************************************/
$g_icon_right_properties: (
    text: (
        text-align: right
    ),
    size: (
        width: auto
    )
);
$g_icon_right_before_properties: (
    position: (absolute, 0 0 null null),
    block: (
        display: inline-block
    )
);

/**************************************************************************************************
    ICON TYPE: VERTICAL
**************************************************************************************************/
$g_icon_vertical_properties: (
    block: (
        display: block
    ),
    text: (
        line-height: normal,
        text-align: center
    )
);
$g_icon_vertical_before_properties: (
    block: (
        display: block
    )
);