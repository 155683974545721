//DEFAULT STYLES
.ngdialog {
  box-sizing: border-box;
}

.ngdialog *,
.ngdialog *:before,
.ngdialog *:after {
  box-sizing: inherit;
}

.ngdialog {
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* fix for Scrollbars not clickable on overflow #552 */
  background: rgba(0, 0, 0, 0.4);
  /* end fix for Scrollbars not clickable on overflow #552 */
}


.ngdialog-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  /* fix for Scrollbars not clickable on overflow #552 */
  margin-right: 15px;
  background: transparent;
  /* end fix for Scrollbars not clickable on overflow #552 */
}

.ngdialog-no-overlay {
  pointer-events: none;
}

.ngdialog.ngdialog-closing .ngdialog-overlay {
  -webkit-backface-visibility: hidden;
}

.ngdialog-content {
  background: white;
  pointer-events: all;
}

.ngdialog.ngdialog-closing .ngdialog-content {
  -webkit-backface-visibility: hidden;
}

.ngdialog-close:before {
  font-family: 'Helvetica', Arial, sans-serif;
  content: '\00D7';
  cursor: pointer;
}

html.ngdialog-open,
body.ngdialog-open {
  overflow: hidden;
}


//END DEFAULT STYLES

//THEME STYLES

.ngdialog.ngdialog-theme-default {
  padding-bottom: 160px;
  padding-top: 160px;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  background: #f0f0f0;
  border-radius: 5px;
  color: #444;
  font-family: 'Helvetica',sans-serif;
  font-size: 1.1em;
  line-height: 1.5em;
  margin: 0 auto;
  max-width: 100%;
  padding: 1em;
  position: relative;
  width: 450px;
}

.ngdialog.ngdialog-theme-default .ngdialog-close {
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  display: block;
  padding: 3px;
  background: transparent;
  color: #bbb;
  content: '\00D7';
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-default .ngdialog-close:active:before {
  color: #777;
}

.ngdialog.ngdialog-theme-default .ngdialog-message {
  margin-bottom: .5em;
}

.ngdialog.ngdialog-theme-default .ngdialog-input {
  margin-bottom: 1em;
}

.ngdialog.ngdialog-theme-default .ngdialog-input textarea,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="text"],
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="password"],
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="email"],
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="url"] {
  background: #fff;
  border: 0;
  border-radius: 3px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0 0 .25em;
  min-height: 2.5em;
  padding: .25em .67em;
  width: 100%;
}

.ngdialog.ngdialog-theme-default .ngdialog-input textarea:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="text"]:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="password"]:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="email"]:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="url"]:focus {
  box-shadow: inset 0 0 0 2px #8dbdf1;
  outline: none;
}

.ngdialog.ngdialog-theme-default .ngdialog-buttons:after {
  content: '';
  display: table;
  clear: both;
}

.ngdialog.ngdialog-theme-default .ngdialog-button {
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  float: right;
  font-family: inherit;
  font-size: .8em;
  letter-spacing: .1em;
  line-height: 1em;
  margin: 0 0 0 .5em;
  padding: .75em 2em;
  text-transform: uppercase;
}

.ngdialog.ngdialog-theme-default .ngdialog-button:focus {
  outline: none;
}

.ngdialog.ngdialog-theme-default .ngdialog-button.ngdialog-button-primary {
  background: #3288e6;
  color: #fff;
}

.ngdialog.ngdialog-theme-default .ngdialog-button.ngdialog-button-secondary {
  background: #e0e0e0;
  color: #777;
}
// END DEFAULT THEME


.ngdialog {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
}

.ngdialog-overlay {
    margin: 0;
    background-color: $c-dialog-overlay-color
}

.ngdialog .ngdialog-content {
    margin: 0;
    padding: 0;
    width: $c-dialog-width;
    border-radius: 0;
    border: $c-dialog-border;
    box-shadow: $c-dialog-shadow;
    z-index: 1001;
}

.ms-dialog {
    @include properties($c-dialog);

    .dialog-row {
        @include properties($c-dialog-row);
    }

    .dialog-close {
        @include properties($c-dialog-close-button);
    }

    .dialog-header-area {
        @include properties($c-dialog-header-area);

        & > .container {
            @include properties($c-dialog-header-area-container);
        }
    }

    .dialog-body-area {
        @include properties($c-dialog-body-area);

        & > .container {
            @include properties($c-dialog-body-area-container);
        }
    }

    .dialog-footer-area {
        @include properties($c-dialog-footer-area);

        & > .container {
            @include properties($c-dialog-footer-area-container);
        }
    }
}

.flexible-dialog {
    .ngdialog-content {
        @include properties($c-dialog-flexible-content);
    }

    .ms-dialog {
        @include properties($c-dialog-flexible-dialog);

        >.container {
            @include properties($c-dialog-flexible-container);
        }
    }
}

.scrollable-content-dialog.ngdialog {
    @include properties($c-scrollable-content-dialog);

    .ngdialog-content {
        @include properties($c-scrollable-content-dialog-content);
    }

    .ms-dialog {
        @include properties($c-scrollable-content-dialog-layout);

        & > .container {
            @include properties($c-scrollable-content-dialog-layout-container);
        }

        .ms-dialog-body {
            @include properties($c-scrollable-content-dialog-layout-body);
        }
    }
}
