/**************************************************************************************************
 FIELD ELEMENT TYPE = DROP-DOWN DEFINITIONS
**************************************************************************************************/
.field-wrapper {
    &.active {
        .multiselect_dropdown_placeholder{
            @include properties($c_ddl_choice_active);
        }
    }
    &.invalid {
        .multiselect_dropdown_wrapper {
            .multiselect_dropdown {
                &.open{
                    .multiselect_dropdown_placeholder,
                    .multiselect_dropdown_content,
                    .multiselect_dropdown_search {
                        @include properties($c_ddl_choice_invalid);
                    }
                }

                .multiselect_dropdown_placeholder {
                    @include properties($c_ddl_choice_invalid);
                }
            }
        }
    }
    &.disabled {
        @include disabled;
    }
}