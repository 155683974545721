@import 'fn/index';

.tippy-box[data-theme~='default-tooltip'] {
  background-color: $color-text-primary;
  border-radius: rem(3);
  font-weight: normal;

  > .tippy-arrow::before {
    color: $color-text-primary;
  }
}

.tippy-box[data-theme~='default-tooltip'][data-placement^=top] > .tippy-arrow::before {
  bottom: rem(-4);
}

.tippy-box[data-theme~='default-tooltip'][data-placement^=bottom] > .tippy-arrow::before {
  top: rem(-4);
}

.tippy-box[data-theme~='default-tooltip'][data-placement^=left] > .tippy-arrow::before {
  right: rem(-4);
}

.tippy-box[data-theme~='default-tooltip'][data-placement^=right] > .tippy-arrow::before {
  left: rem(-4);
}
