@import 'fn/pix_to_rem';
@import 'icons';

$font-icon-name: 'matchsource-icons';
$ico-default: empty;
$ico-map: $ms-icons;
$transparent: rgba(255, 255, 255, 0);
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$g-field-input-hovered-border-color: #5b8197;
$color-brand-main-1: #0079c3;
$color-brand-main-2: #53a0d5;
$color-brand-main-3: #f6f9fc;
$color-brand-main-3-semi: rgba(246, 249, 252, 0.9);
$item-section-border-color: #d2d9df;

$color-brand-navy: #00355e;
$color-brand-alt-1: #bdcc42;
$color-brand-alt-2: #d2df58;
$color-brand-alt-3: #fafdde;
$color-brand-alt-4: #bdcc2a;
$color-user-menu-hover: #d0db69;
$color-user-menu-active: #a9b726;
$m-top-header-link-color: #2a8dcc;
$m-top-header-link-hovered-color: #4398d2;
$color-text-primary: #1d3649;
$color-text-secondary: #d3700f;
$color-text-main-2: #91acbc;
$color-text-main-3: #e9f1f7;
$color-text-light-1: #586c78;
$color-link: #0c87ff;
$color-background-active: #dbedff;
$color-header: #586c78;
$border-light-blue: #e9ecef;
$color-link-hover: #0a79e5;
$m-top-header-link-hovered-color: #4398d2;
$m-top-header-link-selected-color: #004875;
$m-top-header-search-link-color: #586c78;
$m-top-header-search-type-choice-opened-background-color: #dde4ea;
$active-alt-4: #096ccc;
$hover-alt-4: #0a79e5;
$white: #fff;

$color-alt-1: #e9e9e9;
$color-alt-2: #edf0f2;
$color-alt-3: #f6f6f6;
$disabled-color-alt-2: #d9e1e6;
$disabled-color-alt-1: #dee6eb;

$c-options-toggle-color-hover: #0a79e5;
$color-action-1: #009747;
$color-action-3: #f89600;
$color-action-4: #fee9c5;
$color-highlight: #d64848;
$color-highlight-hover: #cb2c2c;
$color-highlight-active: #b21c1c;

$light-blue-border-color: #b6dbff;
$dropdown-menu-item-background-color-hover: #dbedff;

$color-border: #586c78;
$color-border-2: #dbdbdb;
$color-border-3: #e9bfbf;
$color-disabled: #bcc3c9;
$color-note: $color-border;
$g-field-disabled-label-color: #d9e1e6;
$ico-blue-color: #096ccc;
$button-secondary-border-color: #e9f5ff;
$color-important-border: #d3700f;
$color-important: #fee9c5;
$c-tabs-tab-link-active-border-color: #bdcd42;

$border-color: #e8f1f7;
$border-color-alt-2: #d2d9df;

$ico-default-size: rem(24);
$ico-default-font-size: rem(20);
$checkbox-default-size: 18px;
$checkbox-clip-rect-size: 0.0625rem;
$checkbox-height: 34px;
$checkbox-width: 32px;
$check-box-list-line-height: 18px;

$g-field-radio-disabled-label: (
  symbol: radioOff,
  type: left,
  font_size: rem(18px),
  width: rem(32px),
  height: rem(30px),
  behavioral: custom,
  initial_bg_color: $transparent,
  initial_color: $g-field-disabled-label-color,
  initial_ico_bg_color: $transparent,
  initial_ico_color: #dee6eb
);

$g-field-radio-selected-disabled-label: (
  symbol: radioOn,
  type: left,
  font_size: rem(18px),
  width: rem(32px),
  height: rem(30px),
  behavioral: custom,
  initial_bg_color: $transparent,
  initial_color: $g-field-disabled-label-color,
  initial_ico_bg_color: $transparent,
  initial_ico_color: #dee6eb
);

$radio-font-size: 18px;
$radio-width: 32px;
$radio-height: 30px;
$radio-label-font-size: 13px;
$radio-label-padding: rem(2) rem(4) rem(2) 0;
$radio-label-line-height: 32px;

$text-input-height: rem(36px);
$text-input-width: rem(1px);
$text-input-radius: rem(2px);
$text-input-padding: 0 rem(8px);
$text-input-font-size: rem(13px);

$ms-button-navigation-height: rem(32px);
$ms-button-font-size: rem(13px);
$ms-button-height: rem(28px);
$ms-button-height-inline: rem(13);
$ms-button-border-width: rem(2px);
$ms-button-border-radius: rem(2px);
$ms-button-padding: 0 (16px);

$ms-field-control-short-height: rem(28);
$ms-field-control-regular-height: rem(32);

$ms-modal-with-search-criteria-width: rem(565);

$ico-size-correlation: 1.17;
$default-required-mark-symbol: '*';
$select-order-dialog-width: rem(571);

