$ms-icons: (
  calendar: '\f101',
  cart: '\f102',
  cartOff: '\f103',
  checkOff: '\f104',
  checkOn: '\f105',
  columns: '\f106',
  cross: '\f107',
  ctrl: '\f108',
  download: '\f109',
  dragndrop: '\f10a',
  enter: '\f10b',
  exit: '\f10c',
  externalLink: '\f10d',
  eye: '\f10e',
  eyeCrossed: '\f10f',
  filter: '\f110',
  helixIcon: '\f111',
  important: '\f112',
  info: '\f113',
  leftArrow: '\f114',
  leftBackArrow: '\f115',
  list: '\f116',
  lock: '\f117',
  loup: '\f118',
  low: '\f119',
  minus: '\f11a',
  paper-plane: '\f11b',
  pencil: '\f11c',
  pin: '\f11d',
  plus: '\f11e',
  radioOff: '\f11f',
  radioOn: '\f120',
  rightArrow: '\f121',
  rightForwardArrow: '\f122',
  tick: '\f123',
  tickUnderline: '\f124',
  trash: '\f125',
);
