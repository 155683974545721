.run-preliminary-search{
    .field-wrapper{
        display: flex;
        flex-direction: row;
        padding: 20px 0 25px 0;
    }

    .searches-list-wrapper{
        font-size: 0.75rem;
        color: $color-border;

        span{
            display: block;
            margin: 0 0 rem(8) 0;
        }

        .searches-list-textarea{
            padding: 8px;
            width: 100%;
            height: rem(125);
            resize: none;

        }
    }
}