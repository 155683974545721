.multiselect_dropdown_wrapper {
    outline: 0;

    @include properties($c-multiselect-dropdown-wrapper);

    .multiselect_dropdown {
        @include properties($c-multiselect-dropdown-container);

        &.hide_controls {
            .multiselect_dropdown_view_targeted, .multiselect_dropdown_search {
                @include properties($c-multiselect-dropdown-hide_controls);
            }
        }

        &.multiselect_disabled {
            @include properties($c-multiselect-dropdown-disabled);
            opacity: 0.3;

            .multiselect_dropdown_content {
                @include properties($c-multiselect-dropdown-hide_controls);

                .multiselect_dropdown_ctrls {
                    @include properties($c-multiselect-dropdown-hide_controls);
                }
            }
        }

        &.open {
            @include properties($c-multiselect-dropdown-open);

            &.hide_controls.open:not(.top) {
                .multiselect_dropdown_placeholder {
                    @include properties($c-multiselect-open-placeholder);
                }
            }

            .multiselect_dropdown_content {
                @include properties($c-multiselect-dropdown-content);
            }

            .multiselect_dropdown_placeholder {
                @include properties($c-multiselect-open-placeholder-border);

                &:after {
                    @include properties($c_multiselect_arrow-ico-open);
                }
            }

            &.top {
                @include properties($c-multiselect-dropdown-open-top-direction);
            }
        }

        * {
            @include properties($c-multiselect-dropdown-default-boxes-property);
        }

        .multiselect_dropdown_empty {
            @include properties($c-multiselect-dropdown-empty);
        }

        .multiselect_dropdown_search {
            @include properties($c-multiselect-search-icon);

            .multiselect_dropdown_search_input[type=text] {
                @include properties($c-multiselect-search-input-field)
            }
        }

        .multiselect_dropdown_placeholder {
            @include properties($c-multiselect-placeholder);

            &:after {
                content: '';
                @include properties($c_multiselect_arrow-ico);
            }

            &:hover {
                @include properties($c-multiselect-placeholder-hover);
            }
        }

        .multiselect_dropdown_content {
            @include properties($c-multiselect-dropdown-container-dropdown-content);
        }
        .multiselect_dropdown_list {
            @include properties($c-multiselect-dropdown-list);

            .list_item_selected {
                @include properties($c-multiselect-dropdown-list-item-selected);
            }

            .multiselect_dropdown_list_item {
                @include properties($c-multiselect-dropdown-list-item);

                &[disabled] {
                    @include properties($c-multiselect-dropdown-list-item-disabled);

                    &:hover {
                        @include properties($c-multiselect-dropdown-list-item-disabled-selected);
                    }
                    label {
                        @include properties($c-multiselect-dropdown-list-item-hidden-label)
                    }
                }
                &[hidden] {
                    @include properties($c-multiselect-hidden-block);
                }
                label {
                    @include properties($c-multiselect-dropdown-list-item-label);
                }

                input[type=radio] {
                    @include properties($c-multiselect-hidden-block);

                    & + label {
                        &:before,
                        &:after {
                            @include properties($c-multiselect-hidden-block);
                        }
                    }
                }
            }

            &.multi_choice_enabled {
                .multiselect_dropdown_list_item {
                    &.list_item_selected {
                    }
                }
            }

            &:not(.multi_choice_enabled) {
                .multiselect_dropdown_list_item {
                    &.selected {
                        font-color: black;
                        font-weight: 700;
                    }
                }
            }
        }

        .multiselect_dropdown_action {
            @include properties($c-multiselect_dropdown_action);

            input[type='button'] {
                width: auto;
                margin: 0 auto;
            }
        }
    }

    .multiselect_dropdown.top.open:not(.single) {
        .multiselect_dropdown_ctrls:not(.empty_list) {
            z-index: 3;
        }
    }

    .multiselect_dropdown_ctrls {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: white;

        &.empty_list {
            .multiselect_dropdown_select_all,
            .multiselect_dropdown_view_targeted {
                @include properties($c-multiselect-hidden-block);
            }
        }

        .multiselect_dropdown_select_all,
        .multiselect_dropdown_view_targeted {
            a {
                &.multiselect_dropdown_view_all_link {
                    @include properties($c-multiselect-hidden-block);
                }
            }

            .multiselect_dropdown_unselect_all_link {
                @include properties($c-multiselect-hidden-block);
            }
            .multiselect_dropdown_select_all_link {
                @include properties($c-multiselect-hidden-block);
            }

            &.selected,
            &.targeted_only {
                .multiselect_dropdown_select_all_link,
                .multiselect_dropdown_view_targeted_link {
                    @include properties($c-multiselect-hidden-block);
                }

                .multiselect_dropdown_unselect_all_link,
                .multiselect_dropdown_view_all_link {
                    display: inline;
                }
            }
        }
    }

    &.ms-narrow-select {
        @include properties($c-multiselect-dropdown-wrapper-narrow);

        .multiselect_dropdown {
            @include properties($c-multiselect-dropdown-container-narrow);

            .multiselect_dropdown_placeholder {
                @include properties($c-multiselect-placeholder-narrow);

                &:after {
                    content: '';
                    @include properties($c_multiselect_arrow-ico-narrow);
                }
            }

            .multiselect_dropdown_search {
                @include properties($c-multiselect-search-icon);
            }

            &.open {
                .multiselect_dropdown_placeholder {
                    &:after {
                        @include properties($c_multiselect_arrow-ico-open-narrow);
                    }
                }
            }
        }
    }
}
