/**************************************************************************************************
 VARIABLES
**************************************************************************************************/
$g-field-label-color: $color-border;
$g-field-active-label-color: #0C87FF;
$g-field-disabled-label-color: #D9E1E6;
$g-field-invalid-label-color: $color-highlight;

$g-field-input-border-color: #91ACBC;
$g-field-input-active-border-color: #0C87FF;
$g-field-input-disabled-border-color: #DEE6EB;
$g-field-input-invalid-border-color: $color-highlight;
$g-field-input-hovered-border-color: #5B8197;

$g-field-input-text-color: #1D3649;
$g-field-input-active-text-color: #1D3649;
$g-field-input-disabled-text-color: #BCC3C9;
$g-field-input-invalid-text-color: #1D3649;

$g-field-note-color: $color-border;
$g-field-active-note-color: $color-border;
$g-field-disabled-note-color: #D9E1E6;
$g-field-invalid-note-color: $color-highlight;

$g-field-height: rem(36);
$g-field-button-radius: rem(3);
$g-field-icon-height: rem(34);
$g-field-icon-width: rem(36);

$g-field-grouped-item-item-space: rem(8);

/**************************************************************************************************
 FIELD WRAPPER
**************************************************************************************************/
$g-field-wrapper: (
    position: relative,
    flexbox: (column)
);
$g-field-wrapper-vertical: $g-field-wrapper;
$g-field-wrapper-horizontal: (
    //flexbox: (row, flex-start)
);

/**************************************************************************************************
 FIELD LABEL
**************************************************************************************************/
$g-field-label: (
    text: (
        color: $g-field-label-color,
        line-height: normal
    ),
    font: (
        size: rem(12)
    ),
    padding: 0 0 rem(4),
    block: (
        box-sizing: border-box
    ),
    ellipsis: ()
);
$g-field-active-label: (
    text: (
        color: $g-field-active-label-color
    )
);
$g-field-disabled-label: (
    text: (
        color: $g-field-disabled-label-color
    )
);
$g-field-invalid-label: (
    text: (
        color: $g-field-invalid-label-color
    )
);

/**************************************************************************************************
 FIELD ELEMENT WRAPPER
**************************************************************************************************/
$g-field-element: (
    text: (
        font-size: 0
    )
);
$g-field-element-disabled: (
    block: (
        cursor: not-allowed
    )
);
$g-field-component-wrapper: (
    position: relative,
    block: (
        display: block,
        box-sizing: border-box
    ),
    size: 100%
);

$g-field-component-grouped: (
    position: relative,
    size: 100%
);
$g-field-component-grouped-horizontal: (
    flexbox: (row, flex-start, flex-start)
);
$g-field-component-grouped-vertical: (
    flexbox: (column, flex-start, flex-start)
);
$g-field-component-grouped-item: (
    font: (
        size: 0
    )
);
$g-field-component-grouped-item-item: (
    padding: 0 0 0 $g-field-grouped-item-item-space
);
$g-field-component-grouped-vertical-item-item: (
    padding: rem(4) 0 0 0
);

$g-field-element-error: (
    position: absolute,
    padding: 0 rem(2) 0 rem(17),
    background: (
        color: #fff
    ),
    block: (
        box-sizing: border-box,
        index: 1,
    ),
    size: (
        top: 100%,
        width: 100%,
    ),
    text: (
        color: #D64848,
        line-height: rem(34),
    )
);

$g-field-element-error-item: (
    font: (
        size: rem(12),
    )
);

$g-field-element-error-active: (
    border: (
        width: rem(1),
        style: solid,
        color: #D64848,
    )
);

/**************************************************************************************************
 FIELD TEXT
**************************************************************************************************/
$g-field-input: (
    size: (
        width: 100%,
        height: $g-field-height
    ),
    block: (
        box-sizing: border-box
    ),
    border: (
        width: rem(1),
        style: solid,
        color: $g-field-input-border-color,
        radius: rem(2)
    ),
    text: (
        font-size: 1.3,
        color: $g-field-input-text-color,
        text-indent: 0
    ),
    padding: 0 rem(8),
    margin: 0,
    background: (
        color: $white
    )
);
$g-field-active-input: (
    border: (
        color: $g-field-input-active-border-color
    )
);
$g-field-disabled-input: (
    border: (
        color: $g-field-input-disabled-border-color
    ),
    text: (
        color: $g-field-input-disabled-text-color
    ),
    block: (
        cursor: not-allowed
    )
);
$g-field-invalid-input: (
    border: (
        color: $g-field-input-invalid-border-color
    )
);
$g-field-hovered-input: (
    border: (
        color: $g-field-input-hovered-border-color
    )
);

$g-field-input-calendar: (
    position: (absolute, 0 0 null null),
    block: (
        display: block
    ),
    margin: 0,
    icon: (
        type: only,
        symbol: calendar,
        font_size: $ico-default-font-size,
        width: $g-field-icon-width,
        height: $g-field-icon-height,
        behavioral: $default_icon_dark_blue_behavioral
    )
);
$g-field-active-input-calendar: (
    position: (absolute, 0 0 null null),
    icon: (
        type: only,
        symbol: calendar,
        font_size: $ico-default-font-size,
        width: $g-field-icon-width,
        height: $g-field-icon-height,
        behavioral: $default_icon_active_behavioral
    )
);
$g-field-invalid-input-calendar: (
    position: (absolute, 0 0 null null),
    icon: (
        type: only,
        symbol: calendar,
        font_size: $ico-default-font-size,
        width: $g-field-icon-width,
        height: $g-field-icon-height,
        behavioral: $default_icon_invalid_behavioral
    )
);
$g-field-disabled-input-calendar: (
    position: (absolute, 0 0 null null),
    icon: (
        type: only,
        symbol: calendar,
        font_size: $ico-default-font-size,
        width: $g-field-icon-width,
        height: $g-field-icon-height,
        behavioral: $default_icon_disabled_behavioral
    )
);

/**************************************************************************************************
 FIELD DROP-DOWN LIST
**************************************************************************************************/
$g-field-select: (
    size: (
        width: 100%,
        height: $g-field-height
    ),
    block: (
        box-sizing: border-box
    ),
    border: (
        width: rem(1),
        style: solid,
        color: $g-field-input-border-color,
        radius: rem(2)
    ),
    text: (
        font-size: 1.3,
        color: $g-field-input-text-color,
        text-indent: rem(8)
    )
);

/**************************************************************************************************
 FIELD NOTE | MESSAGE
**************************************************************************************************/
$g-field-note: (
    text: (
        color: $g-field-note-color,
        font-size: 1.1,
        line-height: normal
    ),
    padding: 0,
    size: (
        min-height: rem(24)
    ),
    block: (
        box-sizing: border-box,
        overflow: hidden
    )
);
$g-field-active-note: ();
$g-field-disabled-note: (
    text: (
        color: $g-field-disabled-note-color
    )
);
$g-field-invalid-note: (
    text: (
        color: $g-field-invalid-note-color
    )
);

/**************************************************************************************************
 FIELD TYPE | TEXT
**************************************************************************************************/
$g-field-text: (
    text: (
        line-height: rem(36),
        color: #1D3649
    ),
    font: (
        size: rem(13),
        weight: normal
    ),
    size: (
        height: $g-field-height,
        max-width: 100%
    ),
    ellipsis: (),
);

$g-field-text-wrap: (
    text: (
        word-break: break-word,
        white-space: normal,
        line-height: normal,
    ),
    size: (
        height: auto,
    ),
    padding: rem(10) null null null,
);

/**************************************************************************************************
 FIELD TYPE | CHECKBOX
**************************************************************************************************/
$g-field-checkbox: (
    position: (absolute, 0 null null 0),
    size: rem(1),
    margin: 0,
    padding: 0,
    block: (
        overflow: hidden,
        clip: rect(rem(1), rem(1), rem(1), rem(1))
    )
);
$g-field-checkbox-label: (
    block: (
        box-sizing: border-box
    ),
    padding: 0 rem(4) 0 0,
    text: (
        line-height: rem(36)
    ),
    font: (
        size: rem(13)
    ),
    size: (
        width: 100%
    ),
    ellipsis: (),
    icon: (
        symbol: checkOff,
        type: left,
        font_size: 1.8,
        width: rem(32),
        height: 34px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #1D3649,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #91ACBC,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #0C87FF
    )
);
$g-field-active-checkbox-radio-color: (
    text: (
        color: #0C87FF
    )
);
$g-field-checkbox-invalid-label: (
    icon: (
        symbol: checkOff,
        type: left,
        font_size: 1.8,
        width: rem(32),
        height: 34px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #1D3649,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #D64848,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #D64848
    )
);
$g-field-checkbox-invalid-label-checked: (
    icon: (
        symbol: checkOn,
        type: left,
        font_size: 1.8,
        width: rem(32),
        height: 34px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #1D3649,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #D64848,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #D64848
    )
);
$g-field-checkbox-checked-label: (
    icon: (
        symbol: checkOn,
        type: left,
        font_size: 1.8,
        width: rem(32),
        height: 34px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: inherit,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #0C87FF,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #0C87FF
    )
);
$g-field-checkbox-disabled-label: (
    icon: (
        symbol: checkOff,
        type: left,
        font_size: 1.8,
        width: rem(32),
        height: 34px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #D9E1E6,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #DEE6EB
    )
);

$g-field-checkbox-disabled-actual-state-label: (
  block: (
    box-sizing: border-box,
  ),
  text:(
    text-decoration: none,
    text-indent: 0,
    color: #d9e1e6,
    text-align: left,
  ),
  size:(
    width: auto,
  ),
  outline: none,
  background-color: rgba(255, 255, 255, 0),
);

$g-field-checkbox-label-span: (
    text: (
        color: #D64848,
        font_size: 1.3
    ),
    padding: 0 rem(2) 0 0
);
$g-field-checkbox-label-span-vertical: (
    block: (
        display: none
    )
);
$g-field-checkbox-label-span-horizontal: (
    block: (
        display: inline
    )
);


$g-simple-field-checkbox-label: (
    block: (
        box-sizing: border-box
    ),
    padding: 0 rem(4) 0 0,
    text: (
        line-height: rem(24)
    ),
    font: (
        size: rem(13)
    ),
    size: (
        width: 100%
    ),
    ellipsis: (),
    icon: (
        symbol: checkOff,
        type: left,
        font_size: 1.8,
        width: rem(24),
        height: 24px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #1D3649,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #91ACBC,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #0C87FF
    )
);
$g-simple-field-checkbox-invalid-label: (
    icon: (
        symbol: checkOff,
        type: left,
        font_size: 1.8,
        width: rem(24),
        height: 24px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #1D3649,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #D64848,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #D64848
    )
);
$g-simple-field-checkbox-invalid-label-checked: (
    icon: (
        symbol: checkOn,
        type: left,
        font_size: 1.8,
        width: rem(24),
        height: 24px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #1D3649,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #D64848,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #D64848
    )
);
$g-simple-field-checkbox-checked-label: (
    icon: (
        symbol: checkOn,
        type: left,
        font_size: 1.8,
        width: rem(24),
        height: 24px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: inherit,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #0C87FF,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #0C87FF
    ),
    size: (
        width: 100%,
    ),
);
$g-simple-field-checkbox-disabled-label: (
    icon: (
        symbol: checkOff,
        type: left,
        font_size: 1.8,
        width: rem(24),
        height: 24px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #D9E1E6,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #DEE6EB
    )
);

/**************************************************************************************************
 FIELD TYPE | RADIO
**************************************************************************************************/
$g-field-radio: (
    position: (absolute, 0 null null 0),
    size: rem(1),
    margin: 0,
    padding: 0,
    block: (
        overflow: hidden,
        clip: rect(rem(1), rem(1), rem(1), rem(1))
    )
);
$g-field-radio-label: (
    block: (
        box-sizing: border-box
    ),
    padding: rem(2) rem(4) rem(2) 0,
    text: (
        line-height: rem(32)
    ),
    font: (
        size: rem(13)
    ),
    size: (
        width: 100%
    ),
    ellipsis: (),
    icon: (
        symbol: radioOff,
        type: left,
        font_size: 1.8,
        width: rem(32),
        height: 30px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #1D3649,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #91ACBC,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #0C87FF
    )
);
$g-field-option-radio-label: (
    block: (
        display: block,
    ),
    padding: rem(1) rem(10) rem(1) rem(4),
);
$g-field-option-radio-checked-label: (
    font: $font-bold-properties,
);
$g-field-radio-checked-label: (
    icon: (
        symbol: radioOn,
        type: left,
        font_size: 1.8,
        width: rem(32),
        height: 30px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: inherit,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #0C87FF,
        hover_bg_color: $transparent,
        hover_color: inherit,
        hover_ico_bg_color: inherit,
        hover_ico_color: #0C87FF
    )
);
$g-field-radio-disabled-label: (
    icon: (
        symbol: radioOff,
        type: left,
        font_size: 1.8,
        width: rem(32),
        height: 30px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #D9E1E6,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #DEE6EB
    )
);

$g-field-radio-selected-disabled-label: (
    icon: (
        symbol: radioOn,
        type: left,
        font_size: 1.8,
        width: rem(32),
        height: 30px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #D9E1E6,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #DEE6EB
    )
);
$g-field-radio-vertical-label: (
    padding: 0
);

/**************************************************************************************************
 FIELD TEXT
**************************************************************************************************/
$g-field-view: (
    position: relative,
    flexbox: (column, flex-start),
    size: (
        width: 100%
    )
);
$g-field-view-label: (
    padding: rem(4) 0,
    text: (
        line-height: normal,
        white-space: nowrap,
        color: $color-border,
        font-size: rem(12)
    )
);
$g-field-view-value: (
    font: $font-regular-properties,
    size: (
        min-height: rem(15)
    ),
    text: (
        line-height: rem(15),
        white-space: pre,
        color: $color-text-primary,
        font-size: rem(13)
    )
);
$g-non-flexible-field-view-value: (
    text: (
        white-space: pre-wrap,
    )
);


/**************************************************************************************************
 FIELD GROUP
**************************************************************************************************/
$g-field-group: (
    position: relative,
    size: 100%,
    flexbox: (row),
    block: (
        box-sizing: border-box,
        overflow: hidden
    ),
);
$g-field-group-overflow: (
    block: (
        overflow: visible,
    )
);
$g-field-group-field-wrapper: (
    size: 100%
);
$g-field-group-field-input: (
    border: (
        width: 0,
        radius: 0
    )
);
$g-field-group-button: ();
$g-field-group-button-left: (
    border: (
        radius: 0 $g-field-button-radius $g-field-button-radius 0
    )
);
$g-field-group-button-right: (
    border: (
        radius: $g-field-button-radius 0 0 $g-field-button-radius
    )
);
$g-field-button-button: (
    size: 100%,
    block: (
        box-sizing: border-box
    ),
    padding: 0 rem(5),
    border: (
        width: 0
    ),
    text: (
        text-align: left
    )
);
$g-field-checkbox-no-margin: (
    size: (
        width: rem(28),
    ),
    text: (
        text-align: left,
    )
);
