.alert-info-area {
    @include properties($c-alert-general);
    @include properties($c-alert-info);
}

.alert-success-area {
    @include properties($c-alert-general);
    @include properties($c-alert-success);
}

.alert-error-area {
    @include properties($c-alert-general);
    @include properties($c-alert-error);
}