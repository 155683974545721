.ms-tabs {
    @include properties($g-tabs);

    .ms-tab {
        @include properties($g-tabs-tab);
    }

    .tab-link {
        @include properties($g-tabs-tab-link);

        &:not(.disabled):hover {
            @include properties($g-tabs-tab-link-hover);
        }

        &.active {
            @include properties($g-tabs-tab-link-active);
        }

        &.disabled {
            @include properties($g-tabs-tab-link-disabled);
        }
    }

    .tab-label {
        @include properties($g-tabs-tab-label);
    }

    .tabs-counter {
        @include properties($g-tabs-tab-counter);

        &--warn {
          @include properties($g-tabs-tab-counter);

          color: #db852e;
          background-color: #fee9c5;
        }
    }
}

.ms-tab-content {
    @include properties($g-tabs-tab-content);

    &.ms-tab-content--scroll-y {
        @include properties($g-tabs-tab-content-scroll-y);

        > .container {
            @include properties($g-tabs-tab-container-scroll-y);
        }
    }

    > .container {
        @include properties($g-tabs-tab-contanier);
    }
}
