.search-filters {
    @include properties($m-search-filter);

    > .container {
        @include properties($m-search-filter-container);
    }
    .search-filters-toolbar {
        @include properties($m-search-filter-toolbar);

        > .container {
            @include properties($m-search-filter-toolbar-container);
        }
    }
    .search-filters-content {
        @include properties($m-search-filter-content);

        > .container {
            @include properties($m-search-filter-content-container);
        }
    }
    .search-filters-ctrls {
        @include properties($m-search-filter-ctrls);

        > .container {
            @include properties($m-search-filter-ctrls-container);
        }
    }
}