@import 'fn/index';
@import 'variables';

textarea[msTextarea] {
  box-sizing: border-box;
  font-size: $text-input-font-size;
  height: rem(151);
  resize: none;
  width: 100%;

  &:hover {
    border-color: $g-field-input-hovered-border-color;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: $white;
    border-color: $color-text-main-2;
  }
}
