/**********************************************************************************************
	BLOCK DIMENSION
 **********************************************************************************************/
@mixin dimensions($properties) {
    // set width
    @if map-has-key($properties, width) {
        width: map-get($properties, width);
    }
    // set min-width
    @if map-has-key($properties, min-width) {
        min-width: map-get($properties, min-width);
    }
    // set height
    @if map-has-key($properties, height) {
        height: map-get($properties, height);
    }
    // set min-height
    @if map-has-key($properties, min-height) {
        min-height: map-get($properties, min-height);
    }
    // set opacity
    @if map-has-key($properties, opacity) {
        opacity: map-get($properties, opacity);
    }
    // set z-index
    @if map-has-key($properties, index) {
        z-index: map-get($properties, index);
    }
    // set overflow
    @if map-has-key($properties, overflow) {
        overflow: map-get($properties, overflow);
    }
}