/**************************************************************************************************
 SEARCH MODULE
**************************************************************************************************/
.search {
    .ms-module-area {
        @include properties($m-search-module-area);
    }

    .ms-module-header-area {
        @include properties($m-search-module-header);
    }
    .search-areas-divider {
        @include properties($m-search-areas-divider);
    }
    .search-panel-content {
        @include properties($m-search-panel-content);
    }
}

.us-based-donors-dialog .ngdialog-content {
  @include properties($m-us-based-donors-dialog);
}

.icon-request-wmda-donor {
  display: block;
  width: rem(20);
  height: rem(20);
  @include properties($m-request-wmda-donors-icon);

  &.disabled {
    opacity: 0.3;
  }
}
