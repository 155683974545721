.ms-races {
    @include properties($c-races);

    ul li {
        @include properties($c-races-list-items);
    }

    * {
        @include properties($c-races-descendants);
    }

    .races-header {
        @include properties($c-races-header);

        >* {
            @include properties($c-races-header-descendants);
        }

        .races-header-info {
            @include properties($c-races-header-info);
        }

        .races-total-count {
            @include properties($c-races-total-count);
        }

        .races-toolbar {
            @include properties($c-races-toolbar);

            a {
              @include properties($c-races-toolbar-link);
            }
        }
    }

    &.ms-races--empty {
        .races-header {
            .races-toolbar {
                a {
                    @include properties($c-races-toolbar-link-disabled);
                }
            }
        }
    }

    .races-tags {
        @include properties($c-races-tags);

        &:hover {
            @include properties($c-races-tags-hover);
        }

        &:focus {
            @include properties($c-races-tags-focus);
        }

        .races-tag {
            &.races-tag-deprecated {
                @include properties($c-races-tag-deprecated);

                .races-tag-close {
                    @include properties($c-races-tag-deprecated-close);

                    &:hover {
                        @include properties($c-races-tag-deprecated-close-active);
                    }
                }
            }

            @include properties($c-races-tag);

            .races-tag-close {
                @include properties($c-races-tag-close);

                &:hover {
                    @include properties($c-races-tag-close-active);
                }

                &:before {
                    @include properties($c-races-tag-close-icon);
                }
            }
        }
    }

    .races-list {
        @include properties($c-races-list);

        ul {
            @include properties($c-races-list-sub);
        }

        .races-list-group {
            @include properties($c-races-list-group);

            &.races-list-group--collapsible {
                .races-group-label {
                    @include properties($c-races-list-group-label-collapsible);

                    &::before {
                        content: '';
                        @include properties($c-races-group-icon);
                    }

                    &:hover {
                        &::before {
                            @include properties($c-races-group-icon-active);
                        }
                    }
                }

                &.races-list-group--expanded {
                    .races-group-label::before {
                        @include properties($c-races-group-icon-expanded);
                    }

                    .races-group-label:hover {
                        &::before {
                            @include properties($c-races-group-icon-active-expanded);
                        }
                    }
                }
            }

            &.races-list-group--disabled:not(.races-list-group--collapsible) {
                .races-group-label {
                    @include properties($c-races-group-label-disabled);
                }
            }

            &:not(.races-list-group--disabled):not(.races-list-group--collapsible),
            &.races-list-group--collapsible {
                .races-group-label:hover {
                    @include properties($c-races-group-label-active);
                }
            }

            &:not(.races-list-group--collapsible) {
                .races-group-label {
                    @include properties($c-races-group-label-no-children);
                }
            }

            .races-group-label {
                @include properties($c-races-group-label);
            }
        }

        .races-group-items {
            @include properties($c-races-group-items);
        }

        .races-list-group.races-list-group--expanded {
            .races-group-items {
                @include properties($c-races-group-items-expanded);
            }

            .races-group-label {
                @include properties($c-races-group-label-expanded);
            }
        }

        .races-list-group--selected {
            @include properties($c-races-group-selected);
        }

        .races-list-group--collapsible:not(.races-list-group--empty):not(.races-list-group--expanded) {
              .races-group-label {
                  @include properties($c-races-group-label-selected);
              }
        }

        .races-list-group--disabled {
            &:not(.races-list-group--collapsible) {
                .races-group-label {
                    @include properties($c-races-group-collapsible-disabled);
                }
            }

            &.races-list-group--collapsible {
                .races-group-item label {
                    @include properties($g-field-checkbox-disabled-label);
                    @include properties($c-races-item-disabled);
                }
            }
        }

        .races-list-group--empty {
            .races-group-count {
                @include properties($c-races-group-count-empty);
            }
        }

        .races-list-group--collapsible.races-list-group--expanded:not(.races-list-group--empty) {
            .races-group-count {
                @include properties($c-races-group-count-collapsed);
            }
        }

        .races-list-group--expanded {
            .races-group-count {
                @include properties($c-races-group-count-expanded);
            }
        }

        .races-group-item {
            @include properties($c-races-group-item);
        }
    }
}

.field-wrapper.required .ms-races {
    .races-header-label {
        @include required;
    }
}

.field-wrapper.active .ms-races {
    .races-header-label {
        @include properties($c-races-active-label);
    }

    .races-list, .races-tags{
        @include properties($c-races-active-border)
    }
}

.field-wrapper.invalid {
    .ms-races {
        .races-header-label {
            @include properties($c-races-label-error);
        }

        .races-list,
        .races-tags {
            @include properties($c-races-error);
        }
    }

    .component-wrapper .ms-races .races-group-item:not(.races-group-item-deprecated) input[type=checkbox]:checked + label:before {
        @include properties($c-races-not-deprecated-checkbox);
    }
}