@import 'variables';
@import 'fn/index';

$c-tabs-border-color: #e8f1f7;
$c-tabs-tab-link-active-border-color: #bdcd42;

[msTab] {
  box-sizing: border-box;
  color: $color-border;
  cursor: pointer;
  display: block;
  font-family: Arial;
  font-size: rem(13);
  font-weight: bold;
  line-height: normal;
  min-height: rem(43);
  padding: rem(12) rem(20) rem(12) rem(20);
  text-decoration: none;

  &:not(.disabled):hover {
    color: $color-text-primary;
  }

  &.active {
    box-shadow: (inset 0 rem(-1) 0 $c-tabs-tab-link-active-border-color, 0 rem(1) 0 $c-tabs-tab-link-active-border-color);
    color: $color-text-primary;
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .ms-count-mark {
    margin: 0 0 0 rem(8);
  }
}
