.ms-collapse-link {
    .collapse-ico {
        @include properties($c-collapse-link-ico);
        transform: rotate(90deg);
    }

    .collapse-ico.collapsed {
        transform: rotate(-90deg);
    }
}
