ms-options-ng,
.ms-options {
    @include properties($c_options);

    .options-toggle {
        @include properties($c_options_toggle);

        &:hover {
            @include properties($c_options_toggle_hover);

            &::after {
                @include properties($c_options_toggle_hover_arrow_ico);
            }
        }

        &::after {
            content: '';
            @include properties($c_options_toggle_arrow-ico);
        }

        &::before {
            content: '';
            @include properties($c_options_toggle_arrow_ico_back);
        }

        &.open::after {
            transform: rotate(180deg);
        }
    }

    .options-menu {
        @include properties($c_options_menu);

        &-right {
            @include properties($c_options_menu-right);
        }

        & > li {
            @include properties($c_options_menu_item);
        }

        & > li.options-menu-item:not(.disabled):hover {
            @include properties($c_options_menu_item_hover);
        }

        & > li.options-menu-item {
            & > a {
                @include properties($c_options_menu_item_link);
            }

            & > button {
                @include properties($c_options_menu_item_button);
            }
        }

        & > li.options-menu-item.disabled {
            @include properties($c_options_menu_item_disabled);
        }

        & > li.options-menu-item.disabled {
            & > a {
                @include properties($c_options_menu_item_disabled_link);
            }
        }

        & > li.options-menu-item.selected {
            & > a,
            & > button {
                @include properties($c_options_menu_item_selected_link);
            }
        }

        &--no-padding {
            > li.options-menu-item {
                @include properties($c_options_menu_item_no_padding);

                > button {
                    @include properties($c_options_menu_item_button_with_padding);
                }
            }
        }
    }

    .options-menu.open {
        @include properties($c_options_menu_open);
    }
}
