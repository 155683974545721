.menu-options {
    @include properties($c-menu-options-link);

    &:after {
        content: '';
        @include properties($c-menu-options-arrow-off);
    }

    &:focus {
        &:after {
            content: '';
            @include properties($c-menu-options-arrow-on);
        }
    }
}
.menu-options-container {
    @include properties($c-menu-options-container);

    .menu-item {
        @include properties($c-menu-options-item);

        &:hover {
            @include properties($c-menu-options-item-hover);
        }
    }
}
.tippy-tooltip.menu-theme {
    @include properties($c-menu-options-tippy);
}