@import 'fn/index';
@import 'mixins/icon';

@mixin checkbox-checked {
  @include set-ico(
    (
      symbol: checkOn,
      behavioral: custom,
      initial_bg_color: $transparent,
      initial_color: inherit,
      initial_ico_bg_color: $transparent,
      initial_ico_color: $color-link,
      hover_bg_color: $transparent,
      hover_color: inherit,
      hover_ico_bg_color: inherit,
      hover_ico_color: $color-link,
    )
  );
}

@mixin checkbox-unchecked {
  @include set-ico(
    (
      symbol: checkOff,
      type: left,
      font_size: rem($checkbox-default-size),
      width: rem($checkbox-width),
      height: rem($checkbox-height),
      behavioral: custom,
      initial_bg_color: $transparent,
      initial_color: $color-text-primary,
      initial_ico_bg_color: $transparent,
      initial_ico_color: $color-text-main-2,
      hover_bg_color: $transparent,
      hover_color: inherit,
      hover_ico_bg_color: inherit,
      hover_ico_color: $color-link,
    )
  );
}

@mixin set-checkbox-color($checkbox_color) {
  &:hover::before,
  &::before {
    color: $checkbox_color;
  }
}
