.workup-container{
    @include properties($m-workup-container);

    .ms-module-header-area{
        @include properties($m-workup-header);

        .header-buttons-list {
            @include properties($m-workup-header-buttons);
        }
    }

    .content-area{
        @include properties($m-workup-content);

        .workup-questions-list{
            @include properties($m-workup-module-questions-list);

            li{
                @include properties($m-workup-module-questions-list-item);
            }
        }

        .workup-donor-content, .workup-cord-content {
          @include properties($m-workup-module-questions-list);
        }

        & > .container{
            @include properties($m-workup-module-container);
        }
    }

    .ms-module-content-container{
        @include properties($m-workup-module-content);
    }

    .workup-fixed-buttons{
        @include properties($m-workup-fixed-buttons);
    }
}
