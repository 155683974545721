.coop-preliminary-grid{
     tbody{
        @include properties($c-coop-grid-table-row);

        &:nth-child(even){
            @include properties($c-coop-grid-table-row-odd);
        }
    }

    .expand{
        @include properties($c-coop-preliminary-grid-expand-cell);
    }

    .rerun-cell{
        @include properties($c-coop-preliminary-grid-cart-cell);
    }

    .date-cell{
       @include properties($c-coop-preliminary-grid-date-cell);
    }

    .rerun-checkbox{
        @include properties($c-coop-preliminary-grid-rerun-cell);
    }

    .method-cell{
        @include properties($c-coop-preliminary-grid-method-cell);
    }
}