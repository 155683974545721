/**************************************************************************************************
 VARIABLES
**************************************************************************************************/
$c-ddl-font-size: 1.3;
$c-ddl-bg-color: #FFF;
$c-ddl-text-color: #1D3649;
$c-ddl-field-height: $g-field-height;

$c-ddl-border-width: rem(1);
$c-ddl-border-radius: rem(2);

$c-ddl-border-color: #91ACBC;
$c-ddl-hovered-border-color: #5B8197;
$c-ddl-active-border-color: #0C87FF;
$c-ddl-invalid-border-color: #D64848;
$c-ddl-disabled-border-color: #DEE6EB;

/**************************************************************************************************
 SELECT CONTAINER
**************************************************************************************************/
$c_ddl_choice_active: (
    border: (
        color: $c-ddl-active-border-color,
        radius: $c-ddl-border-radius $c-ddl-border-radius 0 0,
        width: $c-ddl-border-width,
        style: solid
    )
);
$c_ddl_choice_invalid: (
    border: (
        color: $c-ddl-invalid-border-color
    )
);