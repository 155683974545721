@use 'sass:math';
/**************************************************************************************************
    MIXINS FOR FONTS DECLARATION
**************************************************************************************************/
@function get-font-family($font_family_name, $font_family_list: $basic-fonts-list){
    @return append($font_family_name, $font_family_list, comma);
}
@mixin set-font-family($font_family) {
    font-family: $font_family;
}

@mixin set-font($properties) {
    @include property-map('font', $properties);
}

@mixin font-definition(
    $font_family_name: 'matchsource-icons',
    $font_family_url: '',
    $font_family_key: icons,
    $font_family_weight: normal,
    $font_family_style: normal
){
    @font-face {
        font-display: block;
        font-family: $font_family_name;
        font-weight: $font_family_weight;
        font-style: $font_family_style;
        //src: url('#{$path_fonts}/matchsource.eot');
        src: /*url('#{$path_fonts}/matchsource.eot?tc9lta') format('embedded-opentype'),*/
            url('#{$path_fonts}/matchsource-icons.woff?su82c6') format('woff'),
            url('#{$path_fonts}/matchsource-icons.ttf?su82c6') format('truetype'),
            url('#{$path_fonts}/matchsource-icons.svg#matchsource?su82c6') format('svg');

        -webkit-font-smoothing: antialiased;
        -webkit-text-stroke-width: 0.2px;
        -moz-osx-font-smoothing: grayscale;
    }

    @if  $font_family_key == icons {
        $font-icon: get-font-family($font_family_name) !global;
    }
}
@mixin fonts-definition(
    $fonts_list: $fonts-list-default
){
    @each $font_family, $font_family_types_list, $font_key, $font_weight, $font_style in $fonts_list {
        @include font-definition(
            #{$font_family},
            $font_family_types_list,
            $font_key,
            $font_weight,
            $font_style
        );
    }
}

@function sqrt($r) {
    $x0: 1;
    $x1: $x0;

    @for $i from 1 through 10 {
        $x1: $x0 - math.div($x0 * $x0 - abs($r), 2 * $x0);
        $x0: $x1;
    }

    @return $x1;
}
