@use 'sass:map';
@import 'variables';
@import 'fn/ico';

// stylelint-disable scss/no-global-function-names
// print font-icon char
@mixin set-ico-char {
  &::before {
    @content;
  }
}

@mixin set-ico-symbol(
  $ico_name: $ico-default
) {
  &::before {
    content: get-ico-symbol($ico_name);
  }
}

@mixin set-ico-base() {
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  position: relative;
  text-decoration: none;
  text-indent: 0;
  vertical-align: inherit;

  &::before {
    content: '';
    font-family: $font-icon-name;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smooth: antialiased;/* stylelint-disable-line order/properties-alphabetical-order */
    font-style: normal;
    margin: 0;
    padding: 0;
    speak: none;
    text-align: center;
    vertical-align: top;
    white-space: normal;
  }

  &[disabled],
  &[disabled]::before {
    cursor: default;
    pointer-events: none;
  }
}

@mixin set-ico-color( $ico-color-map ) {
  $behavioral: map.get($ico-color-map, behavioral);
  $color-schema: $behavioral;

  @if $behavioral == custom {
    $color-schema: $ico-color-map;
  }

  $color: map.get($color-schema, initial_color);
  $bg-color: map.get($color-schema, initial_bg_color);

  $ico-color: map.get($color-schema, initial_ico_color);
  $ico-bg-color: map.get($color-schema, initial_ico_bg_color);

  $color-hover: map.get($color-schema, hover_color);
  $bg-color-hover: map.get($color-schema, hover_bg_color);

  $ico-color-hover: map.get($color-schema, hover_ico_color);
  $ico-bg-color-hover: map.get($color-schema, hover_ico_bg_color);

  $color-active: map.get($color-schema, active_color);
  $bg-color-active: map.get($color-schema, active_bg_color);

  $ico-color-active: map.get($color-schema, active_ico_color);
  $ico-bg-color-active: map.get($color-schema, active_ico_bg_color);

  @if type-of($color) == color {
    color: $color;

    &[disabled] {
      color: $color;
    }
  }
  @if type-of($bg-color) == color {
    background-color: $bg-color;

    &[disabled] {
      background-color: $bg-color;
    }
  }
  @if type-of($ico-color) == color {
    &::before {
      color: $ico-color;
    }
  }
  @if type-of($ico-bg-color) == color {
    &::before {
      background-color: $ico-bg-color;
    }
  }
  @if type-of($color-hover) == color {
    &:hover {
      color: $color-hover;
    }
  }
  @if type-of($bg-color-hover) == color {
    &:hover {
      background-color: $bg-color-hover;
    }
  }
  @if type-of($ico-color-hover) == color {
    &:hover {
      &::before {
        color: $ico-color-hover;
      }
    }
  }
  @if type-of($ico-bg-color-hover) == color {
    &:hover {
      &::before {
        background-color: $ico-bg-color-hover;
      }
    }
  }
  @if type-of($color-active) == color {
    &:active {
      color: $color-active;
    }
  }
  @if type-of($bg-color-active) == color {
    &:active {
      background-color: $bg-color-active;
    }
  }
  @if type-of($ico-color-active) == color {
    &:active {
      &::before {
        color: $ico-color-active;
      }
    }
  }
  @if type-of($ico-bg-color-active) == color {
    &:active {
      &::before {
        background-color: $ico-bg-color-active;
      }
    }
  }
}


@mixin get-ico-type-properties(
  $type,
  $size: $ico-default-size,
  $width: $ico-default-size,
  $height: $ico-default-size,
  $before: false
) {
  @if $size != null {
    $size: $size * $ico-size-correlation;
  }

  @if $type == only {
    @if $before {
      display: inline-block;
      font-size: $size;
      width: $width;
    }
    @else {
      font-size: $size;
      height: $height;
      line-height: $height;
      width: $width;
    }
  }
  @else if $type == left {
    @if $before {
      font-size: $size;
      line-height: $height;
      width: $width;
    }
    @else {
      text-align: left;
      width: auto;
    }
  }
  @else if $type == right {
    @if $before {
      display: inline-block;
      font-size: $size;
      height: $height;
      line-height: $height;
      position: absolute;
      right: 0;
      top: 0;
      width: $width;
    }
    @else {
      padding: 0 $width 0 0;
      text-align: right;
      width: auto;
    }
  }
  @else if $type == vertical {
    @if $before {
      display: block;
      font-size: $size;
      line-height: $height;
    }
    @else {
      display: block;
      line-height: normal;
      text-align: center;
    }
  }
}
@mixin set-ico-type($ico_map) {
  $type: map.get($ico_map, type);
  $size: map.get($ico_map, font_size);
  $width: map.get($ico_map, width);
  $height: map.get($ico_map, height);

  @include get-ico-type-properties($type, $size, $width, $height);

  &::before {
    @include get-ico-type-properties($type, $size, $width, $height, true);
  }
}


@mixin set-ico(
  $ico_properties_map
) {
  $symbol: map.get($ico_properties_map, symbol);

  @include set-ico-base();
  @include set-ico-type($ico_properties_map);
  @include set-ico-color($ico_properties_map);
  @include set-ico-symbol($symbol);
}
