ms-lookup,
.lookup-results-placeholder,
.lookup-results-placeholder > results {
    @include properties($lookup-container);
}

.lookup-container .ms-module-content-area,
.lookup-container {
    @include properties($lookup-container);
}

.lookup-results-placeholder {
    .ms-status-ico {
        .status-code {
            border-radius: 0;
            height: auto;
            padding: 0;
        }
    }
}
