/**************************************************************************************************
 FIELD ELEMENT TYPE = INPUT DEFINITIONS
**************************************************************************************************/
.field-wrapper {
    @include properties($g-field-wrapper);

    /**************************************************************************************************
     FIELD WIDTH
    **************************************************************************************************/
    .ms-smallest {
        width: rem(40);
    }

    .ms-tiny {
        width: rem(68);
    }

    .ms-smaller {
        width: rem(96);
    }

    .ms-small {
        width: rem(112);
    }

    .ms-normal {
        width: rem(140);
    }

    .ms-medium {
      width: rem(160);
    }

    .ms-medium-2 {
      width: rem(180);
    }

    .ms-big {
        width: rem(260);
    }

    .ms-big-2 {
        width: rem(280);
    }

    .ms-biggest {
        width: rem(308);
    }

    .ms-wide {
        width: rem(456)
    }

    .ms-wider {
        width: rem(468);
    }

    .ms-xwide {
        width: rem(480);
    }

    .ms-pre-massive {
        width: rem(565);
    }

    .ms-massive {
        width: rem(620);
    }

    .ms-large {
        width: rem(640);
    }

    .ms-large-2 {
        width: rem(670);
    }

    .ms-large-3 {
        width: rem(800);
    }

    .ms-full-width {
        width: 100%;
    }

    .ms-average {
      width: rem(320);
    }

  .ms-average-2 {
    width: rem(375);
  }

    &.show-empty-label .field-label:empty {
        display: block;
        height: rem(18);
    }

    /**************************************************************************************************
     VERTICAL LAYOUT
    **************************************************************************************************/
    &.vertical {
        @include properties($g-field-wrapper-vertical);

        label {
            .required {
                @include properties($g-field-checkbox-label-span-vertical);
            }
        }
    }

    /**************************************************************************************************
     HORIZONTAL LAYOUT
    **************************************************************************************************/
    &.horizontal {
        @include properties($g-field-wrapper-horizontal);

        label {
            .required {
                @include properties($g-field-checkbox-label-span-horizontal);
            }
        }
    }

    /**************************************************************************************************
     FIELD ACTIVE STATE
    **************************************************************************************************/
    &.active {
        .field-label {
            @include properties($g-field-active-label);
        }

        .field-note {
            @include properties($g-field-active-note);
        }

        .component-wrapper {
            input[type=text], input[type=text]:hover {
                @include properties($g-field-active-input);

                + .calendar {
                    @include properties($g-field-active-input-calendar);
                }
            }

          textarea, textarea:hover {
            @include properties($g-field-active-input);
          }
        }
    }

    /**************************************************************************************************
     FIELD INVALID STATE
    **************************************************************************************************/
    &.invalid {
        .field-label {
            @include properties($g-field-invalid-label);
        }

        .field-note {
            @include properties($g-field-invalid-note);
            overflow: visible;
        }

        .component-wrapper {
            input[type=text], input[type=text]:hover {
                @include properties($g-field-invalid-input);

                + .calendar {
                    @include properties($g-field-invalid-input-calendar);
                }
            }

            input[type=checkbox] {
                &:checked {
                    + label {
                        @include properties($g-field-checkbox-invalid-label-checked);
                    }
                }

                + label {
                    @include properties($g-field-checkbox-invalid-label);
                }
            }

          textarea, textarea:hover {
            @include properties($g-field-invalid-input);
          }
        }

      &.required {
        .ms-dropdown-custom {
          .ng-select-container {
            @include properties($g-field-invalid-input);
          }
        }
      }
    }

    /**************************************************************************************************
     FIELD DISABLED STATE
    **************************************************************************************************/
    &.disabled {
        .field-label {
            @include properties($g-field-disabled-label);
        }

        .field-note {
            @include properties($g-field-disabled-note);
        }

        .field-element {
            @include properties($g-field-element-disabled);
        }

        .component-wrapper {
            input[type=text] {
                @include disabled;
                @include properties($g-field-disabled-input);

                + .calendar {
                    @include properties($g-field-disabled-input-calendar);
                }
            }
        }

        .mask-input{
            @include properties($g-field-disabled-label);

            &::placeholder {
                @include properties($g-field-disabled-label);
            }
        }

        .picker_icon { /* stylelint-disable-line selector-class-pattern */
            opacity: 0.3;
        }
    }

    /**************************************************************************************************
     FIELD DEFAULT STATE
    **************************************************************************************************/
    &.required {
        .field-label {
            @include required;
        }
    }

    /**************************************************************************************************
     FIELD LABEL DEFINITION
    **************************************************************************************************/
    .field-label {
        @include properties($g-field-label);
    }

    /**************************************************************************************************
     FIELD ELEMENT AREA DEFINITION
    **************************************************************************************************/
    .field-element {
        @include properties($g-field-element);

        .field-element-error {
          @include properties($g-field-element-error);

          &.ng-active {
            @include properties($g-field-element-error-active);
          }

          >* {
            @include properties($g-field-element-error-item);
          }
        }
    }

    /**************************************************************************************************
     FIELD NOTATION DEFINITION
    **************************************************************************************************/
    .field-note {
        @include properties($g-field-note);


      &.fixed-note {
        position: absolute;
        top: 100%;
        width: rem(200);
      }
    }

    /**************************************************************************************************
     FIELD TYPE SPECIFIC DEFINITIONS
    **************************************************************************************************/
    .component-wrapper {
        @include properties($g-field-component-wrapper);

        .group {
            @include properties($g-field-component-grouped);

            &.horizontal {
                @include properties($g-field-component-grouped-horizontal);

                .group-item {
                    @include properties($g-field-component-grouped-item);

                    + .group-item {
                        @include properties($g-field-component-grouped-item-item);
                    }
                }
            }

            &.vertical {
                @include properties($g-field-component-grouped-vertical);

                .group-item + .group-item {
                    @include properties($g-field-component-grouped-vertical-item-item);
                }
            }
        }
    }

    /**************************************************************************************************
     FIELD TYPE = INPUT
    **************************************************************************************************/
    .component-wrapper {
        input[type=text] {
            @include properties($g-field-input);

            &:hover {
                @include properties($g-field-hovered-input);
            }

            + .calendar {
                @include properties($g-field-input-calendar);
            }
        }
    }

    select {
        @include properties($g-field-select);
    }

    /**************************************************************************************************
     FIELD TYPE = LABEL
    **************************************************************************************************/
    .ms-field-text {
        @include properties($g-field-text);
    }

    &.wrap-text .ms-field-text {
        @include properties($g-field-text-wrap);
    }

    /**************************************************************************************************
     FIELD TYPE = CHECKBOX
    **************************************************************************************************/
    .component-wrapper {
        input[type=checkbox] {
            @include properties($g-field-checkbox);

            &:checked {
                + label {
                    @include properties($g-field-checkbox-checked-label);
                }
            }

            &:focus {
                + label {
                    &::before {
                        @include properties($g-field-active-checkbox-radio-color);
                    }
                }
            }

            &:disabled {
                + label {
                    @include properties($g-field-checkbox-disabled-actual-state-label);
                    @include disabled;

                  &::before {
                    background-color: rgba(255, 255, 255, 0);
                    color: #dee6eb;
                  }
                }
            }

            + label {
                @include properties($g-field-checkbox-label);

                .required {
                    @include properties($g-field-checkbox-label-span);
                }
            }
        }
    }

    &.simple-field {
        /**************************************************************************************************
         FIELD TYPE = CHECKBOX
        **************************************************************************************************/
        .component-wrapper {
            input[type=checkbox] {
                &:checked {
                    + label {
                        @include properties($g-simple-field-checkbox-checked-label);
                    }
                }

                &:disabled {
                    + label {
                        @include properties($g-simple-field-checkbox-disabled-label);
                        @include disabled;
                    }
                }

                + label {
                    @include properties($g-simple-field-checkbox-label);

                    .required {
                        @include properties($g-field-checkbox-label-span);
                    }
                }
            }
        }
    }

    /**************************************************************************************************
     FIELD TYPE = RADIO
    **************************************************************************************************/
    .component-wrapper {
        ms-radio {
            input[type=radio] {
                @include properties($g-field-radio);
    
                &:checked {
                    + label {
                        @include properties($g-field-radio-checked-label);
    
                        &.option-label {
                            @include properties($g-field-option-radio-checked-label);
                        }
                    }
    
                    &:disabled {
                        + label {
                            @include properties($g-field-radio-selected-disabled-label);
                            @include disabled;
                        }
                    }
                }
    
                &:focus {
                    + label {
                        &::before {
                            @include properties($g-field-active-checkbox-radio-color);
                        }
                    }
                }
    
                &:disabled {
                    + label {
                        @include properties($g-field-radio-disabled-label);
                        @include disabled;
                    }
                }
    
                + label {
                    @include properties($g-field-radio-label);
    
                    &.option-label {
                        @include properties($g-field-option-radio-label);
                    }
                }
            }
        }

        .vertical input[type=radio] + label {
            @include properties($g-field-radio-vertical-label);
        }
    }
}

/**************************************************************************************************
 FIELD TYPE = TEXT
**************************************************************************************************/
.ms-field-view {
    @include properties($g-field-view);

    .field-label {
        @include properties($g-field-view-label);
    }

    .field-value {
        @include properties($g-field-view-value);
    }

    .field-value-wrapper {
      align-items: center;
      display: flex;

      .blocked-icon {
        margin-right: 6px;
      }

      .field-value + .blocked-icon {
        margin-left: 6px;
        margin-right: 0;
      }

      .field-value.wrap-text {
        white-space: normal;
        word-break: break-word;
      }
    }
}

.non-flexible .ms-field-view .field-value {
    @include properties($g-non-flexible-field-view-value);
}

.wrapped-field-view {
    .ms-field-view .field-label,
    .ms-field-view .field-value {
        white-space: normal;
        word-break: break-word;
    }
}


/**************************************************************************************************
 FIELD GROUP
**************************************************************************************************/
.field-group,
.field-group--overflow {
    @include properties($g-field-group);
    @include properties($g-field-group-overflow);

    .field-group-button {
        @include properties($g-field-group-button);

        button {
            @include properties($g-field-button-button);
        }
    }

    .field-wrapper {
        @include properties($g-field-group-field-wrapper);

        input[type=text] {
            @include properties($g-field-group-field-input);
        }
    }

    .field-group-button:first-child {
        button {
            @include properties($g-field-group-button-right);
        }
    }

    .field-group-button:last-child {
        button {
            @include properties($g-field-group-button-left);
        }
    }
}

/**************************************************************************************************
 ELEMENTS
**************************************************************************************************/
.ms-checkbox--no-margin-wrapper.field-wrapper .component-wrapper input[type=checkbox] + label::before {
    @include properties($g-field-checkbox-no-margin);
}
