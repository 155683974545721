.module-page-panel {
    @include properties($m-module-page-panel);

    & > .container {
        @include properties($m-module-page-panel-container);
    }

    .module-page-panel-header {
        @include properties($m-module-page-panel-header);

        & > .container {
            @include properties($m-module-page-panel-header-container);
        }

        .title-container {
            @include properties($m-module-page-panel-header-title-container);
        }

        .title {
            @include properties($m-module-page-panel-header-title);
        }
    }

    .module-page-panel-body {
        @include properties($m-module-page-panel-body);

        & > .container {
            @include properties($m-module-page-panel-body-container);
        }

    }

    .module-page-panel-item {
        @include properties($m-module-page-panel-item);

        & > .container {
            @include properties($m-module-page-panel-item-container);
        }

        &:hover {
            @include properties($m-module-page-panel-item-active);
        }

        &.selected {
            @include properties($m-module-page-panel-item-selected);
        }

        .row {
            @include properties($m-module-page-panel-item-row);

            + .row {
                @include properties($m-module-page-panel-item-row-n-row);
            }

            .item-title {
                @include properties($m-module-page-panel-item-title);
            }

            .item-status {
                @include properties($m-module-page-panel-item-status);
            }
        }
    }

    .module-page-panel-no-items {
        @include properties($m-module-page-panel-no-items);
    }

    .module-page-panel-footer {
        @include properties($m-module-page-panel-footer);
    }
}
