@import '../../../../src/app/styles/icons';

/**************************************************************************************************
    FONTS
**************************************************************************************************/
@include font-definition();

/**************************************************************************************************
    GLOBAL
**************************************************************************************************/
$em-base: 16px;
$grid-base: 8; //px
$max-viewport-width: 1280; //px

/**************************************************************************************************
    ICONS MAP & PROPERTIES
**************************************************************************************************/
// default icon
$ico_default: empty;
// default list of font icons
$ico_map: $ms-icons;
// default icon size
$ico-default-size: rem(24);
$ico-default-font-size: rem(20);

/**************************************************************************************************
    COLOR SCHEMA
**************************************************************************************************/
$transparent: rgba( 255, 255, 255, 0 );

$white: rgba( 255, 255, 255, 1.0 );
$black: rgba( 0, 0, 0, 1.0 );

$color-brand-main-1: #0079C3;
$color-brand-main-2: #53A0D5;
$color-brand-main-3: #F6F9FC;
$color-brand-main-3-semi: rgba(246,249,252,.9);

$color-brand-alt-1: #BDCC42;
$color-brand-alt-2: #D2DF58;
$color-brand-alt-3: #FAFDDE;
$color-brand-alt-4: #BDCC2A;

$color-text-primary: #1D3649;
$color-text-main-2: #91ACBC;
$color-text-main-3: #E9F1F7;

$color-alt-1: #E9E9E9;
$color-alt-2: #EDF0F2;
$color-alt-3: #F6F6F6;

$color-action-1: #009747;
$color-highlight: #D64848;
$color-highlight_hover: #CB2C2C;
$color-highlight_active: #B21C1C;
$color-action-3: #F89600;
$color-action-4: #fee9c5;
$color-link: #0C87FF;
$color-link_hover: #0A79E5;

$color-border: #586C78; //#7E9AAA; //MS-12853

$color-disabled: #BCC3C9;
$color-note: $color-border;

/**************************************************************************************************
    DEFAULT ICON COLOR SCHEMAS
**************************************************************************************************/
$default_icon_white:(
    initial_bg_color: $transparent,
    initial_color: $white,
    hover_bg_color: $transparent,
    hover_color: $white
);
$default_icon_gray_blue_behavioral:(
    initial_bg_color: $transparent,
    initial_color: $color-border,
    hover_bg_color: $transparent,
    hover_color: $color-link_hover
);
$default_icon_white_blue_behavioral:(
    initial_bg_color: $transparent,
    initial_color: $white,
    hover_bg_color: $transparent,
    hover_color: $color-brand-main-1
);
$default_icon_black_blue_behavioral:(
    initial_bg_color: $transparent,
    initial_color: $black,
    hover_bg_color: $transparent,
    hover_color: $color-brand-main-1
);
$default_icon_dark_blue_behavioral:(
    initial_bg_color: $transparent,
    initial_color: $color-border,
    hover_bg_color: $transparent,
    hover_color: $color-link
);
$default_icon_blue_behavioral:(
    initial_bg_color: $transparent,
    initial_color: $color-link,
    hover_bg_color: $transparent,
    hover_color: $color-link
);
$default_icon_disabled_behavioral:(
    initial_bg_color: $transparent,
    initial_color: #BCC3C9,
    hover_bg_color: $transparent,
    hover_color: inherit
);
$default_icon_active_behavioral:(
    initial_bg_color: $transparent,
    initial_color: $color-link,
    hover_bg_color: $transparent,
    hover_color: inherit
);
$default_icon_invalid_behavioral:(
    initial_bg_color: $transparent,
    initial_color: $color-highlight,
    hover_bg_color: $transparent,
    hover_color: inherit
);
$default_icon_warning_behavioral:(
    initial_bg_color: $transparent,
    initial_color: #E9BFBF,
    hover_bg_color: $transparent,
    hover_color: $color-highlight
);
$default_icon_dark_red_behavioral:(
    initial_bg_color: $transparent,
    initial_color: $color-border,
    hover_bg_color: $transparent,
    hover_color: $color-highlight_hover,
    active_bg_color: $transparent,
    active_color: $color-highlight_active
);
$default_icon_warning:(
    initial_bg_color: $transparent,
    initial_color: $color-highlight,
    hover_bg_color: $transparent,
    hover_color: $color-highlight
);
$default_notification_icon:(
    initial_bg_color: $transparent,
    initial_color: $color-text-main-2,
    hover_bg_color: $transparent,
    hover_color: $color-text-main-2
);
$default_icon_grey_blue_behavioral:(
    initial_bg_color: $transparent,
    initial_color: $color-text-main-2,
    hover_bg_color: $transparent,
    hover_color: $color-link
);
$default_dark_gray_icon:(
  initial_bg_color: $transparent,
  initial_color: $color-border,
  hover_bg_color: $transparent,
  hover_color: $color-border,
);


/**************************************************************************************************
    DEFAULT REQUIRED MARK
**************************************************************************************************/
$default-required-mark-symbol: '*';
$default-required-mark: (
    text: (
        color: #d44848
    ),
    padding: 0 rem(4) 0 0,
    font: (
        size: rem(12)
    )
);
