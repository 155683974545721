@use 'sass:math';
/**************************************************************************************************
    FONT
**************************************************************************************************/
@mixin font-size($sizeValue: 1.6) {
    $newVal: math.div($sizeValue, 1.6);
    font-size: ($sizeValue * 10) + px;
    font-size: $newVal + rem;
}
/**************************************************************************************************
    DISABLE STATES
**************************************************************************************************/
@mixin disabled {
    cursor: default;
    pointer-events: none;
}
/**************************************************************************************************
    DISABLED BUTTONS
**************************************************************************************************/
@mixin disabled-element {
    @include disabled();
    opacity: .3;
}
/**************************************************************************************************
    PREFIXER
**************************************************************************************************/
@mixin property-map(
    $prefix,
    $map
) {
    $type: type-of($map);

    @if $type == "map" {
        @each $key, $value in $map {
            #{$prefix}-#{$key}: $value
        }
    }
    @else {
        #{$prefix}: $map
    }
}
/**************************************************************************************************
    REQUIRED MARK
**************************************************************************************************/
@mixin required {
    &:before {
        content: $default-required-mark-symbol;
        @include properties($default-required-mark);
    }
}

@mixin custom-properties($properties) {
    @each $key, $value in $properties {
        #{$key}: #{$value};
    }
}
