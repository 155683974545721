/************************************************
    DONOR: COMMON
 ************************************************/
$m-donor-module-area: (
    padding: 0
);
$m-donor-module-header: (
    padding: rem(8) rem(20)
);

/************************************************
    DONOR: DETAILS
 ************************************************/
$m-donor-details: (
    size: 100%,
    flexbox: (column),
    block: (
        box-sizing: border-box,
        overflow: hidden
    ),
    padding: 0
);
$m-donor-details-section: (
    border: (
        color: #E8F1F7,
        style: solid,
        width: 0  0 rem(1) 0
    )
);
$m-donor-details-content: (
    size: 100%,
    block: (
        overflow: hidden
    )
);
$m-donor-details-content-container: (
    size: 100%,
    block: (
        overflow: hidden
    ),
    flexbox: (column)
);
$m-donor-details-tab-container: (
    padding: rem(8) rem(20)
);


/************************************************
    DONOR: DEMOGRAPHICS
 ************************************************/
$m-donor-demographics: (
    size: (
        height: 100%
    ),
    block: (
        box-sizing: border-box
    ),
    padding: rem(8) rem(20)
);

$m-donor-demographics-section: ();
$m-donor-demographics-section-n-section: (
    margin: rem(16) 0 0 0
);

$m-cord-demographics-view-section: (
    block: (
        box-sizing: border-box
    ),
    border: (
        style: solid,
        width: 0 0 rem(1),
        color: $m-patient-view-section-border-color
    ),
    padding: rem(8) 0 0 0
);
$m-cord-demographics-view-last-section: (
    border: (
        width: rem(0)
    )
);

$m-donor-demographics-section-row: (
    flexbox: (row, flex-start, flex-start),
    size: (
        width: 100%
    ),
    padding: 0
);
$m-donor-demographics-section-row-row: ();

$m-donor-demographics-section-column: (
    padding: 0 0 rem(12) 0,
    size: (
        min-width: rem(97)
    )
);
$m-donor-demographics-section-column-n-column: (
    margin: 0 0 0 rem(28)
);
$m-donor-demographics-general-info-column: (
    size: (
        min-width: auto
    )
);
$m-donor-demographics-race-details-column: (
    size: (
        max-width: rem(776)
    )
);
$m-donor-demographics-race-details-column-field-value: (
    text:(
        white-space: normal
    )
);
$m-cord-demographics-field-value: (
    padding: 0 8px 0 0
);
$m-cord-demographics-section-column-n-column: (
    margin: 0
);
$m-cord-demographics-section-content: (
    margin: 0 0 12px 0
);
$m-cord-demographics-info-box: (
    margin: 10px null 20px null,
    padding: 13px 16px,
    text: (
        color: $color-text-primary,
        font-size:13px
    ),
    background: #E9F1F7,
    border: (
        radius: 2px
    )
);

/************************************************
    DONOR: IDM
 ************************************************/
$m-donor-test-results: (
);

$m-donor-test-results-container: (
    padding: rem(8) rem(20)
);

$m-donor-test-results-form: (
    size: (
        height: 100%
    )
);

$m-donor-test-results-form-section-header: (
    padding: null null 0 null,
    margin: 0
);

$m-donor-test-results-form-question: (
    text: (
        color: $color-text-primary,
        font-size: rem(13),
        line-height: normal
    ),
    margin: rem(12) 0
);

$m-donor-test-results-form-question-label: (
    block: (
        display: inline-block
    ),
    size: (
        width: rem(288)
    ),
    text: (
        vertical-align: top,
        line-height: 1.4
    ),
    padding: 0 rem(16) 0 0,
    margin: 0 rem(16) 0 0
);

$m-donor-test-results-form-question-value: (
    block: (
        display: inline-block
    ),
    text: (
        vertical-align: top,
        line-height: 1.4
    )
);

$m-donor-test-results-form-grid-row: (
    flexbox: (row, stretch, space-between),
    flex: (
        wrap: wrap
    )
);

$m-donor-test-results-form-grid-row-hover: (
    block: (
        box-shadow: none
    )
);

$m-donor-test-results-form-grid-header-column: (
    size: (
        height: rem(16),
        min-height: rem(16)
    ),
    padding: rem(4) rem(12)
);

$m-donor-test-results-form-grid-column: (
    block: (
        overflow: hidden
    ),
    margin: 0,
    padding: 0 rem(12)
);

$m-donor-test-results-form-grid-column-1: (
    size: (
        width: rem(304)
    )
);

$m-donor-test-results-form-grid-column-2: (
    size: (
        min-width: rem(250)
    )
);

$m-donor-test-results-form-grid-column-3: (
    size: (
        width: rem(112)
    ),
    padding: 0 0 0 rem(12)
);

$m-donor-test-results-form-grid-value-question-nested: (
    block: (
        display: block
    )
);
