@import 'variables';

.ms-remaining-characters-wrapper {
  color: $color-text-light-1;
  display: block;
  font-size: 0.75rem;
}

.ms-remaining-characters-counter {
  font-weight: bold;
}
