.ms-tooltip {
    @include properties($m-shared-tootip);

    .section-area {
        @include properties($m-shared-tootip-section);

        + .section-area {
            @include properties($m-shared-tootip-section-n-section);
        }

        &:last-child .field-value {
            @include properties($m-shared-tootip-section-last-field-value);
        }
    }

    .section-title {
        @include properties($m-shared-tootip-section-title);
    }

    .section-row {
        @include properties($m-shared-tootip-section-row);

        + .section-row {
            @include properties($m-shared-tootip-section-row-row);
        }
    }

    .section-column {
        @include properties($m-shared-tootip-section-column);

        + .section-column {
            @include properties($m-shared-tootip-section-column-n-column);
        }
    }

    .field-value {
        @include properties($m-shared-tootip-field-value);
    }

    .section-column:not(:first-child) {
        @include properties($m-shared-tootip-second-column);

        .field-value {
            @include properties($m-shared-tootip-second-column-field-value);
        }
    }
}