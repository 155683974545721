/**************************************************************************************************
    GLOBAL DEFINITIONS
**************************************************************************************************/
$default_overlay_coordinates: 0 0 0 0;

$l_main_properties: (
    font: $font-regular-properties,
    block: (
        overflow: auto
    )
);

$l_main_scroll_container_properties: (
    position: (relative, null null null null),
    size: (
        width: 100%,
        height: 100vh
    ),
    block: (
        overflow: hidden
    )
);

$l_main_transclude_properties: (
    size: (
        height: 100%
    )
);

/**************************************************************************************************
    WINDOW OVERLAY DEFINITIONS
**************************************************************************************************/
$l_window_properties: (
    block: (
        display: block
    ),
    size: 100%,
    position: (fixed, 0 null null 0)
);

/**************************************************************************************************
    COMMON OVERLAY DEFINITIONS
**************************************************************************************************/
$l_overlay_properties: (
    block: (
        display: block
    ),
    position: (absolute, $default_overlay_coordinates),
    padding: 0,
    margin: 0
);

/**************************************************************************************************
    COMMON BUBBLE DEFINITIONS
**************************************************************************************************/
$l_bubble_properties: (
    block: (
        display: block
    ),
    position: (relative, 0 null null 0),
    size: 100%,
    padding: 0,
    margin: 0
);

$l_ui_view_properties: (
    size: (
        height: 100%,
    ),
);