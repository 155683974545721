.genotype-probabilities {
    .source-locus {
        @include properties($m-search-genotype-probabilities-source-locus);
    }

    .ms-grid-table {
        @include properties($m-search-genotype-probabilities-grid);

        .cell-field {
            @include properties($m-search-genotype-probabilities-grid-cell-field);
        }

        .locus {
            @include properties($m-search-genotype-probabilities-grid-locus);
        }

        .match-grade {
            @include properties($m-search-genotype-probabilities-grid-match-grade);
        }

        .percent {
            @include properties($m-search-genotype-probabilities-grid-percent);
        }

        tbody .percent {
            @include properties($m-search-genotype-probabilities-grid-body-percent);
        }

        tbody tr td {
            @include properties($m-search-genotype-probabilities-grid-body-cell);
        }
    }

    &.ngdialog {
        @include properties($m-search-genotype-probabilities-dialog);
    }

    .ngdialog-content {
        @include properties($m-search-genotype-probabilities-dialog-content);
    }
}
