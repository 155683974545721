.ms-select-narrow,
.ms-select-component {
    @include properties($c-select-component);
}

.ms-select-narrow {
    .ms-select {
        @include properties($c-select-narrow);

        .ms-select-container {
            .ms-select-option {
                input[type=radio] + label {
                    @include properties($c-multiselect-dropdown-list-item-label-narrow);
                }
            }
        }

        &.open {
            .ms-select-wrapper {
                @include properties($c-select-wrapper-open-narrow);
            }
        }
    }
}

.ms-select {
    @include properties($c-select);

    .ms-select-ctrls {
        @include properties($c-select-ctrls);
    }

    .ms-select-search {
        @include properties($c-select-search-input);

        &.ng-not-empty {
            @include properties($c-select-search-input-not-empty);
        }

        &:focus {
            @include properties($c-select-search-input-focus);
        }
    }

    &.open,
    &:hover {
      .ms-select-search {
        &.ng-not-empty {
          + .ms-select-clean {
            @include properties($c-select-clean-icon-visible);
          }
        }
      }
    }

    .ms-select-wrapper {
        @include properties($c-select-wrapper);
    }

    .ms-select-container {
        @include properties($c-select-container);

        .ms-select-option {
            @include properties($c-select-option);

            label {
                @include properties($c-multiselect-dropdown-list-item-label);
            }

            input[type=radio] {
                @include properties($c-multiselect-hidden-block);

                @include properties($c-select-option-label);

                &::before,
                &::after {
                    @include properties($c-multiselect-hidden-block);
                }
                
            }

            &:hover, &.pointed {
                @include properties($c-select-option-hover);
            }

            &.matched {
                @include properties($c-select-option-matched);
            }
        }

        .empty {
            @include properties($c-select-container-empty);
        }
    }

    .ms-select-clean {
        @include properties($c-select-clean-icon);
    }

    .ms-select-toggle {
        @include properties($c-select-toggle-icon);

        &::after {
            @include properties($c_select_arrow-ico);
            content: '';
        }

        &:hover {
            &::after {
                @include properties($c_select_arrow-ico-hovered);
                content: '';
            }
        }
    }

    .ms-select-show {
        @include properties($c-select-show);
    }

    &.open {
        @include properties($c-select-open);

        .ms-select-toggle {
            &::after {
                @include properties($c_select_arrow-ico-open);
                content: '';
            }

            &:hover {
                &::after {
                    @include properties($c_select_arrow-ico-open-hovered);
                    content: '';
                }
            }
        }

        .ms-select-wrapper {
            @include properties($c-select-wrapper-open);

            display: flex;

            .scroll-wrapper {
                height: auto;
            }
        }
    }
}

.multiline-select .ng-option-label {
    white-space: normal;
}