.sorting-header {
    @include properties($c-sorting-header);

    &-ico {
        @include properties($c-sorting-header-ico);

        &::after {
            content: '';
            @include properties($c-sorting-header-ico-after);
        }
    }

    &.asc {
        .sorting-header-ico {
            &::after {
                @include properties($c-sorting-header-ico-after-asc);
            }
        }
    }

    &.desc {
        .sorting-header-ico {
            &::after {
                @include properties($c-sorting-header-ico-after-desc);
            }
        }
    }
}