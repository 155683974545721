$m-patient-view-section-border-color: #E8F1F7;

/************************************************
    PATIENT: VIEW
 ************************************************/
$m-patient-view: (
    flexbox: (column),
    padding: 0
);
$m-patient-view-header: (
    block: (
        box-sizing: border-box
    ),
    padding: rem(8) rem(20)
);
$m-patient-view-content: (
    size: 100%,
    block: (
        overflow: hidden
    )
);
$m-patient-view-content-container: (
    size: 100%,
    block: (
        overflow: hidden
    ),
    flexbox: (column)
);

$m-patient-view-section: (
    block: (
        box-sizing: border-box
    ),
    border: (
        style: solid,
        width: 0 0 rem(1),
        color: $m-patient-view-section-border-color
    ),
    padding: rem(8) 0 0 0
);
$m-patient-view-section-last-section: (
    border: (
        width: rem(0)
    )
);

$m-patient-view-section-row: (
    flexbox: (row, flex-start, flex-start),
    size: (
        width: 100%
    )
);
$m-patient-view-section-column: (
    block: (
        box-sizing: border-box
    ),
    padding: 0 0 rem(20) 0
);
$m-patient-view-section-column-n-column: ();
$m-patient-view-edit-btn-disabled: (
    block: (
        cursor: not-allowed
    )
);

/************************************************
    PATIENT: DEMOGRAPHICS
 ************************************************/
$m-patient-view-demographics: (
    size: (
        height: 100%
    ),
    block: (
        overflow: auto,
        box-sizing: border-box
    ),
    padding: rem(4) rem(20) rem(12) rem(20)
);
$m-patient-view-patient-info-section-content: (
    block: (
        box-sizing: border-box
    ),
    padding: 0 0 rem(8) 0
);
$m-patient-view-patient-info-section-column: (
    block: (
        box-sizing: border-box
    ),
    padding: 0 0 rem(12) 0
);
$m-patient-view-demographics-race-column: (
    size: (
        max-width: rem(776)
    ),
    margin: 0 0 0 rem(28)
);
$m-patient-view-demographics-race-column-field-value: (
    block: (
        display: inline-block
    ),
    text:(
        white-space: normal
    )
);
$m-patient-view-info-race-field-label: (
    padding: 0
);
$m-patient-view-info-race-field-value: (
    padding: 0
);
$m-patient-view-fixed-width-field-value: (
    size: (
        width: 288px
    ),
    text:(
        white-space: pre-line,
        word-break: break-word
    )
);
$m-patient-view-fixed-width-field-wrapper: (
    size: (
        width: 288px
    ),
);
$m-patient-view-fixed-width-field-wrapper-value: (
    size: (
        width: unset
    ),
);
$m-patient-view-email-ie-field-value: (
    custom: (
        -ms-word-break: break-all
    )
);
$m-patient-view-info-ethnicity-field-label: (
    padding: 0 null null null
);
$m-patient-view-info-section-content: (
    block: (
        box-sizing: border-box
    ),
    padding: 0 0 rem(8) 0
);
$m-patient-view-info-section-column: (
    block: (
        box-sizing: border-box
    ),
    padding: 0 0 rem(12) 0,
    font: (
        size: 0
    ),
);

/************************************************
    PATIENT: CASE INFO
 ************************************************/
$m-patient-view-case-info: (
    size: (
        height: 100%
    ),
    block: (
        overflow: auto,
        box-sizing: border-box
    ),
    padding: rem(8) rem(20) rem(12) rem(20)
);
$m-patient-view-case-info-no-data-message: (
    text: (
        font-size: rem(13),
        color: $color-text-primary,
        line-height: normal,
    ),
);

$m-patient-transfer-history-toolbar: (
    text: (
        text-align: right,
    ),
);
