/**************************************************************************************************
 SEARCH PANEL
**************************************************************************************************/
.search-panel {
    @include properties($m-search-panel);

    &.search-result-page {
      width: rem(280);
    }

    .collapse-link {
      @include properties($m-search-panel-collapse-link);
    }

    &.collapsed {
      @include properties($m-search-panel-collapsed);
        > .container {
            display: none;
        }
    }

    > .container {
        @include properties($m-search-panel-container);
    }
    .search-panel-tabs {
        @include properties($m-search-panel-tabs);

        > .container {
            @include properties($m-search-panel-tabs-container);

            > .bio-find {
                @include properties($m-search-panel-container-find-width);
            }
        }
    }
    .search-panel-tab {
        @include properties($m-search-panel-tab);

        &.active {
            @include properties($m-search-panel-tab-active);
        }
        &.filters {
            @include set-ico-symbol(filter);
        }
        &.columns {
            @include set-ico-symbol(columns);
        }
        &.opl {
            @include set-ico-symbol(list);
        }
        &.find {
            @include set-ico-symbol(pin);
        }

        .ms-count-mark{
            @include properties($m-search-panel-counter);
        }
    }
}
