/**********************************************************************************************
	ELEMENT PROPERTIES
 **********************************************************************************************/
@mixin properties(
    $properties: ()
) {
    @if map-has-key($properties, icon) {
        @include set-ico(map-get($properties, icon));
    }
    @if map-has-key($properties, position) {
        @include position-properties(map-get($properties, position));
    }
    @if map-has-key($properties, size) {
        @include size-properties(map-get($properties, size));
    }
    @if map-has-key($properties, border) {
        @include border-properties(map-get($properties, border));
    }
    @if map-has-key($properties, block) {
        @include block-properties(map-get($properties, block));
    }
    @if map-has-key($properties, font) {
        @include font-properties(map-get($properties, font));
    }
    @if map-has-key($properties, flexbox) {
        $arg: map-get($properties, flexbox);
        @include flexbox($arg...);
    }
    @if map-has-key($properties, flex) {
        @include flex-properties(map-get($properties, flex));
    }
    @if map-has-key($properties, padding) {
        @include padding(map-get($properties, padding));
    }
    @if map-has-key($properties, margin) {
        @include margin(map-get($properties, margin));
    }
    @if map-has-key($properties, text) {
        @include text-properties(map-get($properties, text));
    }
    @if map-has-key($properties, background) {
        @include background-properties(map-get($properties, background));
    }
    @if map-has-key($properties, transform) {
        @include transform(map-get($properties, transform));
    }
    @if map-has-key($properties, transform-origin) {
        @include transform-origin(map-get($properties, transform-origin));
    }
    @if map-has-key($properties, animation) {
        @include animation(map-get($properties, animation));
    }
    @if map-has-key($properties, animation-delay) {
        @include animation-delay(map-get($properties, animation-delay));
    }
    @if map-has-key($properties, placeholder) {
        @include placeholder {
            @include text-properties(map-get($properties, placeholder));
        }
    }
    @if map-has-key($properties, ellipsis) {
        @include ellipsis(map-get($properties, ellipsis));
    }
    @if map-has-key($properties, custom) {
        @include custom-properties(map-get($properties, custom));
    }
}
