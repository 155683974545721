.coop-grid {
    @include properties($c-coop-grid);

    .collapse-link-arrow-ico {
        @include properties($c-coop-grid-collapse-link-arrow-ico);

        &.open {
            @include properties($c-coop-grid-collapse-link-arrow-ico-open);
        }
    }

    thead {
        @include properties($c-coop-grid-table-header);

        tr {
            @include properties($c-coop-grid-table-header-row);
        }

        th {
            @include properties($c-coop-grid-table-header-column)
        }

        th, td {
            @include properties($c-coop-grid-table-header-cell);
        }
    }

    tbody {
        @include properties($c-coop-grid-table-body);

        td {
            @include properties($c-coop-grid-table-body-cell);
        }
    }
}