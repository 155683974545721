#toast-container {
    .patient-pheno {
        color: $color-text-primary
    }
}

.patient-identification-container {
    width: 100%;
    @include properties($m-patient-identification-container);
}
.patient-identification {
    @include properties($m-patient-identification);

    .patient-name {
        @include properties($m-patient-identification-name);
    }

    .patient-id {
        @include properties($m-patient-identification-id);
    }

    .patient-pheno {
        @include properties($m-patient-identification-pheno);
    }
}
.grid-patient-title {
    @include properties($m-grid-patient-title);

    .grid-patient-title-property {
        @include properties($m-grid-patient-title-prop);
    }
}
