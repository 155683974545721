@use 'sass:math';
@import 'fn/index';
@import 'node_modules/ngx-toastr/toastr.css';

.toast-container {
  box-sizing: content-box;

  .toast-close-button {
    background-color: rgba(255, 255, 255, 0);
    border-width: 0;
    box-sizing: border-box;
    color: #91acbc;
    cursor: pointer;
    display: block;
    float: right;
    font-size: rem(29);
    font-weight: 100;
    height: rem(24);
    line-height: rem(24);
    outline: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    right: rem(-9);
    text-decoration: none;
    text-indent: 0;
    top: rem(-6);
    vertical-align: inherit;
    width: rem(25);
  }

  .ngx-toastr {
    background: $white none;
    border-radius: rem(2);
    box-shadow: none;
    box-sizing: content-box;
    line-height: rem(16);
    overflow: visible;

    &:hover {
      box-shadow: none;
    }

    &::before {
      $border: rem(2);
      $height: 1rem;
      border: $border solid $white;
      border-radius: 50%;
      color: $white;
      content: '';
      display: block;
      height: $height;
      left: rem(-34);
      position: absolute;
      text-align: center;
      top: calc(50% - #{math.div($height, 2) + $border});
      width: 1rem;
    }
  }
}

.toast-success.ngx-toastr {
  border: rem(1) solid $color-action-1;
  border-left: rem(50) solid $color-action-1;
  color: $color-text-primary;
  font-size: rem(13);
  padding-left: rem(15);

  &::before {
    background: $color-action-1;
  }

  &::after {
    $height: rem(9);
    $border: rem(2);
    border-color: $white;
    border-style: solid;
    border-width: 0 $border $border 0;
    content: '';
    height: $height;
    left: rem(-28);
    position: absolute;
    top: calc(50% - #{math.div($height, 2) + $border});
    transform: rotate(45deg);
    width: rem(5);
  }
}

.toast-info.ngx-toastr {
  border: rem(1) solid $color-text-main-2;
  border-left: rem(50) solid $color-text-main-2;
  color: $color-text-primary;
  font-size: rem(13);
  padding-left: rem(15);
  width: rem(350);

  &::before {
    background: $color-text-main-2;
  }

  &::after {
    $height: rem(6);
    background: $white;
    box-shadow: rem(0) rem(3) rem(0) rem(0) $color-text-main-2, rem(0) rem(5) rem(0) $white;
    content: '';
    height: $height;
    left: rem(-25);
    position: absolute;
    top: calc(50% + #{math.div($height, 2) - rem(3)});
    transform: rotate(180deg);
    width: rem(2);
  }
}

.toast-error.ngx-toastr {
  border: rem(1) solid $color-highlight;
  border-left: rem(50) solid$color-highlight;
  color: $color-text-primary;
  font-size: rem(13);
  padding-left: rem(15);

  &::before {
    background: $color-highlight;
    content: ' ! ';
  }
}
