.order-fields-list{
    @include properties($c-order-list-fields);

    li{
        @include properties($c-order-list-fields-li);

        & > span{
            @include properties($c-order-list-fields-text);
        }

        .label{
            @include properties($c-order-list-fields-text-label);
        }
    }

    .order-type{
        @include properties($c-order-list-fields-order-type);

        .type-label{
            @include properties($c-order-list-fields-order-type-label);
        }

        .status{
            @include properties($c-order-list-fields-order-type-status);
        }
    }

    .request-date, .appointment-date, .received-date, .clearance,
    .preparation-start, .collection, .infusion, .rep-sample{
        @include properties($c-order-list-default-fields);
    }

    .cryopreserved-shipment-date {
      width: rem(296);
    }

    .release-reason{
        @include properties($c-order-list-default-release-reason-fields);
    }

    .requested-item {
        @include properties($c-order-list-default-request-item-fields);
    }

    .requested-item > span {

        @include properties($c-order-list-requested-item-fields-text);
    }
}

.cords-orders .order-fields-list {
    .order-type, .request-date, .ct-ship-date, .ship-date {
        @include properties($c-order-list-cord-fields);
    }
}
