.picker_wrapper{
    input[type='text']{
        &.form-control{
            @include properties($c-datepicker-dafault-input-hide)
        }
    }

    .picker_icon{
        @include properties($c-datefield-icon);

        &:before{
            @include properties($c-datefield-icon-line-property);
        }
    }

    &.ms-narrow-datepicker{
        input{
            &.mask-input{
                @include properties($c-datefield-mask-input-narrow);
            }
        }

        .picker_icon{
            @include properties($c-datefield-icon-narrow);

            &:before{
                @include properties($c-datefield-icon-line-property-narrow);
            }
        }
    }
}

.datepicker{
    &.datepicker-dropdown {
        @include properties($c-datepicker-dropdown-overrides);

        &.datepicker-orient-bottom{
            @include properties($c-datepicker-orient-bottom);

            &:after, &:before{
                @include properties($c-datepicker-orient-pseudo-display)
            }

            .datepicker-months, .datepicker-years, .datepicker-days, .datepicker-decades, .datepicker-centuries{
                @include properties($c-datepicker-orient-bottom-blocks)
            }
        }

        &.datepicker-orient-top{
            &:after, &:before{
                @include properties($c-datepicker-orient-pseudo-display)
            }

            .datepicker-months, .datepicker-years, .datepicker-days, .datepicker-decades, .datepicker-centuries{
                @include properties($c-datepicker-orient-top-blocks)
            }
        }

        thead {
            .datepicker-switch{
                @include properties($c-datepicker-month-header);

                &:hover{
                    @include properties($c-datepicker-month-header-hover);
                }
            }

            .prev, .next{
                @include properties($c-datepicker-month-header-controls);

                &:hover{
                    color: transparent !important;

                    &:before{
                        @include properties($c-datepicker-month-header-controls-hover);
                    }
                }

                &:before{
                    @include properties($c-datepicker-month-header-controls-color);
                }
            }

            .prev{
                @include properties($с-datepicker-prev-month-arrow);
            }

            .next{
                @include properties($с-datepicker-next-month-arrow);
            }

            tr{
                .dow{
                    @include properties($c-datepicker-month-header-titles);
                }
            }
        }

        tbody {
            @include properties($c-datepicker-body-text-properties)
        }

        .datepicker-days{
            @include properties($c-datepicker-days-properties);

            tbody{
                tr {
                    @include properties($c-datepicker-days-body-tr-border);

                    .day {
                        @include properties($c-datepicker-days-body-day);

                        &:last-child {
                           @include properties($c-datepicker-days-body-day-last);
                        }

                        &:hover {
                            @include properties($c-datepicker-days-body-day-hover)
                        }

                        &.today {
                            @include properties($c-datepicker-days-today-day);

                            &:hover {
                                @include properties($c-datepicker-days-today-day-hover)
                            }
                        }

                        &.disabled, &.old {
                           @include properties($c-datepicker-days-disabled-day)
                        }

                        &.active {
                            @include properties($c-datepicker-days-active-day)
                        }
                    }
                }
            }
        }

        .datepicker-months, .datepicker-years, .datepicker-decades, .datepicker-centuries{
            tbody {
                td {
                    @include properties($c-datepicker-month-td-padding)
                }

                tr {
                    @include properties($c-datepicker-month-tr-border);

                    .month, .year, .decade, .century {
                        @include properties($c-datepicker-month-item);

                        &:last-child {
                            @include properties($c-datepicker-days-body-day-last)
                        }

                        &:hover {
                            @include properties($c-datepicker-days-body-day-hover)
                        }

                        &.today {
                            @include properties($c-datepicker-days-today-day);

                            &:hover {
                                @include properties($c-datepicker-days-today-day-hover)
                            }
                        }

                        &.disabled, &.old {
                            @include properties($c-datepicker-days-disabled-day)
                        }

                        &.active {
                            @include properties($c-datepicker-days-active-day)
                        }
                    }
                }
            }
        }

        .datepicker-years{
            thead{
                .datepicker-switch {
                    &:hover {
                        @include properties($c-datepicker-years-hover-properties);
                    }
                }
            }
        }

        .datepicker-months, .datepicker-years, .datepicker-decades, .datepicker-centuries{
            @include properties($c-datepicker-items-properties);
        }
    }
}




