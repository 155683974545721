/**********************************************************************************************
	TEXT PROPERTIES
 **********************************************************************************************/
@mixin text-properties(
    $properties: ()
){
    // set up text color
    @if map-has-key($properties, color) {
        @if type-of(map-get($properties, color)) == color {
            color: map-get($properties, color);
        }
    }
    // set up font
    @if map-has-key($properties, font) {
        @include set-font(map-get($properties, font));
    }
    // set up font size
    @if map-has-key($properties, font-size) {
        @if type-of(map-get($properties, font-size)) == 'string' {
            // for "inherit" value
            font-size: map-get($properties, font-size);
        } @else {
            @if unitless(map-get($properties, font-size)) {
                @include font-size(map-get($properties, font-size));
            } @else {
                font-size: map-get($properties, font-size);
            }
        }
    }
    // set line-height
    @if map-has-key($properties, line-height) {
        line-height: map-get($properties, line-height);
    }
    // set letter-spacing
    @if map-has-key($properties, letter-spacing) {
        letter-spacing: map-get($properties, letter-spacing);
    }
    // set text-align
    @if map-has-key($properties, text-align) {
        text-align: map-get($properties, text-align);
    }
    // set text-decoration
    @if map-has-key($properties, text-decoration) {
        text-decoration: map-get($properties, text-decoration);
    }
    // set text-shadow
    @if map-has-key($properties, text-shadow) {
        text-shadow: map-get($properties, text-shadow);
    }
    // set text-transform
    @if map-has-key($properties, text-transform) {
        text-transform: map-get($properties, text-transform);
    }
    // set text-indent
    @if map-has-key($properties, text-indent) {
        text-indent: map-get($properties, text-indent);
    }
    // set vertical-align
    @if map-has-key($properties, vertical-align) {
        vertical-align: map-get($properties, vertical-align);
    }
    // set white-space
    @if map-has-key($properties, white-space) {
        white-space: map-get($properties, white-space);
    }
    // set word-break
    @if map-has-key($properties, word-break) {
        word-break: map-get($properties, word-break);
    }
    // set text selection ability
    @if map-has-key($properties, user-select) {
        @if map-get($properties, user-select) == 'none' {
            -webkit-touch-callout: none;
            cursor: default;
        } @else {
            -webkit-touch-callout: default;
            cursor:auto;
        }
        -webkit-user-select: map-get($properties, user-select);
        -khtml-user-select: map-get($properties, user-select);
        -moz-user-select: map-get($properties, user-select);
        -ms-user-select: map-get($properties, user-select);
        user-select: map-get($properties, user-select);
    }
}