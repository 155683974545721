.ms-simple-grid {
    @include properties($c-simple-grid);

    thead {
        @include properties($g-grid-table-thead);

        th {
            @include properties($c-simple-grid-th);
        }
    }

    tbody {
        td {
            @include properties($c-simple-grid-td);
        }

        tr:nth-child(2n + 1) {
            @include properties($c-simple-grid-row-odd);
        }
        tr:nth-child(2n) {
            @include properties($c-simple-grid-row-even);
        }
    }
}