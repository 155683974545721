.ms-count-mark {
    @include properties($c-count-mark);

    &.paired {
          @include properties($c-count-mark-paired);
    }

    &.warn {
        @include properties($c-count-mark-warn);
    }
}
