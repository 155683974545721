@import 'fn/index';

$tippy-arrow-size: rem(5);
$tippy-namespace-prefix: 'tippy';
$tippy-arrow-wrapper-size: rem(12);

.#{$tippy-namespace-prefix}-box {
  background-color: #1d3649;
  font-size: rem(12);

  &[data-placement^='top'] > .#{$tippy-namespace-prefix}-arrow::before {
    border-width: $tippy-arrow-size $tippy-arrow-size 0;
    bottom: -$tippy-arrow-size;
  }

  &[data-placement^='bottom'] > .#{$tippy-namespace-prefix}-arrow::before {
    border-width: 0 $tippy-arrow-size $tippy-arrow-size;
    top: -$tippy-arrow-size;
  }

  &[data-placement^='right'] > .#{$tippy-namespace-prefix}-arrow::before {
    border-width: $tippy-arrow-size $tippy-arrow-size $tippy-arrow-size 0;
    left: -$tippy-arrow-size;
  }

  &[data-placement^='left'] > .#{$tippy-namespace-prefix}-arrow::before {
    border-width: $tippy-arrow-size 0 $tippy-arrow-size $tippy-arrow-size;
    right: -$tippy-arrow-size;
  }

  .#{$tippy-namespace-prefix}-arrow {
    height: $tippy-arrow-wrapper-size;
    width: $tippy-arrow-wrapper-size;
  }
}
