/**************************************************************************************************
 SEARCH PANEL variables
**************************************************************************************************/
$m-search-panel-width: rem(261);

$m-search-panel-container-find-width: (
    size: (
        width: rem(60)
    ),
);

$m-search-panel: (
    size: (
        width: $m-search-panel-width
    ),
    block: (
        overflow: visible
    ),
    background: (
        color: #FFFFFF
    ),
    border: (
        style: solid,
        color: #DBDBDB,
        width: 0 rem(1) 0 rem(1)
    ),
    position: relative,
    margin: 0 0 0 rem(20),
);
$m-search-panel-container: (
    size: 100%,
    flexbox: (column)
);
$m-search-panel-tabs: (
    size: (
        height: rem(60)
    )
);
$m-search-panel-tabs-container: (
    size: 100%,
    flexbox: (row, center, space-between)
);
$m-search-panel-tab: (
    font: $font-bold-properties,
    icon: (
        type: vertical,
        font_size: 1.4,
        width: 24px,
        height: 25px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: $color-border,
        initial_ico_bg_color: $transparent,
        initial_ico_color: $color-border,
        hover_bg_color: $transparent,// optional -> unused 4 default
        hover_color: #1D3649,// optional -> unused 4 default
        hover_ico_bg_color: inherit,// optional -> used 4 vertical only
        hover_ico_color: #1D3649// optional -> used 4 vertical only
    ),
    text: (
        font-size: 1.3
    ),
    size: (
        width: rem(84)
    ),
    padding: rem(10) 0
);
$m-search-panel-tab-active: (
    block: (
        box-shadow: 0 -2px 0 0 #BDCD42 inset
    ),
    icon: (
        type: vertical,
        font_size: 1.4,
        width: 24px,
        height: 25px,
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: #1D3649,
        initial_ico_bg_color: $transparent,
        initial_ico_color: #1D3649,
        hover_bg_color: $transparent,// optional -> unused 4 default
        hover_color: #1D3649,// optional -> unused 4 default
        hover_ico_bg_color: inherit,// optional -> used 4 vertical only
        hover_ico_color: #1D3649// optional -> used 4 vertical only
    )
);
$m-search-panel-counter: (
    position: (absolute, null rem(0) null null)
);
$m-search-panel-collapsed: (
    size: (
        width: 0,
    ),
    border: (
        width: 0 0 0 rem(1),
    ),
);
$m-search-panel-collapse-link: (
    position: (absolute, rem(16) null null rem(-13)),
);
