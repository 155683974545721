/**********************************************************************************************
	BLOCK SIZE
 **********************************************************************************************/
@mixin size-properties(
    $properties: ()
) {
    @if type-of($properties) == "map" {
        // set width
        @if map-has-key($properties, width) {
            width: map-get($properties, width);
        }
        // set min-width
        @if map-has-key($properties, min-width) {
            min-width: map-get($properties, min-width);
        }
        // set max-width
        @if map-has-key($properties, max-width) {
            max-width: map-get($properties, max-width);
        }
        // set height
        @if map-has-key($properties, height) {
            height: map-get($properties, height);
        }
        // set min-height
        @if map-has-key($properties, min-height) {
            min-height: map-get($properties, min-height);
        }
        // set max-height
        @if map-has-key($properties, max-height) {
            max-height: map-get($properties, max-height);
        }
    }
    @else {
        @include size($properties);
    }
}