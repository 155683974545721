.fill-space:after {
  content: ".";
  visibility: hidden;
}

.text-wrap {
  word-break: break-word;
  white-space: normal;
}

.capitalized-text {
  text-transform: capitalize;
}

.uppercase-text {
  text-transform: uppercase;
}
