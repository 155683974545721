@import 'variables';
@import 'fn/index';

@mixin selected-link {
  text-decoration: underline $color-brand-alt-4 rem(2);
  text-underline-offset: rem(5);
}

@mixin flex-box (
  $direction: row,
  $alignment: stretch,
  $content_position: space-between
) {
  align-items: $alignment;
  display: flex;
  flex-direction: $direction;
  justify-content: $content_position;
}

@mixin page-size (
  $height,
  $width,
) {
  height: $height;
  width: $width;
}

@mixin required {
  &::before {
    color: #d44848;
    content: $default-required-mark-symbol;
    font-size: rem(12);
    padding: 0 rem(4) 0 0;
  }
}

@mixin page-ellipsis () {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}



