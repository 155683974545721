.search-status-running,
.search-status-unviewed {
    .container {
        .collapsable-grid {
            &:nth-child(odd) .collapse-panel,
            &:nth-child(odd) .ms-grid-row {
                background: $color-alt-2;
            }
            &:nth-child(even) .collapse-panel,
            &:nth-child(even) .ms-grid-row {
                background: $white;
            }
        }
    }

    .grid-patient-heading {
        @include properties($m-search-status-running-grid-patient-heading);

        .component-wrapper {
            @include properties($m-search-status-running-grid-patient-heading-checkbox);
        }
    }

    .ms-grid-checkbox-label {
        @include properties($m-search-status-running-grid-patient-label);
    }

    /* adding border at bottom of heading row */
    .grid-row-heading {
        @include properties($m-search-status-grid-row-heading);

        &:after {
            @include properties($m-search-status-grid-row-heading-after);
            content: '';
        }
    }
    .ms-grid-header .ms-grid-column.patient-identification-column {
        @include properties($m-search-patient-identification-column);
    }

   .patient-identification-column {
       .component-wrapper {
           @include properties($m-search-status-component-wrapper);
       }
   }

    .patient-identification-container {
        @include properties($m-search-status-patient-identification-container);
    }
}

.search-status-unviewed {
    ms-grid-patient-title {
        @include properties($m-search-status-ms-grid-patient-title);
    }
}

