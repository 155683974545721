.text-info {
    @include properties($default-text-info);

    &-important {
        @include properties($text-info-important);
    }

    &-title {
        @include properties($text-info-title);
    }

    &-wide {
        @include properties($text-info-wide);
    }

    &-inline {
        @include properties($text-info-inline);
    }
}