/**************************************************************************************************
    FLEX BOX
**************************************************************************************************/
@mixin flexbox(
    $direction: row,
    $alignment: stretch,
    $content_position: space-between
){
    @include display(flex);
    @include flex-direction($direction);
    @include align-items($alignment);
    @include justify-content($content_position);

    > .flexible,
    > compose > .flexible { @include flex(1); }
}

@mixin flex-properties($properties) {
    @if map-has-key($properties, flex) {
        flex: map-get($properties, flex);
    }

    @if map-has-key($properties, wrap) {
        flex-wrap: map-get($properties, wrap);
    }
}