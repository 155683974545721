.filter-section{
    @include properties($m-search-filter-section);

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
  }

    .filter-header{
        @include properties($m-search-filter-header);

        &:hover{
            @include properties($m-search-filter-header-hover);
        }

        &:before{
            content: ' ';
            @include properties($m-search-filter-header-icon);
        }
    }

    .filter-content{
        @include properties($m-search-filter-collapse-content);

        .section-content{
           @include properties($m-search-filter-content-section);

            .section-row{
                &:not(.validation-field){
                    @include properties($m-search-filter-content-section-row);
                }

                .section-label{
                    @include properties($m-search-filter-content-section-label);
                }

                .section-column{
                    &.flexible{
                        @include properties($m-search-filter-content-section-column);
                    }
                }
            }

            .checkbox-group{
                @include properties($m-search-filter-content-section-checkbox-group);

                &--with-margin {
                  margin-left: rem(10);
                }

                li{
                    &.checkbox-child {
                        @include properties($m-search-filter-content-section-checkbox-child);
                    }

                    @include properties($m-search-filter-content-section-checkbox-group-items-default);

                    & ~ li{
                        @include properties($m-search-filter-content-section-checkbox-group-items);
                    }
                }

              &.tooltip-text {
                @include properties($checkbox-group-tooltip-text-props);
              }
            }
        }
    }

    &.open{
        .filter-header {
            @include properties($m-search-filter-header-open);

            &:before {
                content: ' ';
                @include properties($m-search-filter-header-icon-open);
            }
        }

        .filter-content{
           @include properties($m-search-filter-content-open);
        }
    }

}
