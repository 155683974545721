.ms-side-tabs-container {
    @include properties($g-side-tabs-container);

    &.ms-side-tabs-left {
        @include properties($g-side-tabs-left-container);

        .ms-tab-content {
            @include properties($g-side-tabs-left-tab-content);
        }
    }

    .ms-side-tabs {
        @include properties($g-side-tabs);

        .scroll-wrapper {
            @include properties($g-side-tabs-scroll-wrapper);
        }

        .ms-side-tabs-title {
            @include properties($g-side-tabs-title);
        }

        .ms-tab {
            @include properties($g-side-tabs-tab);
        }

        .tab-link {
            @include properties($g-side-tabs-tab-link);

            &:not(.disabled):hover {
                @include properties($g-side-tabs-tab-link-hover);
            }

            &.active {
                @include properties($g-side-tabs-tab-link-active);

                &:hover {
                    @include properties($g-side-tabs-tab-link-active-hover);
                }
            }

            &.disabled {
                @include properties($g-side-tabs-tab-link-disabled);
            }
        }

        .tab-label {
            @include properties($g-side-tabs-tab-label);
        }
    }

    .ms-tab-content {
        @include properties($g-side-tabs-tab-content);

        > .container {
            @include properties($g-side-tabs-tab-container);
        }
    }
}