@use 'sass:math';
/************************************************
    SPINNER: LINE
 ************************************************/
$c-progress-spinner-bounce-number: 14;
$c-progress-spinner-bounce-animation-delay: 0.1s;
$c-progress-spinner-bounce-color: #BDCD42;

$c-progress-spinner: ();
$c-progress-spinner-bounce: (
    block: (
        display: inline-block
    ),
    size: (
        width: rem(4),
        height: rem(4)
    ),
    background: (
        color: $c-progress-spinner-bounce-color
    ),
    border: (
        radius: 100%
    ),
    animation: progress-spinner-bounce $c-progress-spinner-bounce-animation-delay * $c-progress-spinner-bounce-number infinite ease-in both
);

$c-progress-spinner-bounce-list: ();
@for $i from 1 through $c-progress-spinner-bounce-number {
    $c-bounce-properties: (
        animation-delay: $c-progress-spinner-bounce-animation-delay * $i
    );
    $c-progress-spinner-bounce-list: append($c-progress-spinner-bounce-list, $c-bounce-properties);
}


/************************************************
    SPINNER: ROUNDED
 ************************************************/
$c-rounded-spinner-bounce-animation-delay: .2s;
$c-rounded-spinner-radius: rem(12);
$c-rounded-spinner-bounce-number: 8;
$c-rounded-spinner-bounce-offset: (1 - math.div(1, sqrt(2))) * $c-rounded-spinner-radius;

$c-rounded-spinner: (
    position: relative,
    block: (
        display: inline-block,
        box-sizing: border-box
    ),
    size: (
        width: $c-rounded-spinner-radius * 2,
        height: $c-rounded-spinner-radius * 2
    )
);
$c-rounded-spinner-bounce: (
    position: (absolute, $c-rounded-spinner-bounce-offset null null $c-rounded-spinner-bounce-offset),
    block: (
        display: inline-block,
        box-sizing: border-box
    ),
    size: (
        width: rem(4),
        height: rem(4)
    ),
    border: (
        radius: 50%,
    ),
    background: (
        color: $white,
    )
);

$c-rounded-spinner-active-bounce: (
    background: (
        color: $c-progress-spinner-bounce-color,
    ),
    animation: rounded-spinner-bounce $c-rounded-spinner-bounce-animation-delay * $c-rounded-spinner-bounce-number infinite ease-in both
);

$c-rounded-spinner-active-bounce-list: ();
@for $i from 1 through $c-rounded-spinner-bounce-number {
    $c-bounce-properties: (
        animation-delay: $c-rounded-spinner-bounce-animation-delay * $i
    );
    $c-rounded-spinner-active-bounce-list: append($c-rounded-spinner-active-bounce-list, $c-bounce-properties);
}
