/**********************************************************************************************
	BORDER PROPERTIES
 **********************************************************************************************/
@mixin border-properties($properties) {
    @if map-has-key($properties, style) {
        @include border-style(map-get($properties, style));
    }
    @if map-has-key($properties, color) {
        @include border-color(map-get($properties, color));
    }
    @if map-has-key($properties, width) {
        @include border-width(map-get($properties, width));
    }
    @if map-has-key($properties, radius) {
        border-radius: map-get($properties, radius);
    }
}