.match-preference-filter-wrapper{
    .view-switcher{
        .group{
            &.vertical{
                display: block;

                .group-item{
                    margin-left: -7px;
                }
            }
        }
    }

    .preference{
        .section-row{
            &.match-criteria{
                display:flex;
                flex-wrap: wrap;
                padding-bottom: 20px;

                .section-label{
                    width: 101%;
                }
            }
        }

        .acceptable-mismatches{
            width: 100%;
            font-size: 12px;
            color: $color-border;

            thead{
                line-height: 12px;

                td{
                    border: 0;
                    padding-bottom: 3px;
                    line-height: 1;
                    height: auto;
                    background: none;

                    &.checkbox-cell{
                        text-align: center;

                        .field-element{
                            margin-left: -5px;
                        }
                    }
                }
            }

            tbody{
                background: white;
            }

            td{
                padding: 0 5px;
                border: 1px solid #D2D9DF;
                border-collapse: collapse;
                height: 28px;
                line-height: 28px;
                vertical-align: baseline;
                background: #EDF0F2;

                &.checkbox-cell{
                    position: relative;
                    width: 60px;
                    padding: 0;
                    overflow: hidden;
                    vertical-align: top;
                    background: none;

                    span {
                        display: block;
                        text-align: center;
                    }

                    input[type='checkbox']{
                        &:checked{
                            + label{
                                @include properties($test);
                                position: absolute;
                                width: 100%;
                                height: 29px;
                                line-height: 29px;
                                text-align: center;

                                &:before{
                                    line-height: 29px;
                                }
                            }
                        }

                        &:disabled {
                            + label{
                                position: absolute;
                                width: 100%;
                                height: 39px;

                                &:hover{
                                    cursor: not-allowed !important;
                                    pointer-events: all !important;
                                }

                                &:before{
                                    display: none;
                                }
                            }
                        }
                    }

                    label{
                        position: absolute;
                        width: 100%;
                        height: 29px;
                        text-align: center;
                        padding: 0px;


                        &:before{
                            display: none;
                        }

                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .likelihood{
        padding-top: 0px;

        .section-content {
             li {
                 &.section-row {
                     display: flex;
                     padding-bottom: 0;

                     .section-column{
                         padding-right: 16px;
                         flex: 1
                     }
                 }
             }
        }
    }
}