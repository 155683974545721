@import '../app/styles/fn/pix_to_rem';

.draggable-item .grab-handle {
  cursor: grab;
}

.gu-unselectable,
.gu-unselectable .draggable-item .grab-handle {
  cursor: grabbing;
}

.draggable-item.gu-transit {
  border-top: rem(2) solid #0C87FF;
  opacity: 1;

  & > * {
    opacity: .3;
  }
}

.draggable-item.gu-mirror {
  opacity: .8;
}
