.ms-module-area {
    @include properties($m-common-area);
}
.ms-module-header-area {
    @include properties($m-common-header-area);

    > .container {
        @include properties($m-common-header-container);
    }

    + .ms-module-content-area {
        @include properties($m-common-module-header-area-n-content-area);
    }
}
.ms-module-header-container {
    @include properties($m-common-header-wrapper);

    > .container {
        @include properties($m-common-header-wrapper-container);
    }
}
.ms-module-subheader-container {
    @include properties($m-common-module-subheader-wrapper);
}
.ms-module-header {
    @include properties($m-common-header);
}
.ms-module-header-toolbar {
    @include properties($m-common-module-header-toolbar);

    &.toolbar-area {
        > .container {
            @include properties($m-common-module-header-toolbar-area-container);
        }
    }
}

.ms-module-content-area {
    @include properties($m-common-module-content-area);

    > .container {
        @include properties($m-common-module-content-container);
    }
}

.ms-module-section {
    + .ms-module-section {
        @include properties($m-common-module-section);
    }
}
.ms-module-section-header {
    @include properties($m-common-section-header);
}
.ms-module-section-header-container {
    @include properties($m-common-section-header-container);
}
.ms-module-section-toolbar {
    @include properties($m-common-section-header-toolbar);
}

@for $i from 1 through length($m-common-col-list) {
    .col-#{$i} {
        @include properties(nth($m-common-col-list, $i));

        &.non-flexible {
            @include properties(nth($m-common-col-non-flexible-list, $i));
        }
    }
}

.ms-module-header-back-ico {
    @include properties($m-common-module-header-back-arrow-ico);
}

.ui-grid-scrollbar-when-needed {
    .ui-grid .ui-grid-header {
        @include properties($ui-grid-header);
    }
    .ui-grid-header-cell-wrapper{
        @include properties($ui-grid-header-cell-wrapper);
    }
}

.areas-divider {
    @include properties($m-common-areas-divider);
}

.required-mark {
    @include required;
    @include properties($m-common-required-mark);
}

.toolbar-tc-list {
  @include properties($m-toolbar-tc-list-container);
}

.module-menu-item-container {
  @include properties($m-module-menu-item-container);

  &:first-child {
    &.module-menu-item-container--active {
      @include properties($m-module-menu-item-active-first);
    }
  }
}

.module-menu-item-container--active {
  &:before {
    content: '';
    @include properties($m-module-menu-item-container-active-before);
  }

  &:after {
    content: '';
    @include properties($m-module-menu-item-container-active-after);
  }

  @include properties($m-module-menu-item-container-active);
}

.module-menu-item {
  @include properties($m-module-menu-item);
}
