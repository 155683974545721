.search-status-unviewed {
    @include properties($m-search-status-unviewed);

    .ms-grid-column.patient-identification-column {
        @include properties($m-search-status-unviewed-patient-identification-column);
    }
    .ms-grid-column.date-completed-date-column {
        @include properties($m-search-status-unviewed-date-completed-date-column);
    }
    .patient-identification-column{
        padding-left: 51px;
    }
    .field-wrapper.simple-field .component-wrapper input[type=checkbox] + label:before{
        font-weight: normal;
    }
}
