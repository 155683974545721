/**************************************************************************************************
GLOBAL LINK STYLE
**************************************************************************************************/
.nsm-dialog,
.ms-dialog,
.ms-main-content {
    .ms-link {
      @include properties($g-link);

         &:visited {
            @include properties($g-link);
        }

        &.disabled {
            @include properties($g-link-disabled);
        }
    }

    button.ms-link,
    input[type=button].ms-link {
        @include properties($g-link-button-reset);
        @include properties($g-link);
    }

    button[disabled].ms-link,
    input[type=button][disabled].ms-link {
        @include properties($g-link-disabled);
    }

    button.no-style {
        @include properties($g-link-button-reset);
    }
}
