/**********************************************************************************************
	BLOCK PROPERTIES
 **********************************************************************************************/
@mixin block-properties($properties) {
    // set box-sizing
    @if map-has-key($properties, box-sizing) {
        box-sizing: map-get($properties, box-sizing);
    }
    // set box-shadow
    @if map-has-key($properties, box-shadow) {
        box-shadow: map-get($properties, box-shadow);
    }
    // set display mode
    @if map-has-key($properties, display) {
        display: map-get($properties, display);
    }
    // set cursor mode
    @if map-has-key($properties, cursor) {
        cursor: map-get($properties, cursor);
    }
    // set outline
    @if map-has-key($properties, outline) {
        outline: map-get($properties, outline);
    }
    // set opacity
    @if map-has-key($properties, opacity) {
        opacity: map-get($properties, opacity);
    }
    // set z-index
    @if map-has-key($properties, index) {
        z-index: map-get($properties, index);
    }
    // set overflow mode
    @if map-has-key($properties, overflow) {
        overflow: map-get($properties, overflow);
    }
    // set overflow-x mode
    @if map-has-key($properties, overflow-x) {
        overflow-x: map-get($properties, overflow-x);
    }
    // set overflow-y mode
    @if map-has-key($properties, overflow-y) {
        overflow-y: map-get($properties, overflow-y);
    }
    // set white space
    @if map-has-key($properties, white-space) {
        white-space: map-get($properties, white-space);
    }
    // set text overflow
    @if map-has-key($properties, text-overflow) {
        text-overflow: map-get($properties, text-overflow);
    }
    // set ellipsis
    @if map-has-key($properties, ellipsis) {
        @include ellipsis(map-get($properties, ellipsis)...);
    }
    // set overflow mode
    @if map-has-key($properties, visibility) {
        visibility: map-get($properties, visibility);
    }
    // set transition for animations
    @if map-has-key($properties, transition) {
        transition: map-get($properties, transition);
    }
    // set grid-template-columns for css grid layout
    @if map-has-key($properties, grid-template-columns) {
        grid-template-columns: map-get($properties, grid-template-columns);
    }
    // set grid-template-rows for css grid layout
    @if map-has-key($properties, grid-template-rows) {
        grid-template-rows: map-get($properties, grid-template-rows);
    }
    // set clipping area settings
    @if map-has-key($properties, clip) {
        clip: map-get($properties, clip);
    }
}
