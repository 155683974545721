.ms-table {
    @include properties($g-table);

    >.body-overlay>.body>.row,
    >.body>.row,
    >.row {
        @include properties($g-table-row);

        >.cell {
            @include properties($g-table-cell);

            &.cell-container {
                @include properties($g-table-cell-container);
            }
        }
    }
}
