/************************************************
    PATIENT: VIEW
 ************************************************/
.patient-view {
    @include properties($m-patient-view);

    .ms-module-header-area {
        @include properties($m-patient-view-header);
    }

    .edit-patient-btn-disabled {
        @include properties($m-patient-view-edit-btn-disabled);
        input {
            pointer-events: none;
        }
    }

    .close-case-btn-disabled {
        @include properties($m-patient-view-edit-btn-disabled);
        input {
            pointer-events: none;
        }
    }

    .content-area {
        @include properties($m-patient-view-content);

        > .container {
            @include properties($m-patient-view-content-container);
        }
    }

    .patient-form-area {
        > section {
            @include properties($m-patient-view-section);

            &:last-child {
                @include properties($m-patient-view-section-last-section);
            }
        }
    }

    .section-row {
        @include properties($m-patient-view-section-row);
    }

    .section-column {
        @include properties($m-patient-view-section-column);

        + .section-column {
            @include properties($m-patient-view-section-column-n-column);
        }
    }

    .contact-information {
        &.us {
            &.other-language {
                .other-language-specific {
                    display: flex;
                }
            }
            .us-specific {
                display: flex;
             }
            .other-language-specific {
                display: none;
            }
        }
        .us-specific {
            display: none
        }
    }
}

/************************************************
    PATIENT: DEMOGRAPHICS
 ************************************************/
.patient-demographics {
    @include properties($m-patient-view-demographics);

    .contact-information .field-value,
    .diagnosis .field-value {
        @include properties($m-patient-view-fixed-width-field-value)
    }
    .contact-information ms-text[name='email'] .field-value{
        @include properties($m-patient-view-email-ie-field-value)
    }

    .contact-information .field-value-wrapper {
      @include properties($m-patient-view-fixed-width-field-wrapper);
      .field-value {
        @include properties($m-patient-view-fixed-width-field-wrapper-value);
      }
    }
}

.patient-info-section {
    .race .ms-field-view {
        .field-label {
            @include properties($m-patient-view-info-race-field-label);
        }

        .field-value {
            @include properties($m-patient-view-info-race-field-value);
        }
    }

    .section-column.race-column {
        @include properties($m-patient-view-demographics-race-column);

        .field-value {
            @include properties($m-patient-view-demographics-race-column-field-value);
        }
    }

    .ethnicity .ms-field-view .field-label {
        @include properties($m-patient-view-info-ethnicity-field-label);
    }

    .section-content {
        @include properties($m-patient-view-info-section-content);
    }

    .section-column {
        @include properties($m-patient-view-info-section-column);
    }
}

/************************************************
    PATIENT: CASE INFO
 ************************************************/
.patient-case-info {
    @include properties($m-patient-view-case-info);

    .no-patient-data-message {
        @include properties($m-patient-view-case-info-no-data-message);
    }

    .patient-transfer-history-section {
        .toolbar-area {
            @include properties($m-patient-transfer-history-toolbar);
        }
    }
}
