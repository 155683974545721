body {
    .tippy-popper {
        @include properties($c-tooltip-wrapper);

      &.tooltip-align-text-left {
        .tippy-tooltip {
         @include properties($c-tooltip-text-align-left);
        }
      }
    }

  .tippy-backdrop {
    @include properties($c-tooltip-backdrop);
  }

    .tippy-tooltip {
        @include properties($c-tooltip);

        .tippy-content {
            @include properties($c-tooltip-content);
        }
    }

    .tippy-popper[x-placement^=left] .tippy-arrow {
        @include properties($c-tooltip-arrow-left);
    }

    .tippy-popper[x-placement^=right] .tippy-arrow {
        @include properties($c-tooltip-arrow-right);
    }

    .tippy-popper[x-placement^=top] .tippy-arrow {
        @include properties($c-tooltip-arrow-top);
    }

    .tippy-popper[x-placement^=bottom] .tippy-arrow {
        @include properties($c-tooltip-arrow-bottom);
    }
}
