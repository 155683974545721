/**************************************************************************************************
    COMMON BUTTON PROPERTIES
**************************************************************************************************/
@mixin ms_button_normalization() {
    &::-moz-focus-inner {
        border: 0;
    }
    -webkit-background-clip: padding-box;

    @include properties($g_common_button);

    &:hover {
        @include properties($g_common_button_hover);
    }

    &:active, &.active {
        @include properties($g_common_button_active);
    }

    &:focus, &.focus {
        @include properties($g_common_button_focus);
    }

    &:disabled, &.disabled {
        @include properties($g_common_button_disabled);
    }
}

/**************************************************************************************************
    BUTTON TYPES
**************************************************************************************************/
@mixin ms_button_type (
    $type: $default_button_type,
    $properties: ()
) {
    @include ms_button_normalization;

    @if $type == plane {
        @include properties($g_button_plane_properties);
    }
    @else if $type == functional {
        @include properties($g_button_functional_properties);
    }
    @else if $type == link {
        @include properties($g_button_link_properties);
    }
    @else if $type == ico {
        @include properties($g-ico-button-properties);
    }
    @else if $type == custom {
        @include properties($properties);
    }
    @else {
        @include properties($g_button_default_properties);
    }
}

/**************************************************************************************************
    BUTTON COLORS
**************************************************************************************************/
@mixin ms_button_color(
    $color_schema: $common_button_color_schema
) {
    // APPLY COLOR SCHEMA FOR DEFAULT STATE
    $color_schema_default: ();
    @if type-of(map-get($color_schema, default) == "map") {
        $color_schema_default: map-get($color_schema, default);
    }
    @include properties($color_schema_default);

    // APPLY COLOR SCHEMA FOR HOVER STATE
    $color_schema_hover: ();
    @if type-of(map-get($color_schema, hover) == "map") {
        $color_schema_hover: map-get($color_schema, hover);
    }
    &:hover {
        @include properties($color_schema_hover);
    }

    // APPLY COLOR SCHEMA FOR FOCUS STATE
    $color_schema_focus: ();
    @if type-of(map-get($color_schema, focus) == "map") {
        $color_schema_focus: map-get($color_schema, focus);
    }
    &:focus, &.focus {
        @include properties($color_schema_focus);
    }

    // APPLY COLOR SCHEMA FOR ACTIVE STATE
    $color_schema_active: ();
    @if type-of(map-get($color_schema, active) == "map") {
        $color_schema_active: map-get($color_schema, active);
    }
    &:active, &.active {
        @include properties($color_schema_active);
    }

    // APPLY COLOR SCHEMA FOR DISABLED STATE
    $color_schema_disabled: ();
    @if type-of(map-get($color_schema, disabled) == "map") {
        $color_schema_disabled: map-get($color_schema, disabled);
    }
    &:disabled, &.disabled {
        @include properties($color_schema_disabled);
    }
}

/**************************************************************************************************
    BUTTON SIZES
**************************************************************************************************/
@mixin ms_button_size(
    $properties: $common_button_sizes
) {
    // APPLY SIZES FOR DEFAULT STATE
    $sizes_default: ();
    @if type-of(map-get($properties, default) == "map") {
        $sizes_default: map-get($properties, default);
    }
    @include properties($sizes_default);

    // APPLY SIZES FOR HOVER STATE
    $sizes_hover: ();
    @if type-of(map-get($properties, hover) == "map") {
        $sizes_hover: map-get($properties, hover);
    }
    &:hover {
        @include properties($sizes_hover);
    }

    // APPLY SIZES FOR ACTIVE STATE
    $sizes_active: ();
    @if type-of(map-get($properties, active) == "map") {
        $sizes_active: map-get($properties, active);
    }
    &:active, &.active {
        @include properties($sizes_active);
    }

    // APPLY SIZES FOR DISABLED STATE
    $sizes_disabled: ();
    @if type-of(map-get($properties, disabled) == "map") {
        $sizes_disabled: map-get($properties, disabled);
    }
    &:disabled, &.disabled {
        @include properties($sizes_disabled);
    }
}

/**************************************************************************************************
    BUTTON BASE
**************************************************************************************************/
@mixin ms_button(
    $type,
    $color_schema: $common_button_color_schema,
    $sizes: $common_button_sizes
){
    @include ms_button_type($type);
    @include ms_button_color($color_schema);
    @include ms_button_size($sizes);
}

/**************************************************************************************************
    WRAPPER PROPERTIES
**************************************************************************************************/
.button-wrapper {
    @include properties($g-button-wrapper);

    + .button-wrapper {
        @include properties($g_button_wrapper_n_wrapper);
    }

    &--fill-content {
        @include properties($g_button_wrapper_fill_content);
    }

    &.functional {
        @include properties($g-functional-button-wrapper);

        + .button-wrapper {
            @include properties($g-functional-button-wrapper-n-wrapper);
        }
    }

    &.mini {
        @include properties($g_button_wrapper_mini);
    }

    /**************************************************************************************************
        BUTTON DEFINITIONS
    **************************************************************************************************/
    .action-button {
        @include ms_button($g_action_button_type);

        &.important {
            @include ms_button_color($g-action-button-important-color_schema);
        }
    }

    .secondary-button {
        @include ms_button($g_secondary_button_type, $g_secondary_button_color_schema, $g_secondary_button_sizes);
    }

    .link-button {
        background-color: unset;
        border-color: unset;
        border-bottom-color: unset;
        @include ms_button(link, $g_button_link_color_schema, $g_button_link_sizes);
    }

    .ico-button {
        @include ms_button($g-ico-button-type, $g-ico-button-color-schema, $g-ico-button-sizes);
    }

    .danger-button {
        @include ms_button($g_danger_button_type, $g_danger_button_color_schema);
    }

    .functional {
        @include ms_button_size($g-functional-button-sizes);
    }

    .flexible-button {
        @include properties($g-button-flexible);
    }

    .export-button {
        &[disabled]::before {
            @include properties($g-workflow-export-disabled);
        }
    }
}


// TODO: temporary for MS-16965
.button-close-temp {
  @include properties($g-button-close);
}

.button-trash-temp {
  @include properties($g-button-trash-ico);
}


