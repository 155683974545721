.cord-basics-filter-wrapper{
    .view-switcher{
        .group{
            &.vertical{
                @include properties($c-cord-basics-filter-group);

                .group-item{
                    @include properties($c-cord-basics-filter-group-item);
                }
            }
        }
    }

    .tnc, .cd34 {
      @include properties($c-cord-basics-cd34-n-tnc);
    }

    .exponent {
      @include properties($c-cord-basics-exponent);
    }
}
