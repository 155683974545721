@import 'mixins/icon';
@import 'variables';

$button-functional-min-width: rem(80);

@mixin link-button($button-height) {
  background: $transparent;
  border-style: none;
  color: $color-link;
  cursor: pointer;
  font-size: rem(13);
  line-height: $button-height;
  outline: none;
  padding: 0;
  text-decoration: none;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:hover {
    line-height: $button-height;
  }
}

@mixin tab-link-button {
  background: $transparent;
  border-style: none;
  box-sizing: border-box;
  color: $color-text-light-1;
  cursor: pointer;
  display: block;
  font-size: rem(13);
  font-weight: bold;
  line-height: 0;
  min-height: 2.6875rem;
  outline: none;
  padding: rem(12) rem(20);
  text-decoration: none;

  &:not(.disabled):hover {
    color: $color-text-primary
  }

  &.active {
    box-shadow: (inset 0 rem(-1) 0 #BDCD42, 0 rem(1) 0 #BDCD42);
    color: $color-text-primary;
    cursor: default;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
    pointer-events: none;
  }

  &:hover {
    line-height: 0;
  }
}

@mixin side-tab-link-button {
  background: $transparent;
  border-style: none;
  box-sizing: border-box;
  color: $color-link;
  cursor: pointer;
  display: block;
  font-size: rem(13);
  font-weight: bold;
  line-height: 0;
  min-height: 2rem;
  outline: none;
  text-decoration: none;

  &:hover {
    color: $color-link;
  }

  &.active {
    color: $color-text-primary;
    cursor: default;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
    pointer-events: none;
  }
}

button[msButton] {
  &.action-button {
    background-color: $color-link;
    border-color: $color-link;
    border-radius: $ms-button-border-radius $ms-button-border-radius;
    border-style: solid;
    border-width: $ms-button-border-width;
    color: $white;
    cursor: pointer;
    display: block;
    font-size: $ms-button-font-size;
    height: $ms-button-height;
    margin: 0;
    min-width: rem(80px);
    outline: none;
    padding: $ms-button-padding;
    text-align: center;
    text-decoration: none;

    &:hover {
      background-color: $c-options-toggle-color-hover;
      border-color: $c-options-toggle-color-hover;
      color: $white;
    }

    &:disabled {
      background-color: $light-blue-border-color;
      border-color: $light-blue-border-color;
      color: $white;
      cursor: not-allowed;
    }

    &.navigation {
      background: $m-top-header-link-color;
      border-color: $transparent;
      height: $ms-button-navigation-height;
      outline: none;

      &:hover,
      &:active,
      &:focus,
      &:visited {
        background: $m-top-header-link-hovered-color;
        border-color: $transparent;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.secondary-button {
    background-color: $white;
    border-color: $light-blue-border-color;
    border-radius: rem(2px);
    border-style: solid;
    border-width: rem(2px);
    color: $color-link;
    cursor: pointer;
    display: block;
    font-size: rem(13px);
    height: rem(28px);
    line-height: normal;
    margin: 0;
    min-width: rem(80px);
    outline: none;
    padding: 0 rem(16px);
    text-align: center;
    text-decoration: none;

    &:hover {
      background-color: $white;
      border-color: #b7d7f8;
      color: $c-options-toggle-color-hover;
    }

    &:disabled {
      background-color: $white;
      border-color: $button-secondary-border-color;
      color: $light-blue-border-color;
      cursor: not-allowed;
    }
  }

  &.ico-button {
    @include set-ico((
      type: only,
      font_size: rem(16px),
      width: rem(28px),
      height: rem(24px),
      behavioral: custom,
      initial_bg_color: $white,
      initial_color: $color-link,
      initial_ico_bg_color: $transparent,
      initial_ico_color: $color-link
    ));
    border: rem(2px) solid $light-blue-border-color;
    border-radius: 0;
    color: $color-link;
    display: inline-block;
    height: rem(28px);
    margin: 0;
    overflow: hidden;

    padding: 0;
    width: rem(32px);

    &:hover {
      @include set-ico((
        type: only,
        font_size: rem(16px),
        behavioral: custom,
        initial_bg_color: $white,
        initial_color: $c-options-toggle-color-hover,
        initial_ico_bg_color: $transparent,
        initial_ico_color: $c-options-toggle-color-hover
      ));
    }

    &:focus {
      box-shadow: 0 0 0 rem(1px) $color-link inset;
    }

    &:active {
      @include set-ico((
        type: only,
        font_size: rem(16px),
        behavioral: custom,
        initial_bg_color: $white,
        initial_color: $ico-blue-color,
        initial_ico_bg_color: $transparent,
        initial_ico_color: $ico-blue-color
      ));
    }

    &[disabled] {
      @include set-ico((
        type: only,
        font_size: rem(16px),
        behavioral: custom,
        initial_bg_color: $transparent,
        initial_color: $light-blue-border-color,
        initial_ico_bg_color: $transparent,
        initial_ico_color: $light-blue-border-color
      ));

      border-color: $button-secondary-border-color;
    }

    @each $name, $glyph in $ico-map {
      &.#{$name},
      &.#{$name}:hover {
        &::before {
          content: $glyph;
        }
      }
    }
  }

  &.danger-button {
    background: $color-highlight;
    border-color: $transparent;
    border-radius: rem(2px);
    color: $white;
    cursor: pointer;
    display: block;
    font-size: rem(13px);
    height: rem(28px);
    margin: 0;
    min-width: rem(80px);
    outline: 0;
    padding: 0 rem(16px);

    &:hover {
      background: $color-highlight-hover;
      border-color: $transparent;
      color: $white;
    }

    &:focus {
      background: $color-highlight-active;
      border-color: $transparent;
      color: $white;
    }

    &:disabled {
      background: #f3c8c8;
      border-color: $transparent;
      color: $white;
    }
  }

  &.functional {
    height: rem(36px);
    margin: 0;
    min-width: $button-functional-min-width;
    padding: 0 rem(16px);
  }

  &.flexible-button {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &.export-button {
    &[disabled]::before {
      color: $light-blue-border-color;
    }
  }

  &.mini {
    min-width: auto;
  }

  &.link-button {
    @include link-button($ms-button-height);

    &--inline {
      @include link-button($ms-button-height-inline);
    }
  }

  &.tab-link-button {
    @include tab-link-button();
  }

  &.side-tab-link-button {
    @include side-tab-link-button();
  }
}
