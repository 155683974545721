.collapsable-grid {

    [ms-collapsable] {
        position: relative;
        min-width: 20px;
        height: 20px;
    }

    .triangle-collapsable {
        width: 6px;
        height: 6px;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: $color-border;
        position: absolute;
        left: 6px;
        top: 4px;
        transform: rotate(137deg);
    }
    &:hover {
        cursor: pointer;
    }
    &.collapsable-hidden-state {
        .triangle-collapsable {
            left: 5px;
            top: 6px;
            transform: rotate(43deg);
        }
        .collapsable-block {
            height:0;
            overflow: hidden;
        }
    }

    .collapsable-block {
        height: auto;
        overflow: visible;
    }
}