$с-flatpickr-color: $white;
$с-flatpickr-border: 1px solid #0C87FF;
$с-flatpickr-shadow: 0 0 rem(5) rgba(0,0,0,0.2);
$с-flatpickr-arrow-color: $color-text-main-2;
$с-flatpickr-arrow-hover-color: #0C87FF;

$с-flatpickr-day-selected-color: #0C87FF;
$с-flatpickr-day-selected-text-color: $white;

$с-flatpickr-current-day-color: $color-alt-1;
$с-flatpickr-current-day-text-color: $color-text-primary;

$с-flatpickr-day-font-size: rem(13);
$с-flatpickr-day-color: $white;
$с-flatpickr-day-text-color: $color-border;

$с-flatpickr-day-hover-color: #DBEDFF;
$с-flatpickr-day-hover-text-color: $color-border;

$с-flatpickr-current-month-day-color: $white;
$с-flatpickr-current-month-day-text-color: $color-text-primary;

$с-flatpickr-current-month-day-hover-color: #DBEDFF;
$с-flatpickr-current-month-day-hover-text-color: $color-text-primary;

$с-flatpickr-month-and-year-text-color: $color-text-primary;
$с-flatpickr-month-and-year-font-size: rem(16);

$с-flatpickr-weekday-text-color: $color-border;
$с-flatpickr-weekday-font-size: rem(12);

$с-flatpickr-next-month-arrow: (
  block: (
    display: block
  ),
  size: rem(24),
  icon: (
      type: only,
      symbol: rightArrow,
      font_size: rem(16),
      width: rem(24),
      height: rem(24),
      behavioral: $default_icon_dark_blue_behavioral
  ),
  margin: rem(5) rem(9) 0 0
);
$с-flatpickr-prev-month-arrow: (
  block: (
    display: block
  ),
  size: rem(24),
  icon: (
      type: only,
      symbol: leftArrow,
      font_size: rem(16),
      width: rem(24),
      height: rem(24),
      behavioral: $default_icon_dark_blue_behavioral
  ),
  margin: rem(5) 0 0 rem(9)
);

.flatpickr-calendar {
    border: $с-flatpickr-border;
    background: transparent;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    padding: 0;
    -webkit-animation: none;
            animation: none;
    direction: ltr;
    font-size: 14px;
    line-height: 24px;
    border-radius: 0;
    position: absolute;
    //width: 307.875px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    background: $с-flatpickr-color;
    -webkit-box-shadow: $с-flatpickr-shadow;
            box-shadow: $с-flatpickr-shadow;
  }
  .flatpickr-calendar.open,
  .flatpickr-calendar.inline {
    opacity: 1;
    visibility: visible;
    overflow: visible;
    max-height: 640px;
  }
  .flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999;
  }
  .flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
  }
  .flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
  }
  .flatpickr-calendar.static.open {
    z-index: 999;
    display: block;
  }
  .flatpickr-calendar.hasWeeks {
    width: auto;
  }
  .flatpickr-calendar .hasWeeks .dayContainer,
  .flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
  }
  .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid #eee;
  }
  .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
  }
  .flatpickr-calendar:focus {
    outline: 0;
  }
  .flatpickr-wrapper {
    position: relative;
    display: inline-block;
  }
  .flatpickr-month {
    background: transparent;
    color: #3c3f40;
    fill: #3c3f40;
    height: 28px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
  }
  .flatpickr-prev-month {
    @include properties($с-flatpickr-prev-month-arrow);
  }
  .flatpickr-next-month {
    @include properties($с-flatpickr-next-month-arrow);
  }
  .flatpickr-prev-month,
  .flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0px;
    z-index: 3;
  }
  .flatpickr-prev-month i,
  .flatpickr-next-month i {
    position: relative;
  }
  .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-next-month.flatpickr-prev-month {
  /*
          /*rtl:begin:ignore*/
  /*
          */
    left: 0;
  /*
          /*rtl:end:ignore*/
  /*
          */
  }
  /*
          /*rtl:begin:ignore*/
  /*
          /*rtl:end:ignore*/
  .flatpickr-prev-month.flatpickr-next-month,
  .flatpickr-next-month.flatpickr-next-month {
  /*
          /*rtl:begin:ignore*/
  /*
          */
    right: 0;
  /*
          /*rtl:end:ignore*/
  /*
          */
  }
  /*
          /*rtl:begin:ignore*/
  /*
          /*rtl:end:ignore*/
  .flatpickr-prev-month:hover,
  .flatpickr-next-month:hover {
    color: $с-flatpickr-arrow-hover-color;
  }
  .flatpickr-prev-month:hover svg path,
  .flatpickr-next-month:hover svg path {
    fill: $с-flatpickr-arrow-hover-color;
  }
  .flatpickr-prev-month svg,
  .flatpickr-next-month svg {
    display: none;
  }
  .flatpickr-prev-month svg path,
  .flatpickr-next-month svg path {
    -webkit-transition: fill 0.1s;
    transition: fill 0.1s;
    fill: $с-flatpickr-arrow-color;
  }
  .numInputWrapper {
    position: relative;
    height: auto;
  }
  .numInputWrapper input,
  .numInputWrapper span {
    display: inline-block;
  }
  .numInputWrapper input {
    width: 100%;
  }
  .numInputWrapper span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(64,72,72,0.05);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .numInputWrapper span:hover {
    background: rgba(0,0,0,0.1);
  }
  .numInputWrapper span:active {
    background: rgba(0,0,0,0.2);
  }
  .numInputWrapper span:after {
    display: block;
    content: "";
    position: absolute;
    top: 33%;
  }
  .numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0;
  }
  .numInputWrapper span.arrowUp:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(64,72,72,0.6);
  }
  .numInputWrapper span.arrowDown {
    top: 50%;
  }
  .numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(64,72,72,0.6);
  }
  .numInputWrapper span svg {
    width: inherit;
    height: auto;
  }
  .numInputWrapper span svg path {
    fill: rgba(60,63,64,0.5);
  }
  .numInputWrapper:hover {
    background: rgba(0,0,0,0.05);
  }
  .numInputWrapper:hover span {
    opacity: 1;
  }
  .flatpickr-current-month {
    line-height: inherit;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 8px 0 0 0;
    line-height: 1;
    height: 28px;
    display: inline-block;
    text-align: center;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  .flatpickr-current-month.slideLeft {
    -webkit-transform: translate3d(-100%, 0px, 0px);
            transform: translate3d(-100%, 0px, 0px);
    -webkit-animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-current-month.slideLeftNew {
    -webkit-transform: translate3d(100%, 0px, 0px);
            transform: translate3d(100%, 0px, 0px);
    -webkit-animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-current-month.slideRight {
    -webkit-transform: translate3d(100%, 0px, 0px);
            transform: translate3d(100%, 0px, 0px);
    -webkit-animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-current-month.slideRightNew {
    -webkit-transform: translate3d(0, 0, 0px);
            transform: translate3d(0, 0, 0px);
    -webkit-animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-current-month span.cur-month {
    font-family: Arial;
    font-weight: normal;
    font-size: $с-flatpickr-month-and-year-font-size;
    color: $с-flatpickr-month-and-year-text-color;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0 rem(4) 0 0;
  }
  .flatpickr-current-month .numInputWrapper {
    width: rem(58);
    display: inline-block;
  }
  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: #3c3f40;
  }
  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: #3c3f40;
  }
  .flatpickr-current-month input.cur-year {
    font-family: Arial;
    font-weight: normal;
    font-size: $с-flatpickr-month-and-year-font-size;
    color: $с-flatpickr-month-and-year-text-color;
    background: transparent;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: inherit;
    cursor: default;
    padding: 0 0 0 rem(4);
    margin: 0;
    display: inline-block;
    line-height: inherit;
    height: initial;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
  }
  .flatpickr-current-month input.cur-year:focus {
    outline: 0;
  }
  .flatpickr-current-month input.cur-year[disabled],
  .flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: rgba(60,63,64,0.5);
    background: transparent;
    pointer-events: none;
  }
  .flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    height: 28px;
  }
  span.flatpickr-weekday {
    cursor: default;
    font-family: Arial;
    font-weight: normal;
    font-size: $с-flatpickr-weekday-font-size;
    color: $с-flatpickr-weekday-text-color;
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .dayContainer,
  .flatpickr-weeks {
    padding: 1px 0 0 0;
  }
  .flatpickr-days {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //width: 307.875px;
  }
  .flatpickr-days:focus {
    outline: 0;
  }
  .dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: 308px;
    min-width: 308px;
    max-width: 308px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -ms-flex-pack: justify;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  .flatpickr-calendar.animate .dayContainer.slideLeft {
    -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-calendar.animate .dayContainer.slideLeft,
  .flatpickr-calendar.animate .dayContainer.slideLeftNew {
    -webkit-transform: translate3d(-100%, 0px, 0px);
            transform: translate3d(-100%, 0px, 0px);
  }
  .flatpickr-calendar.animate .dayContainer.slideLeftNew {
    -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-calendar.animate .dayContainer.slideRight {
    -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translate3d(100%, 0px, 0px);
            transform: translate3d(100%, 0px, 0px);
  }
  .flatpickr-calendar.animate .dayContainer.slideRightNew {
    -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-day {
    background: $с-flatpickr-current-month-day-color;
    border-color: $с-flatpickr-current-month-day-color;
    color: $с-flatpickr-current-month-day-text-color;
    font-size: $с-flatpickr-day-font-size;
    border: 1px solid transparent;
    box-sizing: content-box;
    cursor: pointer;
    font-weight: normal;
    width: 42px;
    -webkit-flex-basis: 42px;
    -ms-flex-preferred-size: 42px;
    flex-basis: 42px;
    max-width: 39px;
    height: 39px;
    line-height: 39px;
    margin: 0;
    display: inline-block;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus,
  span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
  span.flatpickr-day.nextMonthDay.today:not(.selected):hover
 {
    cursor: pointer;
    outline: 0;
    background: $с-flatpickr-day-hover-color;
    border-color: $с-flatpickr-day-hover-color;
    color: $с-flatpickr-day-hover-text-color;
  }
  .flatpickr-day.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day:focus,
  span.flatpickr-day.today:not(.selected):hover
 {
    cursor: pointer;
    outline: 0;
    background: $с-flatpickr-current-month-day-hover-color;
    border-color: $с-flatpickr-current-month-day-hover-color;
    color: $с-flatpickr-current-month-day-hover-text-color;
  }
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: $с-flatpickr-day-selected-color;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: $с-flatpickr-day-selected-text-color;
    border-color: $с-flatpickr-day-selected-color;
  }
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange,
  .flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;
  }
  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
  }
  .flatpickr-day.selected.startRange + .endRange,
  .flatpickr-day.startRange.startRange + .endRange,
  .flatpickr-day.endRange.startRange + .endRange {
    -webkit-box-shadow: -10px 0 0 $с-flatpickr-day-selected-color;
            box-shadow: -10px 0 0 $с-flatpickr-day-selected-color;
  }
  .flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange,
  .flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
  }
  .flatpickr-day.inRange {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #e9e9e9, 5px 0 0 #e9e9e9;
            box-shadow: -5px 0 0 #e9e9e9, 5px 0 0 #e9e9e9;
  }
  .flatpickr-day.disabled,
  .flatpickr-day.disabled:hover {
    pointer-events: none;
  }
  .flatpickr-day.disabled,
  .flatpickr-day.disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    background: $с-flatpickr-day-color;
    color: $с-flatpickr-day-text-color;
    cursor: default;
  }
  .flatpickr-day.week.selected {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 $с-flatpickr-day-selected-color, 5px 0 0 $с-flatpickr-day-selected-color;
            box-shadow: -5px 0 0 $с-flatpickr-day-selected-color, 5px 0 0 $с-flatpickr-day-selected-color;
  }
  .rangeMode .flatpickr-day {
    margin-top: 1px;
  }
  .flatpickr-weekwrapper {
    display: inline-block;
    float: left;
  }
  .flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: 1px 0 0 #eee;
            box-shadow: 1px 0 0 #eee;
  }
  .flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
  }
  .flatpickr-weekwrapper span.flatpickr-day {
    display: block;
    width: 100%;
    max-width: none;
  }
  .flatpickr-innerContainer {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden;
  }
  .flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .flatpickr-time:after {
    content: "";
    display: table;
    clear: both;
  }
  .flatpickr-time .numInputWrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 40%;
    height: 40px;
    float: left;
  }
  .flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #404848;
  }
  .flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #404848;
  }
  .flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%;
  }
  .flatpickr-time.time24hr .numInputWrapper {
    width: 49%;
  }
  .flatpickr-time input {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    cursor: pointer;
    color: #404848;
    font-size: 14px;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .flatpickr-time input.flatpickr-hour {
    font-weight: bold;
  }
  .flatpickr-time input.flatpickr-minute,
  .flatpickr-time input.flatpickr-second {
    font-weight: 400;
  }
  .flatpickr-time input:focus {
    outline: 0;
    border: 0;
  }
  .flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .flatpickr-am-pm {
    height: inherit;
    display: inline-block;
    float: left;
    line-height: inherit;
    color: #404848;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
  }
  .flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
  }
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time .flatpickr-am-pm:focus {
    background: #f3f3f3;
  }
  .flatpickr-input[readonly] {
    cursor: pointer;
  }
  @-webkit-keyframes fpFadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fpFadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes fpSlideLeft {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
              transform: translate3d(0px, 0px, 0px);
    }
    to {
      -webkit-transform: translate3d(-100%, 0px, 0px);
              transform: translate3d(-100%, 0px, 0px);
    }
  }
  @keyframes fpSlideLeft {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
              transform: translate3d(0px, 0px, 0px);
    }
    to {
      -webkit-transform: translate3d(-100%, 0px, 0px);
              transform: translate3d(-100%, 0px, 0px);
    }
  }
  @-webkit-keyframes fpSlideLeftNew {
    from {
      -webkit-transform: translate3d(100%, 0px, 0px);
              transform: translate3d(100%, 0px, 0px);
    }
    to {
      -webkit-transform: translate3d(0px, 0px, 0px);
              transform: translate3d(0px, 0px, 0px);
    }
  }
  @keyframes fpSlideLeftNew {
    from {
      -webkit-transform: translate3d(100%, 0px, 0px);
              transform: translate3d(100%, 0px, 0px);
    }
    to {
      -webkit-transform: translate3d(0px, 0px, 0px);
              transform: translate3d(0px, 0px, 0px);
    }
  }
  @-webkit-keyframes fpSlideRight {
    from {
      -webkit-transform: translate3d(0, 0, 0px);
              transform: translate3d(0, 0, 0px);
    }
    to {
      -webkit-transform: translate3d(100%, 0px, 0px);
              transform: translate3d(100%, 0px, 0px);
    }
  }
  @keyframes fpSlideRight {
    from {
      -webkit-transform: translate3d(0, 0, 0px);
              transform: translate3d(0, 0, 0px);
    }
    to {
      -webkit-transform: translate3d(100%, 0px, 0px);
              transform: translate3d(100%, 0px, 0px);
    }
  }
  @-webkit-keyframes fpSlideRightNew {
    from {
      -webkit-transform: translate3d(-100%, 0, 0px);
              transform: translate3d(-100%, 0, 0px);
    }
    to {
      -webkit-transform: translate3d(0, 0, 0px);
              transform: translate3d(0, 0, 0px);
    }
  }
  @keyframes fpSlideRightNew {
    from {
      -webkit-transform: translate3d(-100%, 0, 0px);
              transform: translate3d(-100%, 0, 0px);
    }
    to {
      -webkit-transform: translate3d(0, 0, 0px);
              transform: translate3d(0, 0, 0px);
    }
  }
  @-webkit-keyframes fpFadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes fpFadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-webkit-keyframes fpFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fpFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .flatpickr-calendar {
    width: 307.875px;
  }
  .dayContainer {
    padding: 0;
    border-right: 0;
  }
  span.flatpickr-day,
  span.flatpickr-day.prevMonthDay,
  span.flatpickr-day.nextMonthDay {
    border-radius: 0 !important;
    border: 1px solid #e9e9e9;
    max-width: none;
    border-right-color: transparent;
  }
  span.flatpickr-day:nth-child(n+8),
  span.flatpickr-day.prevMonthDay:nth-child(n+8),
  span.flatpickr-day.nextMonthDay:nth-child(n+8) {
    border-top-color: transparent;
  }
  span.flatpickr-day:nth-child(7n-6),
  span.flatpickr-day.prevMonthDay:nth-child(7n-6),
  span.flatpickr-day.nextMonthDay:nth-child(7n-6) {
    //border-left: 0;
  }
  span.flatpickr-day:nth-child(n+36),
  span.flatpickr-day.prevMonthDay:nth-child(n+36),
  span.flatpickr-day.nextMonthDay:nth-child(n+36) {
    border-bottom: 0;
  }
  span.flatpickr-day:nth-child(-n+7),
  span.flatpickr-day.prevMonthDay:nth-child(-n+7),
  span.flatpickr-day.nextMonthDay:nth-child(-n+7) {
    margin-top: 0;
  }
  span.flatpickr-day.today:not(.selected),
  span.flatpickr-day.prevMonthDay.today:not(.selected),
  span.flatpickr-day.nextMonthDay.today:not(.selected) {
    background: $с-flatpickr-current-day-color;
    border-color: $с-flatpickr-current-day-color;
    color: $с-flatpickr-current-day-text-color;
  }
  span.flatpickr-day.startRange,
  span.flatpickr-day.prevMonthDay.startRange,
  span.flatpickr-day.nextMonthDay.startRange,
  span.flatpickr-day.endRange,
  span.flatpickr-day.prevMonthDay.endRange,
  span.flatpickr-day.nextMonthDay.endRange {
    border-color: $с-flatpickr-day-selected-color;
  }
  span.flatpickr-day.today,
  span.flatpickr-day.prevMonthDay.today,
  span.flatpickr-day.nextMonthDay.today,
  span.flatpickr-day.selected,
  span.flatpickr-day.prevMonthDay.selected,
  span.flatpickr-day.nextMonthDay.selected {
    z-index: 2;
  }
  .rangeMode .flatpickr-day {
    margin-top: -1px;
  }
  .flatpickr-weekwrapper .flatpickr-weeks {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .flatpickr-weekwrapper span.flatpickr-day {
    border: 0;
    margin: -1px 0 0 -1px;
  }
  .hasWeeks .flatpickr-days {
    border-right: 0;
  }
  