.coop-source-grid {
    tbody{
        tr {
            @include properties($c-coop-grid-table-row);

            &:nth-child(odd) {
                @include properties($c-coop-grid-table-row-odd);
            }
        }

        td {
            @include properties($c-coop-sources-grid-table-cell);
        }
    }

    .grid-cell {
        @include properties($c-coop-sources-grid-grid-cell);
    }

    .confirmed-hla-cell {
        @include properties($c-coop-sources-grid-confirmed-hla-cell);
    }

    .last-updated-cell {
        @include properties($c-coop-sources-grid-last-updated-cell);
    }
}