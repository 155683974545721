.blocked-icon {
  border-radius: 50%;
  width: 13px;
  min-width: 13px;
  height: 13px;
  box-shadow: inset 0 0 0 1px $color-highlight;
  position: relative;
  z-index: 0;
  overflow: hidden;
  top: -0.5px;
  &::before {
    content: '';
    position: absolute;
    width: 130%;
    height: 1px;
    background-color: $color-highlight;
    transform: rotate(45deg);
    transform-origin: 0 0 0;
    top: 1px;
    left: 2px;
    z-index: 1;
    border: 0;
    outline: 0;
  }
}
