.ms-dropdown {
    @include properties($c_dropdown);

    .dropdown-toggle {
        @include properties($c_dropdown_toggle);
    }

    .dropdown-toggle-arrow {
        @include properties($c_dropdown_toggle_arrow);

        &::after {
            @include properties($c_dropdown_toggle_arrow-ico);
            content: '';
        }
    }

    .dropdown-menu {
        @include properties($c_dropdown_menu);

        > li {
            @include properties($c_dropdown_menu_item);

            &:not(:first-child) {
                @include properties($c_dropdown_menu_item_gap);
            }

            &.dropdown-menu-item:hover {
                @include properties($c_dropdown_menu_item_hover);
            }

            &.dropdown-menu-item > a {
                @include properties($c_dropdown_menu_item_link);
            }

            &.dropdown-menu-note {
                @include properties($c_dropdown_menu_note);
            }

            &.dropdown-menu-divider {
                @include properties($c_dropdown_menu_divider);

                ~ .dropdown-menu-item {
                    @include properties($c_dropdown_menu_item_no_gap);
                }
            }
        
        }

        li.dropdown-menu-header {
            @include properties($c_dropdown_menu_item);
        }

        li.dropdown-menu-component {
            @include properties($c_dropdown_menu_item);

            &:hover {
                @include properties($c_dropdown_menu_item_hover);
            }
        }
    }

    .dropdown-menu.open {
        @include properties($c_dropdown_menu_open);
    }
}