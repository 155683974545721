/************************************************
    DONOR: COMMON
 ************************************************/
.donor {
    .ms-module-area {
        @include properties($m-donor-module-area);
    }

    .ms-module-header-area {
        @include properties($m-donor-module-header);
    }
}

/************************************************
    DONOR: DETAILS
 ************************************************/
.donor-details {
    @include properties($m-donor-details);

    &.container > li:not(:last-child) {
        @include properties($m-donor-details-section);
    }

    .content-area {
        @include properties($m-donor-details-content);

        > .container {
            @include properties($m-donor-details-content-container);
        }
    }

    .ms-tab-content > .container {
        @include properties($m-donor-details-tab-container);
    }
}

/************************************************
    DONOR: DEMOGRAPHICS
 ************************************************/
.donor-demographics {
    @include properties($m-donor-demographics);

    &.cords-demographics {
        > section {
            @include properties($m-cord-demographics-view-section);

            &:last-child {
                @include properties($m-cord-demographics-view-last-section);
            }
        }
        .section-content {
            @include properties($m-cord-demographics-section-content);
        }
        .section-column {
            .field-value {
                @include properties($m-cord-demographics-field-value);
            }
            + .section-column {
                @include properties($m-cord-demographics-section-column-n-column);
            }
        }
    }

    .section-area {
        @include properties($m-donor-demographics-section);

        + .section-area {
            @include properties($m-donor-demographics-section-n-section);
        }
    }

    .section-row {
        @include properties($m-donor-demographics-section-row);

        + .section-row {
            @include properties($m-donor-demographics-section-row-row);
        }
    }

    .section-column {
        @include properties($m-donor-demographics-section-column);

        + .section-column {
            @include properties($m-donor-demographics-section-column-n-column);
        }
    }

    .section-area.general-info-section {
        .section-column {
            @include properties($m-donor-demographics-general-info-column);
        }
    }

    .section-column.race-details-column {
        @include properties($m-donor-demographics-race-details-column);

        .field-value {
            @include properties($m-donor-demographics-race-details-column-field-value);
        }
    }
    .info-box {
        @include properties($m-cord-demographics-info-box);
    }
}
