@import 'fn/index';

.gu-mirror {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)'; /* stylelint-disable-line property-no-vendor-prefix */
  filter: alpha(opacity=80);
  margin: 0 !important;
  opacity: 0.8;
  pointer-events: none;
  position: fixed !important;
  z-index: 9999 !important;/* stylelint-disable-line property-no-vendor-prefix */
}

.gu-hide {
  left: -9999px !important;
}

.gu-unselectable {
  user-select: none !important;
}

.gu-transit {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)'; /* stylelint-disable-line property-no-vendor-prefix */
  filter: alpha(opacity=20);
  opacity: 0.2;/* stylelint-disable-line property-no-vendor-prefix */
}
