.ms-chips {
    @include properties($c-chips);

    &-list {
        @include properties($c-chips-list);

        &-item {
            @include properties($c-chips-list-item);

            &.multiple-lines {
              box-sizing: border-box;
              width: 100%;
            }
        }
    }

    .ms-chips-list .ms-chips-list-item.multiple-lines input[type=checkbox] + label {
      line-height: rem(18);

      &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    &-count {
        .ms-count-mark {
            @include properties($c-chips-count-mark);
        }
    }
}
