/**********************************************************************************************
	FONT ICONS
 **********************************************************************************************
 || Default settings for font icons
$ico_properties_map_example: (
  || symbol
		symbol: empty,
  || type & dimensions
		type: only,
		font_size: $default_ico_size,
		width: $default_ico_width,
		height: $default_ico_height
  || colors & behavioral
		behavioral: custom,
		initial_bg_color: $transparent,
		initial_color: $light_blue,
		initial_ico_bg_color: $transparent,
		initial_ico_color: $light_blue,
		hover_bg_color: $transparent,// optional -> unused 4 default
		hover_color: $light_blue_hover,// optional -> unused 4 default
		hover_ico_bg_color: inherit,// optional -> used 4 vertical only
		hover_ico_color: inherit// optional -> used 4 vertical only
);
$ico_properties_map_example_2: (
  || symbol
		symbol: empty,
  || type & dimensions
		type: only,
		font_size: $default_ico_size,
		width: $default_ico_width,
		height: $default_ico_height,
  || colors & behavioral
		behavioral: $ico_csch_trans_blue_blue_white
);
 *********************************************************************************************/
$ico-size-correlation: 1.17;

@function get_ico_char(
    $ico_name: $ico-default
) {
    @return map-get($ico-map, $ico_name);
}
// print font-icon char
@mixin set_ico_char {
    &:before {
        @content;
    }
}
/**************************************************************************************************
    SYMBOL
**************************************************************************************************/
@function get-ico-symbol(
    $ico_name: $ico-default
) {
    @return map-get($ico-map, $ico_name);
}
@mixin set-ico-symbol(
    $ico_name: $ico-default
){
    &:before {
        content: get-ico-symbol($ico_name);
    }
}
/**************************************************************************************************
    COLOR SCHEMA
**************************************************************************************************/
@mixin set_ico_color( $ico_color_map ) {
    $behavioral: map-get($ico_color_map, behavioral);
    $color_schema: $behavioral;

    @if $behavioral == custom {
        $color_schema: $ico_color_map;
    }

    $color: map-get($color_schema, initial_color);
    $bg_color: map-get($color_schema, initial_bg_color);

    $ico_color: map-get($color_schema, initial_ico_color);
    $ico_bg_color: map-get($color_schema, initial_ico_bg_color);

    $color_hover: map-get($color_schema, hover_color);
    $bg_color_hover: map-get($color_schema, hover_bg_color);

    $ico_color_hover: map-get($color_schema, hover_ico_color);
    $ico_bg_color_hover: map-get($color_schema, hover_ico_bg_color);

    $color_active: map-get($color_schema, active_color);
    $bg_color_active: map-get($color_schema, active_bg_color);

    $ico_color_active: map-get($color_schema, active_ico_color);
    $ico_bg_color_active: map-get($color_schema, active_ico_bg_color);

    @if type-of($color) == color {
        color: $color;
        &[disabled] {
            color: $color;
        }
    }
    @if type-of($bg_color) == color {
        background-color: $bg_color;
        &[disabled] {
            background-color: $bg_color;
        }
    }
    @if type-of($ico_color) == color {
        &:before {
            color: $ico_color;
        }
    }
    @if type-of($ico_bg_color) == color {
        &:before {
            background-color: $ico_bg_color;
        }
    }
    @if type-of($color_hover) == color {
        &:hover {
            color: $color_hover;
        }
    }
    @if type-of($bg_color_hover) == color {
        &:hover {
            background-color: $bg_color_hover;
        }
    }
    @if type-of($ico_color_hover) == color {
        &:hover {
            &:before {
                color: $ico_color_hover;
            }
        }
    }
    @if type-of($ico_bg_color_hover) == color {
        &:hover {
            &:before {
                background-color: $ico_bg_color_hover;
            }
        }
    }
    @if type-of($color_active) == color {
        &:active {
            color: $color_active;
        }
    }
    @if type-of($bg_color_active) == color {
        &:active {
            background-color: $bg_color_active;
        }
    }
    @if type-of($ico_color_active) == color {
        &:active {
            &:before {
                color: $ico_color_active;
            }
        }
    }
    @if type-of($ico_bg_color_active) == color {
        &:active {
            &:before {
                background-color: $ico_bg_color_active;
            }
        }
    }
}
/**************************************************************************************************
    DIMENSIONS & BEHAVIORAL
**************************************************************************************************/
@function get-ico-type-properties(
    $type,
    $size: $ico-default-size,
    $width: $ico-default-size,
    $height: $ico-default-size,
    $before: false
){
    @if $size != null {
        $size: $size * $ico-size-correlation;
    }

    $properties: ();
    @if $type == only {
        @if $before {
            $properties: map-merge(
                $g_icon_only_before_properties,
                (
                    font: (
                        size: $size
                    ),
                    size: (
                        width: $width
                    )
                )
            );
        }
        @else {
            $properties: map-merge(
                $g_icon_only_properties,
                (
                    text: (
                        font-size: $size,
                        line-height: $height
                    ),
                    size: (
                        height: $height,
                        width: $width
                    )
                )
            );
        }
    }
    @else if $type == left {
        @if $before {
            $properties: map-merge(
                $g_icon_left_before_properties,
                (
                    text: (
                        font-size: $size,
                        line-height: $height
                    ),
                    size: (
                        width: $width
                    )
                )
            );
        }
        @else {
            $properties: map-merge(
                $g_icon_left_properties,
                ()
            );
        }
    }
    @else if $type == right {
        @if $before {
            $properties: map-merge(
                $g_icon_right_before_properties,
                (
                    text: (
                        font-size: $size,
                        line-height: $height
                    ),
                    size: (
                        height: $height,
                        width: $width
                    )
                )
            );
        }
        @else {
            $properties: map-merge(
                $g_icon_right_properties,
                (
                    padding: (null $width null null)
                )
            );
        }
    }
    @else if $type == vertical {
        @if $before {
            $properties: map-merge(
                $g_icon_vertical_before_properties,
                (
                    text: (
                        font-size: $size,
                        line-height: $height
                    )
                )
            );
        }
        @else {
            $properties: map-merge(
                $g_icon_vertical_properties,
                ()
            );
        }
    }
    @return $properties;
}
@mixin set_ico_type($ico_map) {
    $type: map-get($ico_map, type);
    $size: map-get($ico_map, font_size);
    $width: map-get($ico_map, width);
    $height: map-get($ico_map, height);

    @include properties(get-ico-type-properties($type, $size, $width, $height));

    &:before {
        @include properties(get-ico-type-properties($type, $size, $width, $height, true));
    }
}
/**************************************************************************************************
    COMMON SIMPLIFIED DECLARATION
**************************************************************************************************/
@mixin set_ico(
    $ico_properties_map
){
    $symbol: map-get($ico_properties_map, symbol);

    @include properties($g_icon_properties);

    &:before {
        @include properties($g_icon_before_properties);
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        content: '';
        speak: none;
    }
    &[disabled], &[disabled]:before {
        @include disabled();
    }

    @include set_ico_type( $ico_properties_map );
    @include set_ico_color( $ico_properties_map );
    @include set-ico-symbol($symbol);
}
