.search-filters.search-columns .search-filters-toolbar {
    @include properties($m-search-col-toolbar);

    .ms-link {
        @include properties($m-search-col-toolbar-link);

        &:last-child {
            @include properties($m-search-col-toolbar-link-last);
        }
    }
}

.search-col-item {
    @include properties($m-search-col-item);
}

.search-col-item:last-child {
    @include properties($m-search-col-item-last);
}

.search-columns-container {
    @include properties($m-search-col-container);
}

.search-col-title {
    @include properties($m-search-col-title);

    .search-col-hidden & {
        @include properties($m-search-col-title-hidden);
    }
}

.search-col-visible {
    @include properties($m-search-col-visible);
}

.search-col-visibility {
    @include properties($m-search-col-visibility);

    .search-col-hidden & {
        @include properties($m-search-col-visibility-hidden);
    }
}

.search-col-dnd {
    @include properties($m-search-col-dnd);
}

.search-col-dnd-ico {
    @include properties($m-search-col-dnd-ico);

    .as-sortable-un-selectable &,
    .as-sortable-dragging & {
        @include properties($m-search-col-dnd-ico-dragging);
    }
}

.bp-restricted {
    .search-col-dnd-ico {
        @include properties($m-search-col-dnd-ico-hidden);
    }
}

.search-filters .search-filters-ctrls > .container.container-compact {
    @include properties($m-search-col-ctrl-container);
}

.search-columns-sortable {
    &.as-sortable-drag {
        @include properties($m-search-col-sortable-drag);
    }

    .as-sortable-placeholder {
        @include properties($m-search-col-sortable-placeholder);
    }

    .as-sortable-copy {
        @include properties($m-search-col-sortable-copy);
    }
}
