@import 'mixins/layout';

.ms-main-content {
    @include properties($m-main-content);

    .ms-main-container,
    > .container {
        @include properties($m-main-content-container);
    }
}
