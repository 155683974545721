.ui-grid {
    body & {
        @include properties($c-ui-grid);
    }

    &.ui-grid-hidden {
        visibility: hidden;
    }

    .ui-grid-icon-wrapper {
        @include properties($c-ui-grid-icon-wrapper);
    }

    .ui-grid-render-container {
        &:first-child {
            .ui-grid-row-selected .ui-grid-cell:first-child {
                @include properties($c-ui-grid-row-selected-cell-first);
            }
            .ui-grid-row-selected + .ui-grid-row-selected .ui-grid-cell:first-child {
                @include properties($c-ui-grid-row-selected-n-row-selected-cell-first);
            }

            .ui-grid-row:not(.ui-grid-row-selected) {
                .ui-grid-cell.pending-this-request:first-child {
                    @include properties($c-ui-grid-row-pending-this-request-cell-first);
                }

                .ui-grid-cell.pending-another-request:first-child {
                    @include properties($c-ui-grid-row-pending-another-request-cell-first);
                }
            }
        }
    }

    .ui-grid-header {
        @include properties($c-ui-grid-header);
    }
    .ui-grid-top-panel {
        @include properties($c-ui-grid-header-top-panel);
    }
    .ui-grid-header-cell {
        @include properties($c-ui-grid-header-cell);

        .ui-grid-cell-contents {
            @include properties($c-ui-grid-header-cell-content);
        }
    }
    .ui-grid-header-cell-label {
        @include properties($c-ui-grid-cell-label);

        &.sort {
            @include properties($c-ui-grid-cell-label-sort);

            i {
                @include properties($c-ui-grid-cell-label-sort-ico);

                &:after {
                    content: '';
                    @include properties($c-ui-grid-cell-label-sort-ico-arrow);
                }
            }
            &.desc {
                i:after {
                    @include properties($c-ui-grid-cell-label-sort-ico-arrow-desc);
                }
            }
            &.asc {
                i:after {
                    @include properties($c-ui-grid-cell-label-sort-ico-arrow-asc);
                }
            }
        }

        + span {
            display: none
        }
    }

    .ui-grid-cell-contents {
        @include properties($c-ui-grid-cell-content);
    }

    .ui-grid-canvas {
        @include properties($c-ui-grid-canvas);
    }
    .ui-grid-row {
        &:nth-child(odd) .ui-grid-cell {
            @include properties($c-ui-grid-cell-odd);
        }
        &:nth-child(even) .ui-grid-cell {
            @include properties($c-ui-grid-cell-even);
        }
        &.ui-grid-row-selected {
            .ui-grid-cell {
                @include properties($c-ui-grid-row-selected-cell);
            }
        }
        &:not(.ui-grid-row-selected) {
            .ui-grid-cell.pending-this-request {
                @include properties($c-ui-grid-row-pending-this-request-cell);
            }

            .ui-grid-cell.pending-another-request {
                @include properties($c-ui-grid-row-pending-another-request-cell);
            }
        }
    }
    .ui-grid-cell {
        @include properties($c-ui-grid-cell);
    }
    .grid-link {
        @include properties($c-ui-grid-link);
    }


    .ui-grid-viewport{
        -ms-overflow-style: scrollbar; // need for IE scroll after perfect scroll init
    }

    &.ui-grid--no-scroll-y {
        @include properties($c-ui-grid-no-scroll-y);

        .ui-grid-viewport {
            @include properties($c-ui-grid-no-scroll-y-viewport);
        }
    }

    &.ui-grid--bordered {
        .ui-grid-render-container-body {
            .ui-grid-header {
                @include properties($c-ui-grid-body-header-bordered);
            }
        }

        .ui-grid-render-container-left {
            @include properties($c-ui-grid-container-left-bordered);

            .ui-grid-header {
                @include properties($c-ui-grid-header-bordered);
            }
            .ui-grid-viewport {
                @include properties($c-ui-grid-bordered);
            }
            .ui-grid-scrollbar-placeholder {
                @include properties($c-ui-grid-scrollbar-bordered);
            }
        }
    }
}

/************************************************
    COMPLEX GRID
 ************************************************/
.ui-grid.ui-grid-complex {
    .ui-grid-pinned-container.ui-grid-pinned-container-left {
        .ui-grid-header-cell:last-child {
            @include properties($c-ui-grid-complex-pinned-header-cell-last);
        }

        .ui-grid-canvas .ui-grid-row .ui-grid-cell:last-child {
            @include properties($c-ui-grid-complex-pinned-cell-last);
        }
    }

    .ui-grid-header {
        @include properties($c-ui-grid-complex-header);
    }

    .ui-grid-header-cell {
        @include properties($c-ui-grid-complex-header-cell);

        &:last-child {
            @include properties($c-ui-grid-complex-header-cell-last);
        }

        .ui-grid-cell-contents {
            @include properties($c-ui-grid-complex-header-cell-content);
        }

        .ui-grid-cell-contents--compact {
            @include properties($c-ui-grid-complex-header-cell-content-compact);
        }

        .ui-grid-cell-contents--normal {
            @include properties($c-ui-grid-complex-header-cell-content-normal);
        }

        .header-cell-field + .header-cell-field {
            @include properties($c-ui-grid-complex-header-cell-cell);
        }
    }

    .ui-grid-canvas .ui-grid-row {
        &:last-child .ui-grid-cell {
            @include properties($c-ui-grid-complex-cell);
        }

        .ui-grid-cell {
            @include properties($c-ui-grid-complex-cell);

            &:last-child {
                @include properties($c-ui-grid-complex-cell-last);
            }

            &.ui-grid-disable-selection {
                @include properties($c-ui-grid-complex-cell-disable-selection);
            }
        }
    }

    .ui-grid-cell-contents {
        @include properties($c-ui-grid-complex-cell-content);

        & > .container > li {
            @include properties($c-ui-grid-complex-cell-content-item);
        }

        &.cell-field-group {
            @include properties($c-ui-grid-cell-content-group);

            .cell-field {
                @include properties($c-ui-grid-cell-content-group-cell);
            }
        }
    }

    .ui-grid-cell-contents--compact {
        @include properties($c-ui-grid-complex-cell-content-compact);
    }

    .ui-grid-draggable-row {
        @include properties($c-ui-grid-draggable-row);
    }

    .ui-grid-draggable-row-over {
        &:before {
            @include properties($c-ui-grid-draggable-row-over);
        }
    }

    .ui-grid-draggable-row-handle {
        @include properties($c-ui-grid-draggable-row-handle);
    }
}

.ui-grid-no-results-area {
    &,
    .ms-module-header-area + &.ms-module-content-area {
        @include properties($c-ui-grid-no-results-area);
    }
}

.ui-grid-no-results-label {
    @include properties($c-ui-grid-no-results-label);
}
