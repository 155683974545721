.ms-hla-locus-view {
    & > .container {
        @include properties($m-patient-hla-locus-view-container);
    }
    .locus-row {
        @include properties($m-patient-hla-locus-view-row);

      &.locus-match-grade {
        @include properties($m-patient-hla-locus-match-grade-row);
      }
    }
    .locus-column {
        @include properties($m-patient-hla-locus-view-column);
    }

    .typing-highlight {
        background: #faf0bc;
        color: #959669;
    }

    .match-grade {
        @include properties($m-patient-hla-locus-match-grade);

        &::before {
            content: '';
            @include properties($m-patient-hla-locus-match-grade-icon);
        }
        &.allele-match::before {
            @include properties($m-patient-hla-locus-allele-match-icon);
        }
        &.potential-match::before {
            @include properties($m-patient-hla-locus-potential-match-icon);
        }
        &.allele-mismatch::before {
            @include properties($m-patient-hla-locus-allele-mismatch-icon);
        }
        &.antigen-mismatch::before {
            @include properties($m-patient-hla-locus-antigen-mismatch-icon);
        }
    }

    .match-likelihood {
        @include properties($m-patient-hla-locus-match-likelihood);
    }
    .typing.double {
        @include properties($m-patient-hla-locus-typing-double);
    }
    .leader, .b-leader, .dpb1-expression {
      @include properties($m-patient-hla-locus-typing-leader);
    }
    .dpb1-expression:hover {
      color: $color-link;
    }
}
